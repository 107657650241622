.expense-commute {
  .expense-commute__button {
    .icon {
      margin-right: 7px;
    }
  }

  .expense-commute__travel-list {
    list-style: none;
    margin: 10px 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    .expense-commute__travel-list__travel {
      .expense-commute__travel-list__travel__columns > .row > div {
        display: flex;
        align-items: center;
        margin: 5px 0;
        flex-direction: row;

        .expense-commute__travel-list__travel__travel-part__icon {
          font-size: 1.5rem;
          color: $color-grey-05;
          padding-left: 15px;
        }

        .expense-commute__travel-list__travel__travel-part__label {
          padding: 0 10px;
          flex-grow: 1;
        }

        .expense-commute__travel-list__travel__travel-part__actions {
          position: absolute;
          right: 0;

          .icon {
            cursor: pointer;
          }
        }
      }

      .expense-commute__travel-list__travel__form-wrapper--outer {
        .expense-commute__travel-list__travel__form-wrapper--inner {
          border-top: 2px solid $color-grey-04;
          padding: 10px 0;

          .expense-commute__travel-list__travel__form-wrapper__form {
            .expense-commute__travel-list__travel__form-wrapper__form__buttons {
              display: flex;
              justify-content: flex-end;

              .expense-commute__travel-list__travel__form-wrapper__form__button {
                margin-left: 10px;
              }
            }

            .expense-commute__travel-list__travel__form-wrapper__form__header {
              margin: 0;
            }
            .row {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.travel-list__column {
  position: relative;
}

.travel-part__labels-wrapper {
  max-width: calc(100% - 15px);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include rwdmax(exlarge) {
    display: block;
  }
}
.travel-part__labels-wrapper--disable {
  color: #ccc !important;

}

.travel-part__label-detail {
  display: inline-block;

  &:first-child {
    white-space: nowrap;
    margin-right: 5px;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include rwdmax(exlarge) {
      margin-right: 0;
      max-width: 135px;
    }
  }
}
.travel-part__label-detail--small {
  @include rwdmax(exlarge) {
    font-size: 12px;
  }
}

.travel-part__label-detail--x-small {
  font-size: 12px;
  display: block;
}

.expense-commute .icon-select .icon:not(.expense-commute__icon-selected) {
  transition: transform 0.2s ease-in-out;
  transform-origin: center;
  &:hover {
    transform: scale(1.35);
  }
}

.expense-commute__panel-heading {
  position: relative;
}
.expense-commute__panel-tooltip {
  position: absolute;
  transform: translateY(2px);
  margin-left: 5px;
}
.expense-commute__panel-tooltip-text {
  margin-bottom: 10px !important;
  display: block;
  &:last-child {
    margin-bottom: 0 !important;
  }
}
