.is-pull-end {
  display: flex;
  justify-content: flex-end;
}

.is-allign-end {
  text-align: right;
}

.is-align-center {
  text-align: center;
}

.is-italic {
  font-style: italic;
}
