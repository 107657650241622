.exchange-summary {
  border-top: 1px solid $color-grey-05;
  margin-top: 15px;
  color: $text-color;
  margin-bottom: 15px;
  font-size: 18px;
}

.exchange-summary__content {
  padding: 5px 0 0;
}

.exchange-summary__partials {
  color: $color-grey-05;
  font-size: 16px;
}

.exchange-summary--loading {
  opacity: 0.5;
}