.dashboard-current-trips {
  .section {
    position: relative;
  }

  .dashboard-current-trips__row {
    margin-top: 13px;
    min-height: 340px;
    .dashboard-current-trips__hr {
      border-bottom: 1px solid $color-grey-04;
      margin: 25px 15px;
      width: 100%;
      height: 1px;
      line-height: 0;
    }
    .dashboard-current-trips__show-button {
      position: absolute;
      right: 30px;
      top: 30px;
    }
  }
}
