.crossings-timeline__crossing {
  display: flex;
  justify-content: space-between;
}

.border-crossings__timieline-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.border-crossings__timieline-label-container {
  display: flex;
  align-items: center;
  width: 165px;
}
.border-crossings__timieline-label {
  width: 165px;
}
.border-crossings__timieline-item-label {
  width: 100%;
  display: block;
  min-width: 140px;
  max-width: 140px;
  margin: 0 20px 5px 0;
}
.border-crossings__timieline-inputs {
  display: flex;
  align-items: center;
}
.border-crossings__timieline-item-input-wrapper {
  width: 100%;
  min-width: 150px;
  max-width: 150px;
  margin-right: 14px;

  & .form-group {
    margin: 0;
  }
}
.border-crossings__timieline-item-icon {
  font-size: 18px;
  color: #cacaca;
  margin-left: -22px;
  transform: translateY(-2px);
  display: block;
}
.border-crossings__timieline-item-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border-crossings__timieline-item-checkbox-label {
  margin: 0 27px 0 14px;
  white-space: nowrap;
  @media screen and (max-width: 1670px) {
    margin: 0 14px;
  }
  @include rwdmax(exlarge) {
    margin: 0 5px;
    white-space: nowrap;
  }
}

.border-crossings__timeline-add-button {
  margin: 20px 0 !important;
  &::before {
    transform: translateY(-8px);
    height: calc(100% + 27px) !important;
  }
  div {
    padding: 0;
  }
  .timeline__header {
    line-height: 31px;
    height: 31px;
  }
  .timeline__header-row {
    margin-left: -5px;
    transform: translateY(1px);
  }
  .timeline__header-icon {
    margin-top: -4px;
  }
  .timeline__header-icon::before {
    transform: translateY(calc(-50% + 2px));
  }
}

.border-crossings__steps {
  max-width: 507px;
  width: 100%;
  margin: 20px 0 55px;
  display: block;
  position: relative;
  height: 25px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    display: block;
  }
  &::before {
    width: 100%;
    height: 1px;
    background: $color-grey-10;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    width: 50%;
    height: 2px;
    background: linear-gradient(90deg, #64E0BA 0%, #2DCEE3 100%);
    left: 0;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 1670px) {
    max-width: 352px;
  }
}
.border-crossings__steps--done {
  &::after {
    width: 100%;
  }
}
.border-crossings__step {
  position: absolute;
  top: 0;
  left: 100px;
  display: table;
  z-index: 1;
}
.border-crossings__step--first {
  left: 85px;
  @media screen and (max-width: 1670px) {
    left: 65px;
  }
}
.border-crossings__step--last {
  left: calc(100% - 110px);
  @media screen and (max-width: 1670px) {
    left: calc(100% - 90px);
  }
}
.border-crossings__step-number {
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: $color-grey-10;
  box-shadow: inset 0 0 0 1px $color-grey-10;
  border-radius: 50%;
  background: #fff;
  font-weight: 500;
}
.border-crossings__step-label {
  display: block;
  white-space: nowrap;
  color: #000;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.border-crossings__step--active {
  .border-crossings__step-number {
    background: linear-gradient(90deg, #64E0BA 0%, #2DCEE3 100%);
    color: #fff;
    box-shadow: none;
  }
}
.border-crossings__heading {
  display: block;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}
.border-crossings__heading--center {
  text-align: center;
}
.border-crossings__toggler {
  display: flex;
  margin-bottom: 35px;

  &--disabled &-label {
    cursor: default;
    opacity: 0.5;
  }
}
.border-crossings__toggler-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 29px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #000;
  background: $color-default-03 $gradient-default-01;
  box-shadow: inset -999px 0 0 #fff; /* The background color */
  background-position: 100% 0;
  background-size: 200% 200%;
  border: 1px solid transparent;
  border-right: 0;
}
.border-crossings__toggler-label .icon {
  font-size: 16px;
  margin-right: 3px;
}
.border-crossings__toggler-label--first {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.border-crossings__toggler-label--last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
  border-right: 1px solid #cbcbcb;
}
.border-crossings__toggler-label--active {
  font-weight: 500;
  color: #fff;
  border: 0;
  box-shadow: none;
  line-height: 29px;
  opacity: 1 !important;
}
.border-crossings__toggler-label--active .icon {
  color: #fff;
}
.border-crossings__toggler-radio {
  display: none;
  visibility: hidden;
}
.border-crossings__pagination-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.border-crossings__pagination {
  font-weight: 500;
}

.border-crossings__timieline-target-point {
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  @media screen and (max-width: 1450px) {
    display: block;
  }

  > strong {
    margin-right: 10px;
  }
}

.accommodation-confirm__dialog-content {
  padding-top: 50px !important;
}

.accommodation-confirm__dialog-actions {
  padding-right: 15px;
  padding-bottom: 15px;
  margin: 0 !important;
}

.accommodation-cant-be-obtained__info-icon {
  font-size: 18px;
  color: #cacaca;
  position: absolute;
  top: -3px;
  left: 25px;
}

.accommodation-checkbox__wrapper {
  position: relative;
}


.timeline-container--national {
  .border-crossings__timieline-inputs {
    @media screen and (max-width: 1525px) {
      width: 100%;
    }
  }
}

.timeline-container--aboard {
  .border-crossings__timieline-inputs {
    @media screen and (max-width: 1815px) {
      width: 100%;
    }
  }

  .border-crossings__timieline-item-input-wrapper {
    @media screen and (max-width: 1450px) {
      min-width: 135px;
      max-width: 135px;
    }
  }

  .border-crossings__timieline-item {
    @media screen and (max-width: 1750px) {
      display: block;
    }
  }

  .date-picker-field {
    @media screen and (max-width: 1450px) {
      .date-picker-field__icon {
        display: none;
      }
    }
  }
}
