.accommodation-suggestions__buttons {
  display: inline;
  margin-left: 10px;
  .btn {
    margin-right: 20px;
  }
}

.private-accomodation__cost {
  display: flex;
  margin-right: 0;

  .icon--type-fx {
    position: relative;
    margin-right: 5px;
    top: -5px;
  }
}
.private-accomodation__cost-value {
  margin: 0 5px;
}
