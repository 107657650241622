@use 'sass:math';

@import '../helpers';

$i: 1;
$sum: '.xs-1, .sm-1, .md-1, .lg-1,';
@while $i <=$grid_columns {
  $sum: $sum #{'.xs-'}#{$i}#{', '} #{' .sm-'}#{$i}#{', '} #{' .md-'}#{$i}#{', '}#{' .lg-'}#{$i}#{', '};
  $i: $i + 1;
}

#{$sum} {
  padding: 0 $grid_columns-padding;
  box-sizing: border-box;
  width: 100%;
}

@for $i from 1 through $grid_columns {
  .xs-#{$i} {
    width: math.div(100%, $grid_columns) * $i;
  }
}

@include rwd(small) {
  @for $i from 1 through $grid_columns {
    .sm-#{$i} {
      width: math.div(100%, $grid_columns) * $i;
    }
  }
}

@include rwd(medium) {
  @for $i from 1 through $grid_columns {
    .md-#{$i} {
      width: math.div(100%, $grid_columns) * $i;
    }
  }
}

@include rwd(exlarge) {
  @for $i from 1 through $grid_columns {
    .lg-#{$i} {
      width: math.div(100%, $grid_columns) * $i;
    }
  }
}

.col {
  padding: 0 $grid_columns-padding;
  box-sizing: border-box;

  width: 100%;
  @include rwd(small) {
    width: auto;
  }
}

.col--grow {
  flex-grow: 1;
}

.col--no-gutters {
  padding: 0;
}

.col--flex-align-center {
  display: flex;
  align-items: center;
}

.flex-end-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: wrap row;
}