.traveler-select {
  .traveler-select__options {
    display: block;
    max-height: 300px;
    overflow-y: auto;
  }

  .request-traveler-avatar {
    margin-right: 15px;
  }

  .request-traveler-name {
    font-size: 13px;
  }

  .request-traveler-avatar .user-avatar {
    width: 40px;
    height: 40px;
  }
}
