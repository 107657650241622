.d-flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.align-self-center{
  align-self: center;
}
.is-invisible{
  visibility: hidden;
}
.text-uppercase {
  text-transform: uppercase;
}
.svg {
  display: block;
}
