.document-page {
  .react-select__menu {
    z-index: 999 !important;
  }

  &--header {
    &__uppercase {

    }
  }
}

.document__amount {
  .component-loader__loader {
    top: 9px;
  }

  &--accounting {
    .component-loader__loader {
      top: 10px !important;
    }
  }
}

.documents-dialog-right {
  div[role="document"] {
    justify-content: flex-end;
    padding-right: 50px;
  }
}

#alert-dialog-slide-title h6 {
  font-family: inherit;
}

.exchange-rate--loader {
  position: relative;
  height: 119px;

  &.thin {
    height: 68px;
  }

  .component-loader__loader--small {
    top: 50%;
  }
}

.account-document-page__section-shadow {
  position: relative;
}

.document-form {
  .form-group__input-wrapper--inline {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .last-requests__dialog {
    position: absolute;

    .last-requests__dialog-content {
      background: white;
    }

    .user-profile__fields {
      flex-grow: 1;

      .form-group {
        width: 100%;
      }
    }
  }
  .add-provider-dialog {
    &__paper {
      max-height: initial;
      width: 100%;
      position: relative;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.document__close {
  font-size: 24px;
  padding-right: -29px;
  padding-top: -6px;
  color: #848484;
}
