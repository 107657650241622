.section {
  border-bottom: 1px solid $color-grey-04;
  padding-bottom: $default-padding;
  margin: $default-padding 0;
}

.section--no-margin {
  margin-top: 0;
}

.section--no-padding {
  padding-bottom: 0;
}

.section--no-border {
  border: 0;
  padding-bottom: 0;
}

.section--no-border-top {
  margin-top: -31px;
  padding-top: 30px;
  border-top: 1px solid white;
}

.user-header__wrapper {
  display: flex;
  justify-content: flex-end;
}

.user-header__title {
  padding-left: 75px;
}

.section__header {
  margin-bottom: $default-padding;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.section__header--no-margin {
  margin: 0;
}

.section__header-title {
  display: flex;
  align-items: center;
}

.section__header-subtitle {
  width: 100%;
  color: #000;
  padding-top: 0.5rem;
}

.section__header-desc {
  width: 100%;
  margin-top: 15px;
  color: $color-grey-04;
}

.section--pull-end {
  display: flex;
  justify-content: flex-end;
  .btn {
    margin-left: 10px;
  }
}
