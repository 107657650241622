.dropdown-field {
  position: relative;

  .dropdown-field__value {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .dropdown-field__value-label {
    font-size: 0.8em;
    margin-right: 10px;
    min-width: 10px;
  }

  .dropdown-field__value-change {
    font-size: 1.2em;
  }

  .dropdown-field__options {
    padding: 20px;
  }

  .dropdown-field__dropdown {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
  }

  .tooltip-content {
    padding: 0;

    &:before {
      content: '';
      background: transparent;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 15px solid #fff;
      position: absolute;
      left: 10px;
      top: -15px;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.07));
      z-index: -1;
    }

    &:after {
      content: '';
      width: 40px;
      height: 10px;
      background: #fff;
      position: absolute;
      top: 0px;
      left: 10px;
    }
  }

  &.is-open {
    .dropdown-field__dropdown {
      display: block;
    }
  }
}
