.is-font-default {
  font-size: $font-size-default;
  font-weight: $font-weight-book;
  line-height: $font-lineheight-default;
}

.is-font-small {
  font-size: $font-size-small;
  font-weight: $font-weight-book;
  line-height: $font-lineheight-small;
}

.is-font-xsmall{
  font-size: $font-size-xsmall;
  font-weight: $font-weight-book;
}

.is-font-default-weight {
  font-weight: $font-weight-book;
}

.is-font-semibold-weight {
  font-weight: $font-weight-semibold;
}

.is-font-heavy-weight {
  font-weight: $font-weight-heavy;
}

.is-text-uppercase {
  text-transform: uppercase;
}

.is-text-lowercase {
  text-transform: lowercase;
}
