.upload-cell-container {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  display: inline-block;
  height: 100%;

  .upload-cell {
    width: 100%;
    height: 100%;

    & > div {
      width: 100% !important;
      height: 100% !important;
      border: 0 none !important;

      .dropzone__icon {
        width: 100%;
        height: 130px;
        font-size: 130px;

        //position: relative;
        //width: 130px;
        //height: 130px;
        //margin: 0 auto;
        //border: 2px dashed #cbcbcb;
        //&::before {
        //  content: "";
        //  position: absolute;
        //  border: 1px solid #fff;
        //  width: 100%;
        //  height: 100%;
        //  top: -1px;
        //  left: -1px;
        //}
      }

      .dropzone__subtitle, .dropzone__title {
        text-align: center;
      }
    }

    &.upload-cell--dashboard {
      .dropzone__header {
        margin-bottom: 12px;
      }
    }
  }

  .upload-cell-dashboard {

  }
}
