.settings-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}

// section
.settings-page__section {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

// account container
.settings-page__account-container {
  flex-basis: 100%;
  margin-right: 30px;
  position: relative;
  max-width: calc(100% - 355px);

  .tabs{
    width: 100%;
  }
}

.settings-page__info {
  display: flex;
  align-items: stretch;
  margin-bottom: 40px;
}

.settings-page__account-dashboard {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 25px;
}

.settings-page__account-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.settings-page__account-avatar {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
}

.settings-page__account-name {
  display: block;
  font-weight: 500;
}

.settings-page__account-contact-link {
  color: $color-grey-05;
}

.settings-page__change-avatar-link {
  font-size: 13px;
  margin-top: auto;
  cursor: pointer;
}

// aside
.settings-page__aside {
  position: relative;
  min-width: 355px;
  max-width: 355px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 30px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: -1px;
    display: block;
    width: 1px;
    background-color: $color-grey-04;
  }
  &::before {
    bottom: -60px;
    top: -90px;
  }
}

.settings-page__aside-header {
  display: block;
  text-align: center;
  margin-bottom: 70px;
}

.settings-page__aside-heading {
  display: block;
}

.settings-page__hierarchy-row {
  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 50px;
    background-color: $color-grey-04;
    margin: 15px auto 20px auto;
  }

  &:last-child::after {
    content: none;
  }
}

.settings-page__hierarchy-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  display: block;
  object-fit: cover;
}

.settings-page__hierarchy-avatar--large {
  width: 73px;
  height: 73px;
}

.settings-page__hierarchy-name {
  color: #000000;
  display: block;
  text-align: center;
}

.settings-page__hierarchy-list-wrapper {

}

.settings-page__hierarchy-list {
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: space-around;
  border: 1px solid $color-grey-04;
  padding: 25px 25px 5px;
  flex-wrap: wrap;

  li {
    width: 70px;
    margin-bottom: 20px;
  }
}

.settings-page__hierarchy-more {
  margin-top: 25px;
  text-align: center;
}

.user-status{
  margin-bottom: 25px;

  &__date{
    margin-left: 20px;
    width: 200px;
  }
}
