.accordion {

}

.accordion__bar {
  //cursor: pointer;
  //align-items: center;
}

.accordion__bar-icon {
  cursor: pointer;
  .icon {
    color: $color-default-02;
    display: block;
  }
}

@keyframes accordion_open {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  99% {
    max-height: 100vh;
  }
  100% {
    max-height: initial;
    overflow: visible;
  }
}

@keyframes accordion_close {
  0% {
    max-height: 100vh;
    overflow: hidden;
  }
  100% {
    max-height: 0;
    overflow: hidden;
  }
}

.accordion-animate {
  margin-left: -10px;
  margin-right: -10px;
}

.accordion__content {
  //overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  //max-height: 0;
  //overflow: hidden;
}

.accordion.is-open {
  .accordion__content {
    animation: accordion_open 1s forwards;
  }
}

.accordion.is-close {
  .accordion__content {
    overflow: hidden;
    animation: accordion_close 1s forwards;
  }
}

.accordion.is-closed {
  .accordion__content {
    overflow: hidden;
    max-height: 0;
  }
}

.accordion.is-active {
  .accordion__content {
    overflow: visible !important;
    max-height: initial !important;
    //animation: none;
  }
}

.table-nested-padding {
  .table-accordion__head,
  .table-accordion__footer {
    padding-right: 0;
  }

  .table-accordion__col:last-of-type {
    padding-right: 0;
  }

  .accordion__content > .accordion__content > .row > .table-accordion__col:first-of-type {
    padding-left: 30px;
  }

  .accordion__content > .accordion__content > .accordion__content > .row > .table-accordion__col:first-of-type {
    padding-left: 60px;
  }

  .accordion__content > .accordion__content > .accordion__content > .accordion__content > .row > .table-accordion__col:first-of-type {
    padding-left: 90px;
  }
}

.reservation-engine-button {
  position: absolute;
  top: calc(50% - 30px);
  text-align: center;
  left: 250px;
  color: $color-default-02;
  cursor: pointer;

  .icon {
    font-size: 22px;
  }

  span {
    font-weight: 500;
    border-bottom: 1px solid $color-default-02;
  }

}