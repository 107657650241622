.timeline-icon {
  cursor: pointer;
  margin-left: 5px;
  text-align: right;

  .icon-trash, .icon-edit, .icon-checked {
    line-height: 38px;
  }
}


.timeline-icon--disabled {
  pointer-events: none;

  i {
    color: #ccc;
    background: linear-gradient(90deg, #ccc 0%, #ccc 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    opacity: 0.7;
  }
}


.timeline-change--disabled {
  pointer-events: none;
  opacity: 0.7;
}
