.last-requests {

}

.last-requests__header {
  text-transform: uppercase;
}

.last-requests__row {
  box-shadow: $default-shadow;
  margin-top: 20px;
  padding: 20px;
}

.last-requests__row-icons-list {
  list-style-type: none;
  margin-top: 5px;
  padding: 0;


  li {
    font-size: 22px !important;
    display: inline-block;
    padding: 0 8px 4px 0;

    i {
      color: $color-grey-07 !important;
    }
  }
}

.last-requests__col--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.last-requests__row-date {
  margin-bottom: 10px;
  font-weight: 500;
}

.last-requests__row-description {
  font-weight: 500;
}

.last-requests__row-amount-label {
  margin-bottom: 10px;
}

.last-requests__dialog h2 {
  font-family: 'unisans' !important;
}


.last-requests__dialog-content {
  overflow-y: visible !important;
}

.last-requests__dialog-paper {
  position: relative;
  //padding: 6px 12px 12px;
}

.last-requests__dialog-actions {
  padding-right: 15px;
  padding-bottom: 15px;
  margin: 0 !important;
}

.last-requests__dialog-close {
  position: absolute;
  top: 7px;
  right: 5px;
  font-size: 24px;
  cursor: pointer;
  color: $color-grey-05;
}
