.state-text-button {
  flex-grow: 1;
  color: $color-default-02;
  cursor: pointer;
  display: table;
  margin-right: 10px;

  &.state-text-button--is-active {
    font-weight: 400;
  }

  @include rwdmax(exlarge) {
    white-space: nowrap;
  }
}

//.filters-container__col.filter.xs-3.sm-2.md-2 {
//  .state-text-button {
//  	margin-left: -10px;
//  }
//}