@import '../helpers';

/*
a
a:hover
 */

a {
  color: $color-default-02;
  text-decoration: none;
}

.a--link {
	box-shadow: 0 0 0 transparent;
	border: 0;
	padding: 0;
	min-width: auto;
	color: #64e0ba;
	cursor: pointer;
	background: $gradient-default-01;
	background-clip: border-box;
	-webkit-background-clip: text;
	//-webkit-text-fill-color: transparent;
}

.a--unformatted {
	color: $color-grey-06;
	text-decoration: none;
}