.vat-summary {
  .table-accordion-container {
    margin: 0;

    .table-accordion__row {
      padding-bottom: 0 !important;
    }
    .form-group {
      min-height: auto !important;
    }
    .form-group__label {
      margin: 0 !important
    }
    .vat-summary__amount-label {
      text-align: right;
      width: 100%;
      display: inline-block;
    }
  }
}
