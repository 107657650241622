$shadow-color: rgba(0, 0, 0, .1);

.tabs {
  box-shadow: 1px 1px 15px 0 $shadow-color;
}

.tabs__titles {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.tabs__title {
  position: relative;
  display: inline-block;
  height: 65px;
  line-height: 65px;
  text-align: center;
  color: $color-grey-05;
  flex-grow: 1;
  cursor: pointer;
  box-shadow: inset 15px -15px 15px -15px $shadow-color;
  &:first-child {
    box-shadow: inset 0 -15px 15px -15px $shadow-color;
  }
  & > a {
    display: inline-block;
    width: 100%;
    color: inherit;
  }
}
.tabs__title--active {
  box-shadow: inset 15px 0 15px -15px $shadow-color !important;
  &:first-child {
    box-shadow: none !important;
  }
}

.tabs__content {
  box-sizing: border-box;
  padding: 30px;
}

.tabs__content-heading {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.tabs__content-heading-company{
  min-width: 200px;
  margin-right: 20px;
}

.tabs__content-heading-right{
  display: inline-flex;
  align-items: center;
}
