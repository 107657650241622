.report-bar {
  width: 100%;
  height: 107px;
  background: #2dcee3 linear-gradient(90deg, #2dcee3 0%, #64e0ba 100%);
  position: relative;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.report-bar__progress {
  height: 100%;
  background: #ffd800 linear-gradient(90deg, #ffd800 0%, #FFC00C 100%);
}

.report-bar__expense {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.report-bar__expense-label {
  display: block;
  font-weight: 500;
  font-size: 14px;
}

.report-bar__expense-icon {
  display: block;
  font-size: 42px;
  color: #fff;
  text-align: center;
}

.report-bar__content {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.report-bar__content-left {
  text-align: left;
}

.report-bar__content-right {
  text-align: right;
}

.report-bar__label {
  display: block;
  font-size: 16px;
}

.report-bar__value {
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
  & > span:first-child {
    margin-right: 5px;
  }
}

.reports {
  .input-wrapper--date .react-datepicker__input-container input {
    width: 100%;
  }
}

.reports-section {
  padding: 0;
  // margin-top: 20px;

  .panel__header {
    padding: 15px;
    background: linear-gradient(90deg, #2dcee3 0%, #64e0ba 100%);

    h5 {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5384616;
      text-transform: uppercase;

      i {
        font-size: 22px !important;
        color: #ffffff;
        float: left;
        line-height: 1;
        margin-right: 7px;
      }
    }
  }


  .panel__content {
    padding: 0 15px 15px;

    h6 {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 15px;
      padding-top: 20px;
      font-weight: 500;
    }
  }

  &__empty {
    .panel__content {
      display: none;
    }
  }
}

.reports-timeline {
  &--item {
    display: flex;
    position: relative;
    padding-left: 15px;
    padding-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      background: #29B6F6;
    }

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: -3px;
      height: 8px;
      width: 8px;
      background: #29B6F6;
      border-radius: 50%;
    }

    &__name {
      flex-grow: 1;
      font-weight: 500;
    }

    &__summary {
      font-weight: 200;
      font-size: 13px;
    }

    &__days {
      font-size: 17px;
    }

    &:last-of-type::before {
      height: 5px;
    }
  }

  &--header {
    font-weight: 500;

    &::before {
      top: 8px;
    }
  }

  &--summary {
    background: #fcfcfc;
    margin: -15px;
    margin-top: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;

    &__title {
      flex-grow: 1;
    }

    &__count {
      font-weight: 500;
    }
  }
}

.costs-ranking-item {
  display: flex;
  padding-bottom: 15px;

  &--image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    display: block;
    object-fit: cover;
  }

  &--person {
    flex-grow: 1;
    &__name {
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__position {
      color: #999999;
      font-size: 11px;
    }
  }

  &--count {
    text-align: right;
  }

  &:first-of-type {
    margin-top: -5px;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.reports-section .chart-widget-legend {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 14px;
  text-align: center;

  li {
    margin: 5px 10px 0;
    padding: 7px 8px 5px;
    display: inline-block;
    transition: all ease-in-out 0.15s;
  }
}
