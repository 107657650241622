.company-settings {
  .btn--xs {
    min-width: 70px;
    padding: 0 10px;
    height: 27px;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }

  .btn--inline {
    display: inline;
  }

  .table__header-actions {
    margin-right: 95px;
  }

  .table__header-actions--small {
    margin-right: 15px;
    padding-bottom: 1rem;
  }

  .table-accordion-container {
    padding-bottom: 0;
    overflow: initial;
  }

  .table-accordion {
    overflow: initial;

    .draggable-item {

    }
  }

  .file-select {
    width: auto;
    margin-right: 1rem;
  }

  .import-button {
      min-height: 35px;
  }

  .table-accordion.table-accordion--is-striped .even {
    background: #f5f5f5;
  }
}
