.main-nav {
  background-color: $color-grey-02;
  box-shadow: 2px 2px 15px 0 $color-grey-03;
  flex-shrink: 0;
  width: 220px;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  min-height: 100vh;
  //transform: translateX(-222px);
  margin-left: -222px;
  transition: margin 0.5s;
  @include rwd(small) {
    margin-left: 0;
  }
  @include rwdmax(exlarge) {
    width: 190px;
  }

  @include rwd(medium) {
    position: fixed;
    margin-left: 0;
  }
}

.main-nav__logo {
  margin: 0 auto;
  width: 100%;
  height: 100px;
  color: white;
  transform: translateY(45px);
}

.main-nav.small .main-nav__logo {
  padding: 0;
}

.logo {
  display: block;
  margin: 0 auto;
  width: calc(53% + 7px);
  height: auto;
}

.main-nav.small .logo {
  content: url('../../img/logo_small.svg');
  width: 37px;
  height: 37px;
}

.logo--empty {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: $gradient-default-01;
}

.main-nav__wrapper {
  position: relative;
  height: 100vh;
  padding-top:  30px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 2px);
  //z-index: 1;
  @include rwd(small) {
    overflow: visible;
  }
}

.main-nav.small {
  @include rwd(small) {
    width: auto;
    .main-nav__list-element-name {
      padding-left: 15px !important;
      padding-right: 15px;
    }
    .main-nav__list-element-name span {
      display: none;
    }
  }
}

.main-nav.open {
  margin-left: 0;
  z-index: 99;
}

.main-nav--hidden {
  width: auto;
}

.main-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: calc(100% - 115px);
}

.main-nav__list:not(.main-nav__list--submenu) {
  padding-top: 8px;
}

@media (max-height: 650px) {
  .main-nav__list {
    overflow-y: auto;
  }
}

.main-nav__list .main-nav__list {
    overflow: hidden;
}

.main-nav--hidden .main-nav__list-element-name {
  padding: 10px 15px;
}

.main-nav__list-element-name--has-menu.active + .main-nav__list--submenu {
  display: block;
}

.main-nav__list-element {
  cursor: pointer;
  .icon {
    z-index: 2;
    font-size: 27px;
  }
}

.main-nav__list-element-name {
  display: flex;
  z-index: 2;
  position: relative;
  color: inherit;
  text-decoration: inherit;
  width: 100%;
  align-items: center;
  padding: 5px 28px;
  @include rwdmax(exlarge) {
    padding: 5px 15px;
  }
}

.main-nav__list-element-name span {
  display: block;
  margin-left: 15px;
  transition: color 0.5s, background 0.5s;
  font-size: 15px;
  color: $color-grey-07;
}

.main-nav__list-element-name:hover span {
  //background: -webkit-linear-gradient(90deg, $color-default-01, $color-default-03);
  color: $color-default-02;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}

.main-nav--hidden .main-nav__list-element-name span {
  display: none;
}

.main-nav__list-element-name.active {
  background-color: #fff;
  box-shadow: 0 0 15px $color-grey-03;
  font-weight: 400;
}

.main-nav__list-element-name:before {
  content: " ";
  opacity: 0;
  width: 3px;
  display: block;
  height: 100%;
  background: linear-gradient($color-default-01, $color-default-03);
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.6s;
}

.main-nav__list-element-name.active:before {
  opacity: 1;
  transition: opacity 0.3s;
}

.main-nav__list--submenu {
  width: 100%;
  display: none;
  .main-nav__list-element-name {
    padding-left: 55px;
    @include rwdmax(exlarge) {
      padding-left: 40px;
    }
  }

  .main-nav__list-element-name:hover, .main-nav__list-element-name.active {
    background-color: lighten($color-grey-03, 5%);
    box-shadow: 0 0 15px inset $color-grey-03;
    z-index: 0;
    &:before {
      opacity: 0;
    }
  }
}

.main-nav__expand {
  position: relative;
  bottom: 18px;
  border: 0;
  border-top: 1px solid #ededed;
  width: calc(100% - 2px);
  background: #fff;
  padding: 5px 10px;
  text-align: right;
  cursor: pointer;
  display: none;
  z-index: 2;
  @include rwd(small) {
    display: block;
  }
  .icon {
    //transition: transform 0.5s;
    transform: rotate(90deg);
    display: inline-block;
    font-size: 10px;
    margin: 5px 0;
  }
}

.main-nav.small .main-nav__expand .icon {
  transform: rotate(270deg);
  display: inline-block;
}

.main-nav__open {
  position: relative;
  border: 0;
  cursor: pointer;
  outline: none;
  top: 20px;
  left: 20px;
  height: 30px;
  background: transparent;
  transition: right 0.5s;
  @include rwd(small) {
    top: 39px;
    display: none;
  }
}

.main-nav.open .main-nav__open:before {
  content: " ";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  width: 100vw;
  @include rwd(medium) {
    display: none;
  }
}

.main-nav.open .main-nav__open {
  right: 10px;
}

.main-nav__open span {
  display: block;
  width: 25px;
  height: 2px;
  position: relative;
  background-color: $color-grey-06;
  &:before, &:after {
    content: " ";
    position: absolute;
    top: -8px;
    background-color: $color-grey-06;
    width: 25px;
    height: 2px;
    left: 0;
    transition: top 0.5s, transform 0.5s;
  }
  &:before {
    top: -7px;
  }
  &:after {
    top: 7px;
  }
}

.main-nav.open .main-nav__open span {
  height: 0;
  &:before {
    transform: rotate(45deg);
    top: 0;
  }
  &:after {
    transform: rotate(-45deg);
    top: 0;
  }
}

.main-nav {
  .main-nav__icon {
    display: flex;
    margin: 0;
    width: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .main-nav__list-element-name {
    height: 50px;
  }

  .icon-card-outlined {
    font-size: 18px;
  }
}
