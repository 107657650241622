.panel {
  padding: 20px 25px;
  box-shadow: 0 0 15px $color-grey-03;
  position: relative;
  margin-bottom: 30px;
  background-color: $color-grey-02;

}

.panel__header {
  margin-bottom: 20px;
  h1 {
    color: $color-grey-05;
    font-size: 15px;
    text-transform: uppercase;

    .icon {
      color: $color-grey-05;
      font-size: 20px;
      margin-right: 7px;
    }
  }
}

.panel--header-icon {
  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.panel__content {

}

.panel__close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: $color-default-01;
  cursor: pointer;
}

.panel--google_map {
  height: 100%;
  .panel__content {
    height: calc(100% - 55px);
  }
}

.google_map-desc {
  padding: 10px 25px 0;
  display: block;
}

.panel--full-content {
  .panel__content {
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: -35px;
  }
}

.input-add-provider{
  display: flex;

  .provider-add-btn-wrapper {
    display: flex;
    flex-grow: 1;
    padding-top: 32px;
    padding-bottom: 6px;
  }

  .provider-add-btn {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    align-self: center;
    flex-grow: 1;
    display: flex;
  }
}

.no-margin{
  margin: 0 !important;
}

.zero-placeholder{
  position: absolute;
  bottom: 27px;
  left: 28px;
  padding: 3px 10px;
  background: #fff;
}
