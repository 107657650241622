.declarations {
  width: 100%;
  margin-bottom: 48.5px;
}

.declarations__grid-row {
  display: grid;
  width: 100%;
  grid-template-columns: 80px 125px repeat(3, 1fr) 25px;

  @media screen and (min-width: 1620px) {
    grid-template-columns: 150px 125px repeat(3, 1fr) 75px;
  }

  &--gray {
    background-color: #f5f5f5;
  }
}

.declarations__grid-column {
  //align-items: center;
}

.declarations__heading {
  display: block;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.23px;
  line-height: 15px;

  &--text-left {
    text-align: left;
  }

  &--text-right {
    text-align: right;
  }
}

.declarations__day {
  margin-left: -25px;
  padding-left: 25px;
  width: calc(100% + 50px);

  &--gray {
    background-color: #f5f5f5;

    .declarations__flag-wrapper,
    .declarations__crossing-time,
    .declarations__crossing-timezone,
    .declarations__crossing-duration {
      &::before {
        background-color: #f5f5f5;
      }
    }

  }

}

@mixin declarations-columns-background($direction) {
  .declarations__flag-wrapper,
  .declarations__crossing-time,
  .declarations__crossing-timezone,
  .declarations__crossing-duration {
    &::before {
      background: linear-gradient(to $direction, #f5f5f5 50%,#ffffff 50%);
    }
  }
}

.declarations__day:not(:last-child):not(.declarations__day--gray) {
  .declarations__grid-row:not(.declarations__step) {
    &:last-child {
      @include declarations-columns-background(top);
    }
  }
}

.declarations__day--gray {
  .declarations__grid-row:not(.declarations__step):not(:first-child) {
    &:last-child {
      @include declarations-columns-background(bottom);
    }
  }
}

.declarations__day--gray {
  .declarations__grid-row:not(.declarations__step):not(:last-child) {
    &:first-child {
      @include declarations-columns-background(top);
    }
  }
}

.declarations__day:not(:first-child):not(.declarations__day--gray) {
  .declarations__grid-row:not(.declarations__step) {
    &:first-child {
      @include declarations-columns-background(bottom);
    }
  }
}

.declarations__header {
  margin-bottom: 26px;

  & .declarations__grid-column:nth-child(2) {
    padding-left: 37px;
    white-space: nowrap;
  }
}

.declarations__body {
  position: relative;
}

.declarations__crossing {
  position: relative;
  margin-top: -8.5px;
  width: 95%;
  margin-bottom: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    display: block;
    height: 1px;
    width: calc(100% + 50px);
    background-color: #d0d0d0;
  }

  &:last-child::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -25px;
    right: -38px;
    display: block;
    height: 8px;
    background-color: #fff;
  }

  //&:last-child {
  //  bottom: -8.5px;
  //}
}

//.declarations__day {
//  position: relative;
//
//  &::before {
//    content: "";
//    position: absolute;
//    bottom: 0;
//    left: -25px;
//    right: -50px;
//    display: block;
//    height: 9px;
//    background-color: #fff;
//  }
//}

.declarations__day--gray {
  .declarations__crossing.first {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: -25px;
      right: -38px;
      display: block;
      height: 7px;
      background-color: #fff;
    }
  }
}

.declarations__crossing-time {
  position: relative;
  z-index: 1;
  display: table;
  width: 36px;
  color: #000;
  font-size: 12px;
  text-align: right;
  line-height: 16px;
  box-sizing: border-box;
  margin-left: auto;
  padding-left: 5px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
}

.declarations__crossing-timezone {
  position: relative;
  z-index: 1;
  display: table;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  padding-right: 6px;
  text-align: right;
  width: 85px;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }

  &--no-flag {
    width: 81px;
    padding-right: 3px;
    padding-left: 3px;
    text-align: right;
    margin-right: 10px;
  }
}

.declarations__country-column {
  display: flex;
}

.declarations__flag-wrapper {
  display: block;
  width: 35px;
  height: 16px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}

.declarations__flag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.declarations__crossing-label {
  position: relative;
  z-index: 1;
  grid-column: 3 / 6;
  height: 17px;
  border-radius: 4px;
  background-color: #dfdfdf;
  box-sizing: border-box;
  padding-left: 7px;
}

.declarations__crossing-label-text {
  display: block;
  color: #000;
  font-size: 12px;
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.declarations__crossing-duration {
  position: relative;
  z-index: 1;
  display: block;
  width: 25px;
  text-align: center;
  margin: 0 auto;
  left: 12px;
  color: #cbcbcb;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
}

.declarations__step {
  padding: 20px 0;
  grid-template-columns: 80px 125px repeat(3, 1fr) 50px;

  @media screen and (min-width: 1620px) {
    grid-template-columns: 150px 125px repeat(3, 1fr) 100px;
  }
}

.declarations__step-date-wrapper {
  grid-column: 1 / 3;
}

.declarations__step-date {
  color: #000;
  font-size: 14px;
  line-height: 17px;
}

.declarations__checkbox .checkbox-wrapper__input-wrapper {
  margin: 0 auto;
}

.declarations__checkbox {
  display: flex;
  justify-content: center;
}

.declarations__tooltip {
  margin-bottom: 20px;

  // margins based on grid from .declarations__grid-row
  // done to center label above checkboxes (MIN-379)
  margin-left: calc(80px + 125px);
  margin-right: 25px;

  @media screen and (min-width: 1620px) {
    margin-left: calc(150px + 125px);
    margin-right: 75px;
  }
}

.declarations__tooltip-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.declarations__period-change {
  display: flex;
  justify-content: flex-end;
}
