.account-document-page__add-button-wrapper {
  margin: 30px 0;
  padding: 15px;

  .account-document-page__add-button {
    margin: 0 auto;
  }
}

.col--img {
  width: calc(100% - 500px) !important;
}

.col--form {
  width: 500px !important;
}

.vat-summary {
  .table-accordion-container {
    padding-bottom: 0;
  }
}

.account-document-page__amount-fields {
  display: flex;

  > div {
    width: 25%;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }
}

.account-document-page__rate {
  > div {
    width: calc(50% - 10px);
    min-width: 110px;
  }
}

.account-document-page__section-shadow {
  box-shadow: 1px 1px 10px $color-grey-03;
  margin: 0 0 20px 0;
  padding: 20px;
}

.account-document-page__section-icon {
  margin-right: 5px;
}

.account-document-page__no-rate {
  font-size: 13px;
  width: auto !important;
}

.account-document-page__rate .input-suggestion{
  width: auto !important;
}
