.toast-custom__outer-container {
  display: block !important;
  min-height: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  overflow: visible;
  border-radius: 10px !important;
  box-shadow: 2px 2px 5px $color-grey-03;

  button {
    display: none;
  }
}

.toast-custom__inner-container {
  height: 100%;
  width: 100%;
}

.toast-custom {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  display: flex;
  padding-left: 13px;
  border: 2px solid transparent;
  border-radius: 10px;
  box-shadow: inset -999px 0 0 #fff;
}

.toast-custom__icon-container {
  align-items: center;
  display: flex;
  width: 40px;
  animation-name: fade-out-animation;
  //animation-duration: 2s;
  animation-delay: .3s;
  animation-timing-function: ease;
}

.toast-custom__icon {
  font-size: 30px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &.error {
    background-image: url('../../img/error.svg');
  }

  &.success {
    background-image: url('../../img/success.svg');
  }

  &.info {
    background-image: url('../../img/info.svg');
  }

  &.warning {
    background-image: url('../../img/warning.svg');
  }
}

.toast-custom__content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 15px 20px;
  font-weight: 400;
  font-family: 'unisans';
  max-width: 300px;
  span {
    text-align: center;
    font-size: 12px;
    line-height: 17px;
  }
}

.toast-custom.toast-custom--type-info {
  border-color: $color-default-03;
  .toast-custom__icon {
    color: $color-default-03;
  }
  .toast-custom__content {
    color: $color-default-03;
  }
}

.toast-custom.toast-custom--type-success {
  border-color: $color-default-01;
  .toast-custom__icon {
    color: $color-default-01;
  }
  .toast-custom__content {
    color: $color-default-01;
  }
}

.toast-custom.toast-custom--type-warning {
  background: $gradient-warning-01;
  background-origin: border-box;
  -moz-background-origin: border;

  .toast-custom__icon {
    color: $color-warning;
  }
  .toast-custom__content {
    color: $color-warning;
  }
}

.toast-custom.toast-custom--type-error {
  background: $gradient-danger-01;
  background-origin: border-box;
  -moz-background-origin: border;

  .toast-custom__icon {
    color: $color-primary-02;
  }
  .toast-custom__content {
    color: $color-primary-02;
  }
}

.toast-custom__progress {
  display: none;
}

.toast-custom__transition--in {
  animation: fade-in .7s ease-out both;
}

.toast-custom__transition--out {
  animation: fade-out .7s ease-out both;
}

.Toastify__toast {
  background: transparent;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
