@import '../helpers';

.accommodation__heading {
  display: block;
  margin-bottom: 20px;
}

// FORM
.accommodation__form-content {
  display: flex;
}

.accommodation__inputs-container {
  flex-basis: 333px;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-right: 25px;

  @media screen and (min-width: 1465px) {
    margin-right: 40px;
  }
}

.accommodation__map-container {
  width: 100%;
}

.accommodation__inputs {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}
.accommodation__inputs--save {
  box-sizing: border-box;
  //border-top: 1px solid #cacaca;
  //padding-top: 5px;
  //margin-top: 25px;
  margin-bottom: 0;
  justify-content: space-between;
}

.accommodation__button {
  display: inline-block;
}

.accommodation__button-resign {
  padding: 0 10px;
  min-width: 201px;
  height: 27px;
  line-height: 24px;
  margin-right: 20px;
}

.accommodation__button-search {
  padding: 0 10px;
  min-width: 112px;
  height: 27px;
  line-height: 27px;
}

.accommodation__requested-amount-inputs {
  display: flex;
  align-items: flex-start;
}

.accommodation__input-container {
  max-width: 180px;
  min-width: 180px;
  width: 100%;
  margin-right: 30px;
  position: relative;
  & .form-group__error {
    text-align: left;
  }
  &:last-child {
    margin-right: 0;
  }
}
.accommodation__input-container--radios {
  max-width: none;
  min-width: auto;
  .radio-wrapper {
    max-width: 180px;
    min-width: 180px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.accommodation__input-container--radios-private {
  margin-bottom: 20px;
}
.accommodation__input-container--stars {
  align-items: center;

  & > .icon {
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  .accommodation__input-label{
    margin-bottom: 3px;
  }
}
.accommodation__input-container--checkboxes {
  max-width: none;
  min-width: auto;
  display: flex;
  justify-content: flex-start;
}
.accommodation__input-container--requested-amount {
  margin-right: 10px;
}
.accommodation__input-container--currency {
  max-width: 70px;
  min-width: 70px;
}

.accommodation__checkbox-container {
  display: flex;
  transform: translateY(-6px);
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
}

.accommodation__input-label {
  display: block;
  font-size: 12px;
  height: 16px;
  margin-bottom: 20px;

  &--small-margin{
    margin-bottom: 15px;
    margin-top: 6px;
  }
}

// MAP
.accommodation__map-title {
  margin-bottom: 15px;
}

.accommodation__map {
  width: 100%;
  height: 100%;
  background-color: $color-grey-04;
  img[src*="undo_poly.png"] {
    display: none;
  }
}

// RANGE
.accommodation__range {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 75%;
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  margin-left: 8px;
  left: 0;
  right: 0;
  padding: 10px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .07);

  @media screen and (min-width: 1601px) {
    width: 383px;
  }
}

.accommodation__range-title {
  font-size: 12px;
  white-space: nowrap;
}

.accommodation__range-distance {
  font-size: 12px;
  white-space: nowrap;
  width: 35px;
}

.accommodation__range-container {
  flex-basis: 187px;
  margin: 0 12px;
  position: relative;
}

.accommodation__range-icons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
  transform: translateY(-15px);
  & > .icon {
    font-size: 15px;
    color: #848484;
  }
}

.accommodation__range-input {
  height: 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.accommodation__range-value {
  display: none;
}

.accommodation__range-slider-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left .25s ease;
}

.accommodation__range-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -10px;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0 rgba(1, 1, 0, .11);
}

.accommodation__range-track {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
}
.accommodation__range-track--active {
  position: absolute;
  height: 100%;
  background: $gradient-default-01;
  border-radius: 4px;
  transition: width .25s ease;
}

.accommodation__range-label {
  display: none;
}

// SEARCH DASHBOARD
.accommodation__search-dashboard {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

// save
.accommodation__save {
  display: flex;
  align-items: center;
}

.accommodation__amount-input-wrapper {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.accomodation__amount-warning-icon {
  font-size: 22px;
}

.accommodation__amount-input {
  margin: 0 5px;
}

// HOTELS
.accommodation__hotels-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 370px;
}
.accommodation__hotels-options {
  margin-bottom: 20px;
}
.accommodation__hotels-option-button {
  border: 1px solid $color-grey-04;
  border-radius: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
  background: 0;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}

.accommodation__hotels-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.accommodation__hotels-list-empty {
  position: absolute;
}

.accommodation__hotel-offer {
  max-width: 253px;
  min-width: 253px;
  width: 100%;
  height: 336px;
  margin-right: 20px;
  box-sizing: border-box;
  border: 1px solid $color-grey-04;
  border-radius: 4px;
  display: flex;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .07);
  background-color: #fff;
  &:last-child {
    margin-right: 0;
  }
}
.accommodation__hotel-offer--active {
  border: 2px solid #2dcee3;
  box-shadow: 1px 1px 15px 0 rgba(45, 206, 227, .3);
  & .accommodation__hotel-details-bar {
    border-right: 2px solid #2dcee3;
  }
}

.accommodation__hotel-details-bar {
  border-right: 1px solid $color-grey-04;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 10px 4px 0;
}

.accommodation__hotel-accessories,
.accommodation__hotel-stars {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-self: center;
  font-size: 20px;
}
.accommodation__hotel-accessories {
  display: none;
  & .icon {
    color: #505050;
  }
}
.accommodation__hotel-stars {
  & > .icon {
    margin-bottom: -5px;
  }
}

.accommodation__hotel-info {
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accommodation__hotel-gallery {
  width: 100%;
  height: 109px;
  position: relative;
  margin-bottom: 10px;
}

.accommodation__hotel-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.accommodation__hotel-gallery-button {
  position: absolute;
  top: 50%;
  padding: 0;
  background-color: transparent;
  z-index: 1;
  font-size: 22px;
  transform-origin: center center;
  border: 0;
  line-height: 22px;
  box-sizing: border-box;
  transform: translateY(-50%);
  cursor: pointer;
  outline: 0;
  height: 38px;
  width: 109px;
  & > .icon {
    color: #fff;
  }
}
.accommodation__hotel-gallery-button--prev {
  left: -36px;
  transform: translateY(-50%) rotate(90deg);
  background: linear-gradient(180deg, transparent 0%, rgba(26, 26, 26, .7) 100%);
}
.accommodation__hotel-gallery-button--next {
  right: -36px;
  transform: translateY(-50%) rotate(-90deg);
  background: linear-gradient(180deg, transparent 0%, rgba(26, 26, 26, .7) 100%);
}

.accommodation__hotel-info-details {
  position: relative;
  padding-left: 30px;
}

.accommodation__hotel-heading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.accommodation__hotel-name {
  display: block;
  font-size: 18px;
  font-weight: 500;
  max-width: 168px;
  white-space: nowrap;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accommodation__hotel-address {
  color: $color-grey-05;
  align-items: center;
  margin-bottom: 10px;
  max-width: 168px;
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  display: inline-block;
}

.accommodation__hotel-description-icon {
  font-size: 21px;
  margin-right: 5px;
  position: absolute;
  left: 4.5px;
  color: #e74d3c;
  cursor: pointer;
}
.accommodation__hotel-address-icon {
  font-size: 21px;
  color: #e74d3c;
  display: inline-block;
  top: -9px;
}

.accommodation__hotel-address-wrapper {
  margin-left: -27px;
}

.accommodation__hotel-price-container {
  text-align: right;
}

.accommodation__hotel-price {
  font-size: 18px;
  display: block;
  font-weight: 500;
}

.accommodation__hotel-price-details {
  font-size: 12px;
}

.accommodation__hotel-net-price {
  display: block;
  margin-bottom: 5px;
}

.accommodation__hotel-select-btn {
  padding: 0 10px;
  min-width: 88px;
  height: 39px;
  line-height: 39px;
  margin: 0 auto;
}

// HOTEL DETAILS
.accommodation__hotel-details-container {
  width: 100%;
  display: flex;
  border: 1px solid $color-grey-04;
  border-radius: 4px;
  margin-bottom: 20px;
}

.accommodation__hotel-details-main {
  padding: 12px 14px;
  width: 100%;
}

.accommodation__hotel-details-gallery {
  //position: relative;
  //display: grid;
  //grid-template-columns: repeat(6, 1fr);
  //grid-gap: 15px;
  //margin-bottom: 15px;
  //overflow-y: scroll;
  height: 120px;
}

.accommodation__hotel-gallery-image {
  width: 100%;
  height: 109px;
  object-fit: cover;
  cursor: pointer;
}

.accommodation__hotel-details-name {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-right: 18px;
}

.accommodation__hotel-details-address {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.accommodation__hotel-details-address-icon {
  color: #e74d3c;
  margin-right: 3px;
  font-size: 14px;
}

.accommodation__hotel-text {
  width: 100%;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.accommodation__hotel-details-copy {
  margin: 0;
}

.accommodation__hotel-details-packets {
  display: flex;
  align-items: center;
}

.accommodation__hotel-packet {
  padding: 12px 15px;
  box-sizing: border-box;
  border: 1px solid $color-grey-04;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .07);
  border-radius: 5px;
  min-width: 171px;
  max-width: 171px;
  width: 100%;
  height: 224px;
  margin-right: 20px;
  display: flex;
  flex-flow: column;
  &:last-child {
    margin-right: 0;
  }
}

.accommodation__hotel-packet--selected {
  border-color: $color-default-01;
}

.accommodation__hotel-packet-items-wrapper {
  height: 54px;
}

.accommodation__hotel-packet-item {
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
    margin-right: 5px;
  }
}

.accommodation__hotel-packet-prices {
  margin-top: 10px;
  text-align: center;
}

.accommodation__hotel-packet-button {
  margin: auto auto 0 auto;
  padding: 0 10px;
  min-width: 88px;
  height: 39px;
  line-height: 39px;
}

.accommodation__offers {
  height: 413px;
  max-width: 650px;
  margin-left: auto;
  position: relative;

  @media screen and (min-width: 1465px) {
    height: 510px;
  }
}

.accommodation__hotel-description-modal {
  max-width: 700px;
  max-height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.accommodation__search-dashboard-button {
  display:inline-block;
}


.accommodation__input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accommodation__buttons {
  display: flex;
  justify-content: flex-end;
}
