//based on train-trip__dialog styles

$spacingRight: 25px;

.trip-dialog{
  max-width: 1355px;
  width: 100%;

  @media screen and (max-width: 1366px) and (max-height: 768px) {
    height: 100%;
    max-width: none;
  }

  @media screen and (min-height: 900px) {
    height: calc(100% - 100px);
  }

  & > div {
    height: 100%;
  }

  & > div > div:not(.tariff-conditions__title) {
    height: 100%;
  }

  &.tariff-conditions > div > div:not(.tariff-conditions__title) {
    height: calc(100% - 100px);
  }

  &__list{
    list-style-type: none;
    margin: 0;
    padding: 5px $spacingRight 5px 5px;
    display: flex;
    flex-direction: column;

  }

  &__list-wrapper{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__list-wrapper > &__header{
    padding-left: 10px;
  }
  &__no-results{
    span {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }

  &__return-icon {
    display: inline-block;
    transform-origin: center center;
    line-height: 17px;
    transform: translate(-2px, 3px) rotate(90deg);
    font-size: 18px;
  }
  &__return-text {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 17px;
    font-weight: bold;
    font-family: "Helvetica", sans-serif;
  }
  &__header-title {
    margin-bottom: 18px;
    line-height: 19px;
    padding-left: 5px;
  }
  &__header-line {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  &__close {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    font-size: 24px;
    position: absolute;
    top: -6px;
    right: -1px;
    cursor: pointer;
    outline: 0;
    color: #848484;
  }
  &__main {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    height: 100%;
  }
  &__dashboard {
    display: flex;
    align-items: center;
    margin: 0 $spacingRight 37px 0;

    & .train-trip__input--range-slider {
      margin-right: 30px !important;
    }
  }
}
