@import '../helpers';

/*
h1
h2
h3
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: rem($font-size-default);
    margin: 0;
    letter-spacing: 1.3px;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.h1 {}

.h2 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: $font-weight-heavy;
}

.h3 {
    font-size: 15px;
    font-weight: $font-weight-semibold;
}

.h4 {
    font-size: 13px;
    font-weight: $font-weight-semibold;
}

.h5 {}

.h6 {}