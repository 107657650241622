.smart-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.smart-checkbox__input {
  display: none;
}

.smart-checkbox__icon {
  flex-shrink: 0;
  display: flex;
  width: 22px;
  height: 22px;
  border: 2px solid $color-default-02;
  border-radius: 50%;
  position: relative;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  &:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //font-size: 10px;
    z-index: 1;
    font-size: 18px;
    color: #fff;
    transition: color 0.5s;
  }
}

@keyframes smart-checkbox__icon {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.smart-checkbox--is-checked .smart-checkbox__icon {
  border: 2px solid $color-default-02;
  animation: smart-checkbox__icon 0.3s forwards;
  &:before {
    content: "\E90e";
    color: $color-default-02;
  }
}

.smart-checkbox__label {

}
