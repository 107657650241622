@use "ag-grid-community/styles" as ag;

@include ag.grid-styles((
  theme: 'alpine',
  --ag-input-focus-border-color: transparent,
  --ag-input-focus-box-shadow: none,
  --ag-range-selection-border-color: transparent,
  --ag-border-color: transparent,
  --ag-control-panel-background-color: transparent,
  --ag-header-background-color: transparent,
  --ag-font-family: unisans,
  --ag-font-size: 14px,
  --ag-odd-row-background-color: #f5f5f5,
  --ag-header-foreground-color: #000000,
  --ag-row-border-color: transparent,
  --ag-row-hover-color: null,
  --ag-selected-row-background-color: null,
  --ag-checkbox-checked-color: $color-default-02
));

.ag-theme-alpine {
  .ag-cell-inline-editing {
    box-shadow: none;
    height: 51px;
  }

  .ag-filter-toolpanel-header,
  .ag-filter-toolpanel-search,
  .ag-status-bar,
  .ag-header-row,
  .ag-panel-title-bar-title,
  .ag-multi-filter-group-title-bar {
    font-weight: 500;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    border-right: 1px solid #cacaca;
  }

  .ag-header {
    border-bottom-color: #cacaca;
  }

  .ag-right-aligned-cell {
    text-align: right;
    justify-content: flex-end;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    color: #505050;
    line-height: 1.2;

    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ag-edit-button-renderer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ag-select-field {
    width: 100%;
    height: 34px;

    .react-select__placeholder {
      line-height: 32px;
    }

    .react-select__value-container {
      height: 34px;
      line-height: 32px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .ag-grid__button {
    line-height: 1.2;
  }

  .ag-selection-checkbox,
  .ag-header-select-all {
    cursor: pointer;
    margin: 2px 0;

    .ag-checkbox-input {
      width: 18px;
      height: 18px;
      border: 0;
    }

    .ag-checkbox-input-wrapper{
      width: 18px;
      height: 18px;
      border: 1px solid $color-default-02;
      border-radius: 3px;

      &:after {
        content: '';
      }

      &.ag-checked:after {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        content: "\e913";
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }
    }
  }

  .ag-row {
    cursor: pointer;
  }

  .ag-row-hover {
    .ag-cell {
      color: $color-default-02;
    }
  }
}
