.breadcrumb {
    padding: 0;
    padding-bottom: 0;
    margin-bottom: -10px !important;
    margin: 0;
}

.breadcrumb-level {
  display: inline-block;
  position: relative;
  @include rwdmax(small) {
    width: 50%;
  }
}

.breadcrumb-level--separator {
  @extend .icon;
  transform: rotate(-90deg);
  font-size: 20px;
  height: 35px;
  color: $color-default-01;

  &:before {
    content: "\e904";
  }
}

.breadcrumb-level:last-child {
  a {
    cursor: default;
  }

  .breadcrumb-level--separator {
    display: none;
  }
}

.breadcrumb-level a {
  display: inline-block;
  position: relative;
  line-height: 40px;
  padding: 0 10px 0 0;
  color: #000;
  text-decoration: none;
  z-index: 1;
}
