.dashboard-requests-to-accept {
  .section {
    position: relative;
  }

  .table-accordion-container {
    padding-bottom: 60px;
  }

  .dashboard-requests-to-accept__row {
    position: relative;
    min-height: 210px;
    margin-bottom: 13px;
    padding: 0 13px;

    .dashboard-requests-to-accept__summary {
      .basic-summary > header {
        display: none;
      }
      .dashboard-requests-to-accept__header {
        .h2 {
          text-transform: none;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .dashboard-requests-to-accept__right-col {
      padding-left: 80px;
      position: relative;

      .dashboard-requests-to-accept__user-info {
        position: relative;
        font-size: 0;
        height: 50px;
        .dashboard-requests-to-accept__user-info__name {
          position: absolute;
          left: 75px;
          top: 0;
          bottom: 0;
          right: 0;
          span {
            font-weight: 400;
            position: absolute;
            top: 50%;
            margin-top: -14px;
            font-size: 17px;
          }
        }
        .dashboard-requests-to-accept__user-info__avatar {
          max-height: 50px;
          height: 50px;
          position: relative;
          img {
            max-height: 100%;
          }
        }
      }
      .dashboard-requests-to-accept__project-info {
        display: block;
        font-weight: 400;
        margin: 10px 0;

        p {
          font-weight: 100;
          margin: 0;
        }
      }
      .dashboard-requests-to-accept__actions {
        position: relative;
        bottom: 5px;
        right: 0;

        @include rwd(small) {
          position: absolute;
          left: 80px;
          bottom: 15px;
        }

        button {
          display: inline-block;
          margin-right: 20px;
          :last-child {
            margin-right: 0;
          }
        }
      }
    }
    .dashboard-requests-to-accept__hr {
      border-bottom: 1px solid $color-grey-04;
      margin: 0 15px;
      width: 100%;
      height: 1px;
      line-height: 0;
    }

  }

  section:last-child .dashboard-requests-to-accept__hr {
    border-bottom: none;
    margin: 0;
  }

  .basic-summary__currency {
    margin-right: 8px;
  }

  .accordion__children .basic-summary__currency {
    margin-right: 0px;
  }

  .basic-summary {
    .settled-cell {
      right: -7px;
      margin-right: 0;
      position: relative;

      .summary-warnings {
        right: -25px !important;
      }
    }

    .accordion__bar .table-accordion__row-icon {
      max-height: 100%;
    }
  }

}
