/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('/images/flags.png') no-repeat;
}


.flag.flag-ad {
    background-position: -16px 0;
}

.flag.flag-ae {
    background-position: -32px 0;
}

.flag.flag-af {
    background-position: -48px 0;
}

.flag.flag-ag {
    background-position: -64px 0;
}

.flag.flag-ai {
    background-position: -80px 0;
}

.flag.flag-al {
    background-position: -96px 0;
}

.flag.flag-am {
    background-position: -112px 0;
}

.flag.flag-an {
    background-position: -128px 0;
}

.flag.flag-ao {
    background-position: -144px 0;
}

.flag.flag-ar {
    background-position: -160px 0;
}

.flag.flag-as {
    background-position: -176px 0;
}

.flag.flag-at {
    background-position: -192px 0;
}

.flag.flag-au {
    background-position: -208px 0;
}

.flag.flag-aw {
    background-position: -224px 0;
}

.flag.flag-ax {
    background-position: -240px 0;
}

.flag.flag-az {
    background-position: 0 -16px;
}

.flag.flag-ba {
    background-position: -16px -16px;
}

.flag.flag-bb {
    background-position: -32px -16px;
}

.flag.flag-bd {
    background-position: -48px -16px;
}

.flag.flag-be {
    background-position: -64px -16px;
}

.flag.flag-bf {
    background-position: -80px -16px;
}

.flag.flag-bg {
    background-position: -96px -16px;
}

.flag.flag-bh {
    background-position: -112px -16px;
}

.flag.flag-bi {
    background-position: -128px -16px;
}

.flag.flag-bj {
    background-position: -144px -16px;
}

.flag.flag-bl {
    background-position: -160px -16px;
}

.flag.flag-bm {
    background-position: -176px -16px;
}

.flag.flag-bn {
    background-position: -192px -16px;
}

.flag.flag-bo {
    background-position: -208px -16px;
}

.flag.flag-br {
    background-position: -224px -16px;
}

.flag.flag-bs {
    background-position: -240px -16px;
}

.flag.flag-bt {
    background-position: 0 -32px;
}

.flag.flag-bw {
    background-position: -16px -32px;
}

.flag.flag-by {
    background-position: -32px -32px;
}

.flag.flag-bz {
    background-position: -48px -32px;
}

.flag.flag-ca {
    background-position: -64px -32px;
}

.flag.flag-cd {
    background-position: -80px -32px;
}

.flag.flag-cf {
    background-position: -96px -32px;
}

.flag.flag-cg {
    background-position: -112px -32px;
}

.flag.flag-ch {
    background-position: -128px -32px;
}

.flag.flag-ci {
    background-position: -144px -32px;
}

.flag.flag-ck {
    background-position: -160px -32px;
}

.flag.flag-cl {
    background-position: -176px -32px;
}

.flag.flag-cm {
    background-position: -192px -32px;
}

.flag.flag-cn {
    background-position: -208px -32px;
}

.flag.flag-co {
    background-position: -224px -32px;
}

.flag.flag-cr {
    background-position: -240px -32px;
}

.flag.flag-cu {
    background-position: 0 -48px;
}

.flag.flag-cv {
    background-position: -16px -48px;
}

.flag.flag-cw {
    background-position: -32px -48px;
}

.flag.flag-cy {
    background-position: -48px -48px;
}

.flag.flag-cz {
    background-position: -64px -48px;
}

.flag.flag-de {
    background-position: -80px -48px;
}

.flag.flag-dj {
    background-position: -96px -48px;
}

.flag.flag-dk {
    background-position: -112px -48px;
}

.flag.flag-dm {
    background-position: -128px -48px;
}

.flag.flag-do {
    background-position: -144px -48px;
}

.flag.flag-dz {
    background-position: -160px -48px;
}

.flag.flag-ec {
    background-position: -176px -48px;
}

.flag.flag-ee {
    background-position: -192px -48px;
}

.flag.flag-eg {
    background-position: -208px -48px;
}

.flag.flag-eh {
    background-position: -224px -48px;
}

.flag.flag-england {
    background-position: -240px -48px;
}

.flag.flag-er {
    background-position: 0 -64px;
}

.flag.flag-es {
    background-position: -16px -64px;
}

.flag.flag-et {
    background-position: -32px -64px;
}

.flag.flag-eu {
    background-position: -48px -64px;
}

.flag.flag-fi {
    background-position: -64px -64px;
}

.flag.flag-fj {
    background-position: -80px -64px;
}

.flag.flag-fk {
    background-position: -96px -64px;
}

.flag.flag-fm {
    background-position: -112px -64px;
}

.flag.flag-fo {
    background-position: -128px -64px;
}

.flag.flag-fr {
    background-position: -144px -64px;
}

.flag.flag-ga {
    background-position: -160px -64px;
}

.flag.flag-gb {
    background-position: -176px -64px;
}

.flag.flag-gd {
    background-position: -192px -64px;
}

.flag.flag-ge {
    background-position: -208px -64px;
}

.flag.flag-gg {
    background-position: -224px -64px;
}

.flag.flag-gh {
    background-position: -240px -64px;
}

.flag.flag-gi {
    background-position: 0 -80px;
}

.flag.flag-gl {
    background-position: -16px -80px;
}

.flag.flag-gm {
    background-position: -32px -80px;
}

.flag.flag-gn {
    background-position: -48px -80px;
}

.flag.flag-gp {
    background-position: -64px -80px;
}

.flag.flag-gq {
    background-position: -80px -80px;
}

.flag.flag-gr {
    background-position: -96px -80px;
}

.flag.flag-gs {
    background-position: -112px -80px;
}

.flag.flag-gt {
    background-position: -128px -80px;
}

.flag.flag-gu {
    background-position: -144px -80px;
}

.flag.flag-gw {
    background-position: -160px -80px;
}

.flag.flag-gy {
    background-position: -176px -80px;
}

.flag.flag-hk {
    background-position: -192px -80px;
}

.flag.flag-hn {
    background-position: -208px -80px;
}

.flag.flag-hr {
    background-position: -224px -80px;
}

.flag.flag-ht {
    background-position: -240px -80px;
}

.flag.flag-hu {
    background-position: 0 -96px;
}

.flag.flag-ic {
    background-position: -16px -96px;
}

.flag.flag-id {
    background-position: -32px -96px;
}

.flag.flag-ie {
    background-position: -48px -96px;
}

.flag.flag-il {
    background-position: -64px -96px;
}

.flag.flag-im {
    background-position: -80px -96px;
}

.flag.flag-in {
    background-position: -96px -96px;
}

.flag.flag-iq {
    background-position: -112px -96px;
}

.flag.flag-ir {
    background-position: -128px -96px;
}

.flag.flag-is {
    background-position: -144px -96px;
}

.flag.flag-it {
    background-position: -160px -96px;
}

.flag.flag-je {
    background-position: -176px -96px;
}

.flag.flag-jm {
    background-position: -192px -96px;
}

.flag.flag-jo {
    background-position: -208px -96px;
}

.flag.flag-jp {
    background-position: -224px -96px;
}

.flag.flag-ke {
    background-position: -240px -96px;
}

.flag.flag-kg {
    background-position: 0 -112px;
}

.flag.flag-kh {
    background-position: -16px -112px;
}

.flag.flag-ki {
    background-position: -32px -112px;
}

.flag.flag-km {
    background-position: -48px -112px;
}

.flag.flag-kn {
    background-position: -64px -112px;
}

.flag.flag-kp {
    background-position: -80px -112px;
}

.flag.flag-kr {
    background-position: -96px -112px;
}

.flag.flag-kw {
    background-position: -112px -112px;
}

.flag.flag-ky {
    background-position: -128px -112px;
}

.flag.flag-kz {
    background-position: -144px -112px;
}

.flag.flag-la {
    background-position: -160px -112px;
}

.flag.flag-lb {
    background-position: -176px -112px;
}

.flag.flag-lc {
    background-position: -192px -112px;
}

.flag.flag-li {
    background-position: -208px -112px;
}

.flag.flag-lk {
    background-position: -224px -112px;
}

.flag.flag-lr {
    background-position: -240px -112px;
}

.flag.flag-ls {
    background-position: 0 -128px;
}

.flag.flag-lt {
    background-position: -16px -128px;
}

.flag.flag-lu {
    background-position: -32px -128px;
}

.flag.flag-lv {
    background-position: -48px -128px;
}

.flag.flag-ly {
    background-position: -64px -128px;
}

.flag.flag-ma {
    background-position: -80px -128px;
}

.flag.flag-mc {
    background-position: -96px -128px;
}

.flag.flag-md {
    background-position: -112px -128px;
}

.flag.flag-me {
    background-position: -128px -128px;
}

.flag.flag-mf {
    background-position: -144px -128px;
}

.flag.flag-mg {
    background-position: -160px -128px;
}

.flag.flag-mh {
    background-position: -176px -128px;
}

.flag.flag-mk {
    background-position: -192px -128px;
}

.flag.flag-ml {
    background-position: -208px -128px;
}

.flag.flag-mm {
    background-position: -224px -128px;
}

.flag.flag-mn {
    background-position: -240px -128px;
}

.flag.flag-mo {
    background-position: 0 -144px;
}

.flag.flag-mp {
    background-position: -16px -144px;
}

.flag.flag-mq {
    background-position: -32px -144px;
}

.flag.flag-mr {
    background-position: -48px -144px;
}

.flag.flag-ms {
    background-position: -64px -144px;
}

.flag.flag-mt {
    background-position: -80px -144px;
}

.flag.flag-mu {
    background-position: -96px -144px;
}

.flag.flag-mv {
    background-position: -112px -144px;
}

.flag.flag-mw {
    background-position: -128px -144px;
}

.flag.flag-mx {
    background-position: -144px -144px;
}

.flag.flag-my {
    background-position: -160px -144px;
}

.flag.flag-mz {
    background-position: -176px -144px;
}

.flag.flag-na {
    background-position: -192px -144px;
}

.flag.flag-nc {
    background-position: -208px -144px;
}

.flag.flag-ne {
    background-position: -224px -144px;
}

.flag.flag-nf {
    background-position: -240px -144px;
}

.flag.flag-ng {
    background-position: 0 -160px;
}

.flag.flag-ni {
    background-position: -16px -160px;
}

.flag.flag-nl {
    background-position: -32px -160px;
}

.flag.flag-no {
    background-position: -48px -160px;
}

.flag.flag-np {
    background-position: -64px -160px;
}

.flag.flag-nr {
    background-position: -80px -160px;
}

.flag.flag-nu {
    background-position: -96px -160px;
}

.flag.flag-nz {
    background-position: -112px -160px;
}

.flag.flag-om {
    background-position: -128px -160px;
}

.flag.flag-pa {
    background-position: -144px -160px;
}

.flag.flag-pe {
    background-position: -160px -160px;
}

.flag.flag-pf {
    background-position: -176px -160px;
}

.flag.flag-pg {
    background-position: -192px -160px;
}

.flag.flag-ph {
    background-position: -208px -160px;
}

.flag.flag-pk {
    background-position: -224px -160px;
}

.flag.flag-pl {
    background-position: -240px -160px;
}

.flag.flag-pn {
    background-position: 0 -176px;
}

.flag.flag-pr {
    background-position: -16px -176px;
}

.flag.flag-ps {
    background-position: -32px -176px;
}

.flag.flag-pt {
    background-position: -48px -176px;
}

.flag.flag-pw {
    background-position: -64px -176px;
}

.flag.flag-py {
    background-position: -80px -176px;
}

.flag.flag-qa {
    background-position: -96px -176px;
}

.flag.flag-re {
    background-position: -112px -176px;
}

.flag.flag-ro {
    background-position: -128px -176px;
}

.flag.flag-rs {
    background-position: -144px -176px;
}

.flag.flag-ru {
    background-position: -160px -176px;
}

.flag.flag-rw {
    background-position: -176px -176px;
}

.flag.flag-sa {
    background-position: -192px -176px;
}

.flag.flag-sb {
    background-position: -208px -176px;
}

.flag.flag-sc {
    background-position: -224px -176px;
}

.flag.flag-scotland {
    background-position: -240px -176px;
}

.flag.flag-sd {
    background-position: 0 -192px;
}

.flag.flag-se {
    background-position: -16px -192px;
}

.flag.flag-sg {
    background-position: -32px -192px;
}

.flag.flag-sh {
    background-position: -48px -192px;
}

.flag.flag-si {
    background-position: -64px -192px;
}

.flag.flag-sk {
    background-position: -80px -192px;
}

.flag.flag-sl {
    background-position: -96px -192px;
}

.flag.flag-sm {
    background-position: -112px -192px;
}

.flag.flag-sn {
    background-position: -128px -192px;
}

.flag.flag-so {
    background-position: -144px -192px;
}

.flag.flag-somaliland {
    background-position: -160px -192px;
}

.flag.flag-sr {
    background-position: -176px -192px;
}

.flag.flag-ss {
    background-position: -192px -192px;
}

.flag.flag-st {
    background-position: -208px -192px;
}

.flag.flag-sv {
    background-position: -224px -192px;
}

.flag.flag-sy {
    background-position: -240px -192px;
}

.flag.flag-sz {
    background-position: 0 -208px;
}

.flag.flag-tc {
    background-position: -16px -208px;
}

.flag.flag-td {
    background-position: -32px -208px;
}

.flag.flag-tf {
    background-position: -48px -208px;
}

.flag.flag-tg {
    background-position: -64px -208px;
}

.flag.flag-th {
    background-position: -80px -208px;
}

.flag.flag-tj {
    background-position: -96px -208px;
}

.flag.flag-tk {
    background-position: -112px -208px;
}

.flag.flag-tl {
    background-position: -128px -208px;
}

.flag.flag-tm {
    background-position: -144px -208px;
}

.flag.flag-tn {
    background-position: -160px -208px;
}

.flag.flag-to {
    background-position: -176px -208px;
}

.flag.flag-tr {
    background-position: -192px -208px;
}

.flag.flag-tt {
    background-position: -208px -208px;
}

.flag.flag-tv {
    background-position: -224px -208px;
}

.flag.flag-tw {
    background-position: -240px -208px;
}

.flag.flag-tz {
    background-position: 0 -224px;
}

.flag.flag-ua {
    background-position: -16px -224px;
}

.flag.flag-ug {
    background-position: -32px -224px;
}

.flag.flag-us {
    background-position: -48px -224px;
}

.flag.flag-uy {
    background-position: -64px -224px;
}

.flag.flag-uz {
    background-position: -80px -224px;
}

.flag.flag-va {
    background-position: -96px -224px;
}

.flag.flag-vc {
    background-position: -112px -224px;
}

.flag.flag-ve {
    background-position: -128px -224px;
}

.flag.flag-vg {
    background-position: -144px -224px;
}

.flag.flag-vi {
    background-position: -160px -224px;
}

.flag.flag-vn {
    background-position: -176px -224px;
}

.flag.flag-vu {
    background-position: -192px -224px;
}

.flag.flag-wales {
    background-position: -208px -224px;
}

.flag.flag-wf {
    background-position: -224px -224px;
}

.flag.flag-ws {
    background-position: -240px -224px;
}

.flag.flag-xk {
    background-position: 0 -240px;
}

.flag.flag-ye {
    background-position: -16px -240px;
}

.flag.flag-yt {
    background-position: -32px -240px;
}

.flag.flag-za {
    background-position: -48px -240px;
}

.flag.flag-zm {
    background-position: -64px -240px;
}

.flag.flag-zw {
    background-position: -80px -240px;
}

.flag.flag-other {
    background-position: -96px -240px;
}
