.document-cell-outer {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 250px;
  width: 100%;
  color: $text-color;
  position: relative;
  height: 285px;
}

.document-cell-outer--unset-min-height {
  min-height: unset;
}

.document-cell-inner {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  padding: 15px;
  padding-bottom: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
}

.document-cell__content-top {
  height: 150px;
  max-height: 150px;

  & > * {
    height: 100%;
  }
}

.document-cell-inner__delete-icon {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border: 1px solid #cacaca;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
}

.touch {
  .document-cell-inner__delete-icon {
    display: flex;
  }
}

.document-cell-inner.document-cell-inner--is-deletable {
  &:hover .document-cell-inner__delete-icon {
    display: flex;
  }
}

.document-cell__img-wrapper {
  width: 100%;
  height: 150px;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  .icon-document {
    font-size: 90px;
  }

  .document-cell__img-wrapper__icon {
    color: $color-default-01;
    font-size: 25px;
    text-align: right;

    .icon--inline {
      margin: 0 2px;
    }

    &.document-cell__img-wrapper__icon--down {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.document-cell__img-wrapper--position-top {
  background-position: 0 0;
  background-size: cover!important;
}

.document-cell__content--outer {
  height: 78px;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  font-size: 13px;
  margin-top: 10px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  color: $text-color;

  .document-cell__content--inner {
    width: 100%;
    height: 100%;
    position: relative;

    .document-cell__content__button {
      // and a comma...
      & > div, a {
        width: 100%;
        position: absolute;
        bottom: 15px;

        & > .btn {
          width: 100%;
          padding: 5px 10px;
        }
      }
    }
  }

  p {
    margin: 0;
  }
}

.document-cell__content-data {
  width: 85%;
  display: inline-block;
  vertical-align: top;
  position: relative;

  p, div {
    min-height: 24px;
    line-height: 24px;
    position: relative;
    max-width: 100%;
  }

  .document-cell__content-data__truncate-css {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.document-cell__content-icons {
  width: 15%;
  height: 100%;
  display: inline-block;
  position: relative;
  right: -3px;
  top: -1px;

  .document-cell__content-icons__expense-icons {
    position: absolute;
    left: 0;
    right: 0;
    top: 49px;
    width: 25px;
    display: flex;
    flex-direction: column;

    .icon--document-more {
      font-size: 16px !important;
    }
  }

  .document-cell__content-icons__bottom-icons {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 25px;
  }

  .icon {
    font-size: 22px !important;
    height: 22px;
    line-height: 22px;
    width: 25px;
    text-align: center;
    display: inline-block;
  }

  .document-status-icon-ok {
    font-size: 28px !important;
    transform: translate(-2px, 3px);
  }
}

.mileage-allowance-cell {
  .mileage-allowance-cell__img-wrapper {
    display: flex;
    align-items: center;
  }
  .document-cell__content--outer {
    .mileage-allowance-cell__lump-sum {
      bottom: 15px;
      position: absolute;
    }
  }
}

.tippy-popper {
  .icon {
    font-size: 22px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
  }
  p {
    margin: 0;
  }
}

.document-cell__img-wrapper--failure {
  background-size: contain;
}

.mileage-allowance--disabled {
  .document-cell-outer {
    pointer-events: none;
  }

  .dropzone__icon--diets .icon {
    color: lighten($color-grey-10, 10%);
    background: none;
    -webkit-background-clip: content;
    -webkit-text-fill-color: unset;
  }

  .btn {
    @extend .btn--primary__disabled;
  }

}

.document-cell__non-delegation-header {
  margin-bottom: -21px;
}

