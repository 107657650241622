// DASHBOARD

$medium: 1550px;
$large: 1790px;

.plane-trip__dashboard {
  padding-left: 15px;
  padding-right: 15px;

  .plane-trip__filter-block {
    padding: 0;
  }

  .form_group__label--empty {
    @include rwdmax(large) {
      display: none;
    }
  }

  .form-field__checkbox {
    display: flex;
    align-items: center;
  }

  .plane-trip__filter-inner-wrapper {
    flex-wrap: wrap;
    align-items: baseline;

    @include rwd(exlarge) {
      flex-wrap: nowrap;
    }

    .form-field__checkbox {
      margin-top: 5px;
    }
  }


  .plane-trip__filter {
    @include rwd(medium) {
      width: 50%;
    }

    @include rwd(large) {
      width: 33%;
    }

    @include rwd(exlarge) {
      width: auto;
    }

    &.plane-trip__filter-large {
      @include rwd(exlarge) {
        flex-grow: 1;
      }
    }
  }
}
.plane-trip__dashboard-row {
  display: flex;
  flex-wrap: wrap;
}

.plane-trip__dashboard-field {
  width: 180px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

// DIALOG
.plane-trip__dialog-dashboard {
  margin: 0 25px 37px 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plane-trip__filter-block {
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.plane-trip-dialog__main {
  .component-loader__overlay {
    z-index: 10;
  }

  .component-overlay {
    z-index: 10;
  }
}

.plane-trip__filter-wrapper {
  margin-left: -15px;
  margin-right: -15px;
  display: block;
}

.plane-trip__filter-inner-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;

  @include rwd(large) {
    margin-top: 0;
    flex-wrap: nowrap;
  }
}

.plane-trip__filter {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @include rwd(small) {
    width: 50%;
  }

  @include rwd(medium) {
    width: 33%;
  }

  @include rwd(large) {
    width: auto;
  }

  &.plane-trip__filter-large {
    @include rwd(large) {
      flex-grow: 1;
    }
  }

  .train-trip__input {
    min-width: 0;
    max-width: 100%;
  }

  .checkbox-wrapper__label {
    white-space: nowrap;
    padding-right: 0;
  }
}

.plane-trip__filter-search{
  margin-top: 36px;
  align-self: normal;

  button{
    padding-left: 30px;
    padding-right: 30px;
  }
}

.plane-trip__filter-dropdown {
  width: 185px;
}

.plane-trip__label {
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-bottom: 10px;
}

.plane-trip__offers-header {
  display: block;
  font-size: 22px;
  line-height: 27px;
  padding-left: 5px;
  margin-bottom: 10px;
  letter-spacing: 1px;

  &--not-visible {
    visibility: hidden;
    height: 0;
    margin-bottom: 0;
  }

  &--top-margin {
    margin-top: 25px;
  }
}

.plane-trip__offer {
  max-width: 1280px;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  padding: 30px 15px 22px 15px;
  margin-bottom: 20px;
  max-height: 120px;
  transition: max-height 0.3s;

  &--expanded {
    max-height: 2000px;
  }
}

.plane-trip__offer-row-description {
  display: flex;
  justify-content: flex-end;
}

.plane-trip__offer-price-info {
  max-width: 390px;
}

.plane-trip__dialog-ticket-logo {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.plane-trip__offer-details-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1.5fr repeat(6, 3fr) 168px;
  grid-column-gap: 25px;
  align-items: flex-start;

  .btn.with-arrow.btn--outline{
    align-self: center;
    width: 100% !important;
    max-width: none !important;
  }
}

.plane-trip__offer{
  .btn{
    max-width: 137px;
    margin: 0 auto;
    width: 100%;
  }
}

.plane-trip__offer-detail-tooltip-wrapper {
  display: flex;

  .plane-trip__icon-info {
    margin-left: 7px;
  }
}

.plane-trip__offer-tooltip--luggage {
  min-width: 270px;
  padding-right: 50px;
}

.plane-trip__offer-tooltip__info--luggage {
  float: right;
  margin-right: -50px;
  padding-left: 15px;
}

.plane-trip__offer-tooltip__content {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.plane-trip__icon-info {
  display: block;
  margin-left: 5px;
}

.plane-trip__offer-luggage-icon {
  font-size: 18px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.plane-trip__offer-detail--price {

}

.plane-trip__offer-detail-header {
  font-weight: 500;
  letter-spacing: 0.23px;
  height: 19px;
  line-height: 19px;
  display: block;
  margin-bottom: 5px;
}

.plane-trip__offer-detail-header--hours {
  letter-spacing: 0.26px;
}

.plane-trip__offer-detail-footer {
  display: block;
  font-size: 14px;
  letter-spacing: 0.23px;
  line-height: 17px;
  min-height: 17px;
}

.plane-trip__offer-detail-inner-wrapper {
  &:last-child {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.plane-trip__offer-detail-content {
  position: relative;
  display: inline-block;
}

.plane-trip__offer-detail-price-warning {
  font-size: 21px;
  display: block;
  position: absolute;
  top: -5px;
  right: -25px;
}

.plane-trip__offer-detail-price-value {
  display: block;
  font-size: 24px;
  line-height: 19px;
  letter-spacing: 0.29px;
  font-weight: 500;
}

.plane-trip__offer-detail-price-desc {
  opacity: 0.8;
  color: #505050;
  font-size: 14px;
  letter-spacing: 0.23px;
  line-height: 17px;
  margin-top: 4px;
  display: inline-flex;
  width: 100%;
}

.plane-trip__offer-detail-annotation {
  display: block;
  font-size: 12px;
  text-align: right;
  color: $color-grey-05;
  white-space: nowrap;
  letter-spacing: .23px;
  line-height: 12px;
}

// SINGLE OFFER
//.plane-offer {}


.plane-offer__step {
  margin-bottom: 40px;
  @include rwd($medium) {
    margin-bottom: 25px;
  }

  &:last-child {
    margin-bottom: 0;
  }

}


.plane-offer__heading {
  font-size: 22px;
  letter-spacing: 0.35px;
  line-height: 27px;
  display: block;
  margin-bottom: 8px;
}

.plane-offer__grid {
  display: grid;
  grid-template-columns: 320px 1fr;

  @include rwd(1367px) {
    grid-template-columns: 360px 1fr;
  }

  &--reservation {
    grid-template-columns: 290px 1fr;

    @include rwd($medium) {
      grid-template-columns: 300px 1fr;
    }
  }
}

.plane-offer__airlines {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 10px;
}

.plane-offer__logo {
  width: 55px;
  height: 25px;
  object-fit: contain;
  margin-right: 15px;
}

.plane-offer__hour {
  font-weight: 500;
  display: block;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.26px;
  margin-bottom: 1px;
}

.plane-offer__flight-info {
  display: flex;
  font-size: 14px;
  letter-spacing: 0.23px;
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 265px;
  line-height: 25px;

}

.plane-offer__flight-airline {
  margin-right: 18px;
}

.plane-offer__flight-class {
  position: relative;
  margin-left: -12px;
  padding-left: 12px;

  &:before {
    position: absolute;
    content: "\00b7";
    left: 0;
  }
}

.plane-offer__details-grid {
  display: grid;
  grid-template-columns: 150px 230px 175px 110px; //685
  grid-column-gap: 10px;

  @include rwd(1367px) {
    grid-template-columns: 150px 250px 175px 110px; //685
    grid-column-gap: 40px;
  }

  &--reservation {
    grid-template-columns: 140px 90px 220px;
    grid-row-gap: 25px;
    grid-column-gap: 40px;

    @include rwd($medium) {
      grid-template-columns: 140px 100px 260px;
      grid-column-gap: 30px;
    }
    @include rwd($large) {
      grid-template-columns: 140px 100px 180px 190px 90px;
    }
  }
}

.plane-offer__details-grid--paxes {
  .plane-offer__paxes {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 6;
  }

  .plane-offer__pax {
    display: grid;
    grid-template-columns: 280px 1fr 1fr;
    grid-column-gap: 40px;
  }

  .plane-offer__pax-user {
    margin-bottom: 20px;

    @include rwd($large) {
      margin-bottom: 0;
    }
  }

  .plane-offer__pax + .plane-offer__pax {
    margin-top: 20px;
  }

  .plane-offer__detail--luggage, .plane-offer__detail--service {
    grid-row: 2;
    grid-column: auto;

    @include rwd($large) {
      grid-row: auto;
    }
  }
}

.plane-offer__details-grid--single-pax {
  .plane-offer__pax {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

.plane-offer__detail {
  font-size: 14px;
  letter-spacing: 0.23px;

  .react-select__container--outer {
    width: 100%
  }

  .react-select__container--nowrap {
    .react-select__select-container {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-select__control {
      width: 100%;
    }

    .react-select__multi-value-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 35px);
    }
  }

  &--luggage {
    grid-row: 2;
    grid-column: 1 / 3;
  }

  &--service {
    grid-row: 2;
  }

  @include rwd($large) {
    &--luggage, &--service {
      grid-row: auto;
      grid-column: auto;
    }
  }
}

.plane-offer__detail-heading {
  font-weight: 500;
  display: block;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.26px;
  margin-bottom: 5px;

  sup{
    font-size: 12px;
    font-weight: 400;
    top: -1em;
  }
}

.plane-offer__detail-content {
  display: block;
  line-height: 17px;
  letter-spacing: 0.23px;
  font-size: 14px;
  min-height: 34px;
  position: relative;

  .plane-offer__luggage-icon {
    display: block;
  }
}

.plane-offer__luggage-field {
  display: flex;
  align-items: center;
  margin-top: -3px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.plane-offer__luggage-icon {
  font-size: 20px;
  margin-left: -4px;
  color: #cacaca;

  &--active {
    color: $color-default-03;
  }
}

.plane-offer__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.plane-offer__price-wrapper {
  display: flex;
  align-items: center;

  .plane-trip__offer-detail-price-warning {
    position: relative;
    top: auto;
    right: auto;
    margin-right: 10px;
  }
}

.plane-offer__warning-icon {
  font-size: 22px;
  margin-right: 5px;
}

.plane-offer__price {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.26px;
}


.plane-offer__messages {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 3px 0 27px;
}

.plane-offer__agreement-message,
.plane-offer__commission-message {
  flex-basis: 45%;
  max-width: 450px;

  p {
    margin: 0 0 6px 0;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 1px;
    color: #848484;
  }
}

.plane-offer__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.plane-offer__button {
  padding: 0 10px;
  height: 27px;
  line-height: 27px;

  &--another {
    min-width: 150px;
    margin-right: 20px;
    line-height: 24px;
  }

  &--save {
    min-width: 112px;
  }
}

.plane-offer__reservation-notice {
  width: 100%;
  @include rwd(exlarge) {
    padding: 0 10%;
  }
}

.plane-offer__reservation-status {
  display: flex;
  justify-content: flex-end;
}


.plane-trip__offer-detail {
  &--logo {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img{
      position: relative;
      z-index: 4;
      background: #fff;
    }
  }
}

.plane-offer__selected-services {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 5px;
  }
}

.plane-offer__selected-services-amount {
  font-size: 12px;
  font-weight: 500;
}

.plane-offer__agreement-message {
  margin: 0 0 20px 0;
  max-width: 450px;
}

.react-select__container--flight-services {
  margin-top: 5px;
}

.tariff-conditions-content {
  max-height: 500px;
}

.trip-dialog__title {
  display: flex;
  height: auto;
  justify-content: space-between;
}

.plane-trip__offer-action-button {
  margin-top: 10px;
}

.plane-trip__offer-expander {
  align-self: center;
  cursor: pointer;

  i {
    display: block;
  }
}

.plane-trip__return-offers {
  opacity: 0;
  transition: opacity .5s;
  padding-left: 23px;
  position: relative;
  margin-top: 25px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background: #f8f8f8;
    z-index: 3;
  }

  &--visible {
    opacity: 1;
  }

  .plane-trip__offer-details {
    margin-top: 40px;
    position: relative;

    &:after{
      content: "";
      position: absolute;
      top: 20px;
      left: -22px;
      height: 2px;
      width: 52px;
      background: #f8f8f8;
      z-index: 3;
      margin-top: -2px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child{
      position: relative;
      z-index: 6;

      &:before{
        content: "";
        position: absolute;
        left: -23px;
        bottom: 0;
        height: 38px;
        background: #fff;
        width: 2px;
      }
    }
  }
}

.plane-trip__return-offers-heading{

}

.plane-offer__flight-trip{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plane-offer__flight-trip-icon{
  width: auto;
  min-width: 20px;
  height: 1px;
  background: #cecece;
  position: relative;
  display: block;
  margin-top: 6px;
  margin-left: 3px;
  margin-right: 3px;

  &:before{
    content: "";
    position: absolute;
    height: 1px;
    top: 0;
    background: #cecece;
    width: 200px;
    margin-left: -200px;
  }

  .icon{
    position: absolute;
    top: 0;
    right: -2px;
    font-size: 25px;
    color: #505050;
    transform: translateY(-50%);
  }
}


.plane-offer__flight-trip-cities{
  display: flex;
  justify-content: space-between;
  margin-top: -7px;
  position: relative;
  overflow: hidden;
  padding-top: 7px;
}

.plane-offer__flight-trip-city{
  opacity: 0.64;
  color: #505050;
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  margin-top: 3px;
}

.plane-offer__flight-trip-cities-center{
  flex-grow: 1;
}

.plane-offer__flight-trip-airport{
  background: #fff;
  position: relative;
  z-index: 5;
  display: inline-flex;
}

.plane-trip__return-offers-heading{
  position: relative;

  &:before{
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    height: 2px;
    background: #cbcbcb;
    top: 50%;
    margin-top: -1px;
  }
}

.plane-trip__return-offers-heading-text{
  background: #fff;
  margin-left: -23px;
  padding-left: 23px;
  padding-right: 10px;
  color: #505050;
  font-family: "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.26px;
  line-height: 19px;
  position: relative;
  z-index: 2;
  display: inline-flex;
}

.plane-trip__offer--expanded > .plane-trip__offer-details {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    height: 70px;
    width: 2px;
    background: #f8f8f8;
    z-index: 3;
  }

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    height: 2px;
    width: 52px;
    background: #f8f8f8;
    z-index: 3;
    margin-top: -2px;
  }
}

.trip-dialog__list-wrapper{
  &.is-changed{
    position: relative;

    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 6;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, 0.5);
    }

    /**:not(.btn__content):not(.btn):not(.btn__progress-wrap) {
      color: #ccc !important;
    }*/

  }
}
