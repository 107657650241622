.app {
  min-height: 100vh;
  width: 100%;
  @include rwd(small) {
    display: flex;
  }
}

.app__main-nav {

}

.app__main-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.app__main-page {
  display: flex;
  flex-grow: 1;
}

.app__main {
  //flex-grow: 1;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: column;
  //width: calc(100% - 220px);
  width: 100%;
  @include rwd(small) {
    padding-left: 65px;
    flex-grow: 1;
    width: calc(100% - 500px);
  }
  @include rwd(medium) {
    padding-left: 222px;
  }
  @include rwdmax(exlarge) {
    padding-left: 190px;
  }
}

.main-nav.small + .app__main {
  padding-left: 65px;
}

.app__main-page-content {
  flex-grow: 1;
  width: 100%;
  padding: 20px 20px 80px;


  @include rwd(small) {
    padding: $default-padding $default-padding 80px;
    min-height: 550px;
  }
}

.app__main-page-aside {

}

.app {
  &.company {
    .app__main-page-content {
      padding-bottom: 0;
    }
  }
}
