.expenses {
  margin: 0 -5px;
  @include rwd(small) {
    margin: 0 -15px;
  }
}

.expenses__col {
  padding: 5px;
  @include rwd(small) {
    padding: 5px 13px;
  }
  text-align: center;
  font-weight: $font-weight-book;

  .checkbox-wrapper {
    justify-content: center;
  }
}

.expenses__col:first-of-type .checkbox-wrapper {
    width: 125px;
    justify-content: flex-start !important;
}
