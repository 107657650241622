.has-overlay {
  position: relative;
}

.component-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.9);
  z-index: 10001;
}

.component-overlay__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.component-overlay__message {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  border: 3px solid transparent;
  border-radius: 10px;
  position: relative;
  background-color: $color-default-01;
  background: $gradient-default-01;
  background-position: 100% 2px;
  background-size: 200% 200%;
  box-shadow: inset -999px 0 0 #fff; /* The background color */
}
.component-overlay__message--unsettled-lump-sums {
  width: 410px;
}

.component-overlay__message-icon {
  display: inline-block;
  margin-right: 5px;
  transform: translateY(3px);
}

.component-overlay--unsettled-lump-sums {
  z-index: 1 !important;
}
