.timeline-container {
  padding: 5px 0 0 15px;

  .tooltip-content {
    margin-bottom: 20px;
  }
}

.trip-plan--draft {
  position: relative;
}

.trip-plan--draft .timeline::before {
  content: none;
}

.trip-plan--draft .timeline__header-row {
  margin-top: 0;
}

.trip-plan--draft::before {
  content: "";
  width: 2px;
  height: calc(100% - 50px);
  background: $color-default-01;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.trip-plan--draft .trip-end {
  position: relative;
}

.trip-plan--draft .trip-end::before {
  content: "" !important;
  position: absolute;
  top: 15px;
  left: -1px;
  height: calc(100% - 39px);
  width: 2px;
  background: #fff;
}

/*.timeline-container:not(.trip-plan--documents):not(.timeline-container--other-costs)
.timeline:nth-last-child(2) {
  padding-bottom: 20px;
}*/

.timeline {
  position: relative;
  padding-left: $default-padding;
  outline: 0;

  @include rwd(small) {
    padding-left: $default-padding;
  }

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    background: $color-default-01;
    position: absolute;
    top: 15px;
    left: -1px;
  }
}

.panel {
  .timeline {
    padding-bottom: 0px;
  }

  .timeline.accordion + .timeline--add {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.timeline.accordion[class*="other-cost-form"] {
  padding-bottom: 20px;
}

.timeline.accordion + .timeline--add {
  /* padding-bottom: 10px; */
}
.timeline.accordion:last-child:before {
  height: 0 !important;
}
.timeline--interactive .accordion {
  padding-bottom: 0;
}

.timeline__btn-add {
  margin: 6px 0 4px;
  cursor: pointer;
}

.timeline--add {
  z-index: 6;
  cursor: pointer;
  height: 0;
  padding: 2px 0 2px 40px;
  margin: 0 0 0 -20px;
  display: block;
  width: 400px;
  transition: height .3s, padding .3s;

  .timeline__header-icon {
    font-size: 14px;
    opacity: 0;
    top: auto;
    transition: opacity 0.3s;
    left: -15.5px;
  }

  .timeline__header-row {
    font-size: 13px;
    opacity: 0;
    padding-left: 19px;
    transition: opacity 0.3s, padding 0.3s ease;
    color: $color-default-02;
  }

  .timeline__header {
    cursor: pointer;
    display: inline-flex;
  }
  }

.timeline--add-target-point::before {
  height: calc(100% - 5px) !important;
}

.timeline--add::before {
  transition: none !important;
  height: calc(100% - 6px);
  left: 19px;
}
.trip-plan--documents {
  .timeline {
    &:before {
      top: 25px;
      height: calc(100% + 35px);
    }
  }

  .timeline + .timeline {
    margin-top: 35px;
  }
}

.timeline--add ~ .accordion .ReactCollapse--content {
  margin-bottom: -20px;
}

.timeline--add ~ .accordion .row .ReactCollapse--content {
  margin-bottom: 0;
}

.timeline--add:hover {
  height: 40px;
  padding: 10px 0 10px 40px;

  .timeline__header-icon {
    opacity: 1;
  }

  .timeline__header-row {
    opacity: 1;
  }
}

.timeline--last.timeline:last-child {
  //cursor: pointer;
  &:before {
    content: none;
  }
}

.timeline--last.timeline .timeline__header-icon {
  z-index: 1000;
}

.timeline__header {
  position: relative;
  color: $color-grey-06;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.timeline__header-tooltip {
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  z-index: 9999;

  .tooltip-content {
    min-height: 150px;
    z-index: 9999;
  }
}

.timeline__header--padding {
  padding: 0 10px;
}

.timeline__header--title {
  padding: 5px 0;
}

.timeline__header-icon {
  top: -6px;
  //@include rwd(small) {
  //  top: auto;
  //}
  position: absolute;
  left: -31px;
  width: 23px;
  font-size: 25px;
  transform: translateX(-50%);
  display: block;
  transition: all .2s ease-in-out;

  .icon {
    color: $color-default-02;
    //text-shadow: 2px 2px 5px $color-grey-03;
  }

  &:before {
    content: " ";
    display: block;
    width: 100%;
    height: 50%;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
    background: #fff;
    position: absolute;
  }
}

.timeline-container--trip-plan {
  .timeline__header-icon {
    top: initial;
    z-index: 5;
  }

  .timeline__header-row {
    margin-top: -2px;
  }

  //&::before {
  //  display: none;
  //}
}

.trip-plan--documents {
  .timeline {
    margin-bottom: 15px;
  }
}

.timeline-container--other-costs {
  .timeline__header-row {
    margin-top: -8px;
  }

  .timeline:not([class^="other-cost-form-"]) {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline:not([class^="other-cost-form-"]) .timeline__header .timeline__header-row {
    margin-top: 2px;
  }

  [class^="other-cost-form-"]::before {
    height: 100% !important;
  }

  & .timeline__btn-add > .timeline__header {
    padding-left: 10px;
  }
}

.timeline-container--other-costs .timeline::before {
  transform: translateY(5px);
  height: calc(100% + 20px);
}

.timeline-container--border-crossings {
  position: relative;
  margin-bottom: 30px;
  padding-top: 0;
  margin-top: -4px;
}

.timeline-container--border-crossings::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  height: calc(100% - 17px);
  width: 2px;
  background: #64e0ba;
  @media screen and (max-width: 1670px) {
    height: calc(100% - 56px);
  }
}

.timeline-container--border-crossings .timeline {
  margin-bottom: 12px;
  z-index: 1;

  &::before {
    content: none;
  }
}

.timeline-container--border-crossings .timeline__header-icon {
  top: 0;
}

.timeline-container--border-crossings .border-crossings__draggable-container .timeline:not(.timeline--add) .timeline__header-icon {
  &::before {
    width: 11px;
    height: 11px;
    top: 15px;
    left: 7px;
  }
}

.timeline-container--border-crossings .timeline:not(.timeline--add) .timeline__header-icon {
  .icon-plus {
    top: 5px;
  }
}

.timeline-container--border-crossings .timeline__header-row {
  transform: translateY(2px);
  @media screen and (max-width: 1670px) {
    transform: translateY(5px);
  }
}

.timeline-container--border-crossings .timeline--add .timeline__header-row {
  padding-left: 5px;
  transform: none;
}

.timeline__header-icon--inline {
  top: auto;
}

.timeline__header-flag {
  margin-left: -5px;
  transform: translateX(-33px);
  transition: none;
}

.timeline__header-row {
  width: 100%;
  flex-grow: 1;
  align-items: center;
  position: relative;

  .icon {
    cursor: pointer;
  }

  .col {
    padding: 0;
  }
}

.timeline__content {

  &:after {
    content: " ";
    display: block;
    width: 10px;
  }

  &.focused .tooltip-content {
    z-index: 10;
  }

  .tooltip-content {
    min-height: 285px;
  }

  .form-group__input-wrapper--inline {
    justify-content: normal;
  }
}

.trip-timeline-element--active {
  .timeline__header-icon {
    transform: translateX(-50.5%) scale(1.50);
  }
}

.timeline--edge-points {

  .form-group__label {

  }

  .btn--outline {
    position: absolute;
    right: 0;
    top: 50%;
  }
}


.trip-start .timeline__header--target-point {
  margin-top: 0;
}

.trip-start.timeline.accordion .tooltip-content {
  z-index: 6;
}

.trip-end .timeline__header--target-point {
  margin-bottom: 0;
}

.trip-end.timeline.accordion .tooltip-content {
  z-index: 4;
}

.open .ReactCollapse--collapse {
  overflow: visible !important;
  height: auto !important;
}

.trip-timeline-element-head {
  font-weight: 500;
  color: $text-color;

  .sm-2.is-pull-end {
    padding-right: 0;
  }
}

.timeline__header--target-point {
  padding: 2px 10px;
  border-radius: 5px;
}

.timeline__header--target-point::before {
  content: "";
  background: $color-default-01;
  width: 2px;
  height: 15px;
  position: absolute;
  left: -31px;
  top: -4px;
}

.trip-start .timeline__header--target-point::before,
.trip-end .timeline__header--target-point::before {
  content: none;
}

.timeline__main-container {
  position: relative;
}

.timeline__loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.5);
  z-index: 999;
}

.trip-timeline-element-head--hidden {
  visibility: hidden;
}

.target-point {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__inputs {
    align-self: flex-start;
  }
}

.timeline-container--other-costs {
  .timeline__header-icon {
    font-size: 1rem;

    .icon {
      font-size: 25px;
    }

    .icon.icon--filled {
      background: $gradient-default-01;
      border-radius: 50%;
      overflow: hidden;
      color: white !important;

      width: 25px;
      height: 25px;
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
