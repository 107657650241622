.user-profile-card {
  display: flex;
  margin-top: 5px;
}

.user-profile-card__header {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  white-space: nowrap;
}

.user-profile-card__avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  box-shadow: 2px 2px 5px $color-grey-03;
  background-color: $color-grey-03;
  background-size: cover;
  background-position: center;
  margin-right: 15px;
}

.user-profile-card__email {
  text-transform: lowercase;
  word-break: break-word;
}

.user-profile-card__info {
  max-width: 100%;
  display: block;

  &.truncated {
    margin-top: -7px;
  }

  > span {
    display: block;
  }
}

.user-profile-card__content {
  max-width: calc(100% - 75px);
  flex-grow: 1;

  a {
    color: $color-grey-06;
    &:hover {
      text-decoration: underline;
    }
  }
}
