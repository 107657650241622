.autocomplete {

}

.autocomplete__input {

}

.autocomplete__list {
  position: absolute;
  z-index: 99;
  border: 1px solid $color-grey-04;
  width: 100%;

  #PlacesAutocomplete__google-logo img {
    //display: none;
    max-width: 100px;
  }

  .autocomplete-places-suggestion {
    background: #fff;
    cursor: pointer;
    padding: 10px;
    color: $color-grey-05;

    .autocomplete-places-suggestion--active, &:hover {
      background: rgb(250, 250, 250);
    }
  }
}
