@import '../helpers';

/*
table
th
tr
 */

.table-container {
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  margin: $default-padding-small 0;
}

.table {
  border-spacing: 0;
  width: 100%;
}

.table__head {
  font-weight: $font-weight-semibold;
}

.table__body {

}

.table__body-td {
  padding: 15px 20px;
  min-width: 100px;
}

.table-responsive {
  overflow-y: visible;
  overflow-x: auto;
}

.table-responsive--expenses {
  @media (min-width: 1320px) {
    overflow: visible;
  }
}

.table tr:nth-child(2n+2) {
  background-color: lighten($color-grey-03, 5%);
}

.table__head th {
  font-weight: $font-weight-semibold;
  border-top: 1px solid $color-grey-04;
  border-bottom: 1px solid $color-grey-04;
  padding: 15px 20px;
  text-align: left;
}

.table {
  display: table;
  border-collapse: separate;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
}

.tbody {
  display: table-row-group;
  vertical-align: middle;
}

.th {
  display: table-cell;
  vertical-align: inherit;
}

.table__head .th {
  font-weight: $font-weight-semibold;
  border-top: 1px solid $color-grey-04;
  border-bottom: 1px solid $color-grey-04;
  padding: 15px 20px;
  text-align: left;
}

.tr {
  display: table-row;
  vertical-align: inherit;
}

.table .tr:nth-child(2n+2) {
  background-color: lighten($color-grey-03, 5%);
}

.td {
  display: table-cell;
  vertical-align: inherit;
}

a.tr {
  color: $color-grey-05;
  text-decoration: none;
}

.tbody .tr:hover {
  color: $color-default-02;
}

// fix dates
@media only screen and (max-width: 1700px) {
  .date-cell {
    width: 11% !important;

    & + div + div {
      width: 29% !important;
    }
  }
}

.long-text-wrap{
  white-space: pre-wrap;
  word-break: break-word;
}
