.sensitive-data__fields {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .form-group {
    width: 400px;
  }
}

.mpk-split-field {
  width: 100%;

  .form-group__label {
    width: initial;
  }

  &__label {
    display: flex;
    justify-content: space-between !important;
    align-content: center;
  }

  &--split {
    color: $color-default-01;
    cursor: pointer;
    position: relative;
    top: 4px;
  }

  &__percentage {
    position: relative;
    width: 100px
  }

  &__remove {
    position: relative;
    //right: -15px;
    padding: 5px;
    cursor: pointer;
    top: 2px;

    .icon {
      color: red;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto;
    column-gap: 15px;
    row-gap: 15px;
  }

  &__percentage {
    display: flex;
    align-items: center;
  }

  &__sign {
    font-size: 16px;
    line-height: 1;
    margin-left: 10px;
    color: $color-default-01;
    padding-top: 3px;
  }
}
