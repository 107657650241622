 
.table-accordion.table-accordion--is-striped {
  .table-accordion__row:nth-child(2n+2), .table-accordion__row-link:nth-child(2n+2) {
    background-color: #f5f5f5;
  }
}
 
.table-accordion--is-striped-vertically {
  .table-accordion__row--is-data-row {
    .table-accordion__col:nth-child(2n+2) {
      background-color: $color-grey-08;
    }
  }
}

.table-accordion__body {
  .green-table {

    @media (max-width: 1700px) {
      .table-accordion__col__content {
        font-size: 12px;
      }
    }

    &.table-accordion__row.green-table--vat {
      margin: 0 -15px;
  
      .table-accordion__col {
          padding-right: 25px !important;
      }
    }


    &.table-accordion__row--is-data-row {
      border-bottom: 1px solid #dddddd;
      text-align: right;
      &:last-child {
        /* border-bottom: 0 none; */
      }
      .table-accordion__col {
        border-right: 1px solid #dddddd;
        &:last-child {
          border-right: 0 none;
        }
      }
    }

    &.table-accordion__row--is-background-gradient-row, 
    &.table-accordion__row--is-background-primary-row {
      @include rwdmax(exlarge) {
        font-size: 12px;
      }
      &.table-accordion__row--is-background-gradient-row, 
      &.table-accordion__row--is-background-primary-row {
        background: -webkit-gradient(linear, left top, right top, from(#64e0ba), to(#2dcee3));
        background: linear-gradient(90deg, #64e0ba, #2dcee3);
      }
      &.table-accordion__row--is-background-primary-row {
        .table-accordion__col {
          background: $color-default-01;
          text-transform: none;
        }
      }
   
      .table-accordion__col {
        color: #fff;
        text-transform: uppercase;
        word-wrap: break-word;
        /* word-break: break-all; */
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        border-top: 1px solid white;
        border-right: 1px solid white;
        text-align: center;
   
        &.table-accordion__col--is-connected-to-upper {
          background: transparent;
          border-top: 0 none;
        }
   
        .table-accordion__col__content {
          display: inline-block;
          vertical-align: middle;
        }
      }
   
    }

    .table-accordion__col {
      &.table-accordion__col--is-connected-to-left {
        display: none !important;
      }

      @include rwdmax(exlarge) {
        padding: 10px 5px !important;
      }
    }

    &:not(.table-accordion__row--is-data-row) {
      .table-accordion__col:nth-child(2n) .table-accordion__col__content,
      .table-accordion__col:nth-child(3n) .table-accordion__col__content {
        font-weight: 400;
      }
    }

    &.table-accordion__row--is-data-row
    .table-accordion__col__content {
      font-weight: 300 !important;
      @include rwdmax(exlarge) {
        font-size: 13px !important;
      }
    }
    .table-accordion__col--is-summary {
      font-weight: bold;
      @include rwdmax(exlarge) {
        padding: 10px 5px !important;
      }
      .table-accordion__col__content {
        font-weight: 500 !important;
      }
    }
  }
}

.table-accordion__head {
  .table-accordion__row.green-table--vat {
    margin: 0 -11px;

    .table-accordion__col {
        padding-left: 25px !important;
    }
  }
}

@media (max-width: 1599px) {
  .expense-accounting .lg-6 {
    width: 100%;
  }
}
