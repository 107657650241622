.form-group {
  margin: 5px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: $color-grey-06;
  width: 100%;
  @include rwd(small) {
    flex-wrap: nowrap;
  }
  min-height: 31px;

  &.xs-4 {
    width: 33.33333% !important;
  }
  &.xs-5 {
    width: 41.66667% !important;
  }
  &.xs-6 {
    width: 50% !important;
  }
  @include rwd(exlarge) {
    &.lg-5  {
      width: 41.66667% !important;
    }
  }
}

.form-group--no-label {
  padding-top: 40px;
}

.form-group--hidden {
  margin: 0;
  padding: 0;
  min-height: 0;
}

.form-group--label-top {
  flex-wrap: wrap;

  .form-group__label {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    font-size: 12px;
    margin-bottom: 5px;
    color: $color-grey-05;
    height: 16px;
  }

  .react-select-container__placeholder {
    /* padding: 0; */
  }
}

.form-group--inverse
.form-group__label {
    text-align: left;
    justify-content: flex-start;
    width: 100%;
    align-self: center;
    margin: 0 15px;
}

.form-group--vertical {
  flex-wrap: wrap;
  margin: 35px 0;

  .form-group__wrapper {
    min-width: 65%;
    margin: 10px auto;
  }
  .input--submit {
    margin: 35px auto 10px;
  }
}

.form-group--btn {
  @extend .button-group;
}

.form-group__error {
  width: 100%;
  text-align: right;
  color: $color-primary-01;
  p {
    margin: 0;
  }
}

.form-group__error-small {
  font-size: 12px;
}

.form-group__error-left-align {
  text-align: left;
}

.form-group__label {
  margin-right: $default-padding;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  @include rwd(small) {
    width: 120px;
    text-align: right;
    justify-content: flex-end;
    align-self: flex-start;
    margin-top: 6px
  }
}

.form-group__label--start {
  justify-content: flex-start;
}

.form-group__label-inner {
  align-self: flex-start;
  margin-top: 6px !important;
}

.form-group--inline-checkbox {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.form-group--inline-checkbox > div {
  display: flex;
  width: 50%;
}

.form-group--inline .form-group__label {
  width: auto;
  @include rwd(small) {
    width: 120px;
  }
}

.form-group--inline {
  .input-toggle {
    align-items: baseline;
  }
}

.form-group--inline .radio-wrapper {
  float: left;
}

.form-group--inline-checkbox-disabled {
  display: none;
}

.form-group__label--no-margin {
  margin-top: 0;
}

.form-group__input-wrapper {
  align-self: center;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: 31px;
  position: relative;
}

.form-group__input-wrapper--no-margin {
  p {
    margin: 5px 0;
  }
}

.form-group__input-wrapper--inline {
  display: flex;
  justify-content: space-between;

  &.form-group__input-wrapper--select {
    align-self: flex-start;
  }
}

.form-group__input-wrapper--thin {
  justify-content: normal;

  .radio-wrapper + .radio-wrapper {
    margin-left: 30px;
  }
}

.form-group__input-wrapper--align-right {
  .input {
    text-align: right;
  }
}

.form-group__checkbox-wrapper {
  display: flex;
  align-items: center;
}

.form-group__checkbox-wrapper-label {
  padding: 0 10px;
}

.form-group__input-wrapper + .form-group__input-wrapper {
  margin-left: 10px;
}

.form-group__input-wrapper + span {
  margin: 0 10px;
}

.input-suggestion {
  font-size: 0.9em;

  a {
    cursor: pointer;
    font-weight: bolder;
    left: 25px;
    box-shadow: 0 0 0 transparent;
    border: 0;
    padding: 0;
    min-width: auto;
    color: #64e0ba;
    cursor: pointer;
    display: table;
    margin-right: 10px;
    background: $gradient-default-01;
        background-clip: border-box;
    background-clip: border-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    &:hover {
      text-decoration: underline;
    }
  }
}

.comments__label {
  font-size: 12px;
}

.lump-sum {
  margin-bottom: -10px;
  margin-top: 10px;
}
