.tippy-popper {
	.tippy-tooltip {
		background: #fff !important;
		padding: 15px !important;
		box-shadow: 0 0 15px $color-grey-03;

		&.error-theme {
			border: 2px solid $color-primary-01;
			border-radius: 10px;
		}
	}
	.tippy-tooltip-content {
		color: #505050;
		font-size: 13px;
	}
	&[x-placement^=top] [x-arrow] {
		border-top-color: #fff;
	}
	&[x-placement^=right] [x-arrow] {
		border-right-color: #fff;
	}
	&[x-placement^=bottom] [x-arrow] {
		border-bottom-color: #fff;
	}
	&[x-placement^=left] [x-arrow] {
		border-left-color: #fff;
	}

	.tippy-tooltip [x-circle] {
		background: #fff;
		display: none;
	}

	& * {
		text-align: left;
	}

	.tooltip-with-icon {
		padding-left: 25px;
		position: relative;

		.icon {
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

[data-tooltipped] + div:empty {
	display: none;
}