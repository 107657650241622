.upload {
  margin-bottom: $default-padding;
}

.upload__dropzone {
  position: relative;
}

.upload__file {

}

.upload--inline {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .upload__dropzone {
    margin: 0 8px 15px 8px;
    width: calc(100% - 16px);
    min-width: 110px;
    @include rwd(small) {
      width: calc(33% - 16px);
    }
    @include rwd(medium) {
      width: calc(22% - 16px);
    }
    @include rwd(exlarge) {
      width: calc(16% - 16px);
    }
  }
  .upload__file {
    width: calc(100% - 16px);
    margin: 0 8px 15px 8px;
    min-width: 110px;
    @include rwd(small) {
      width: calc(33% - 16px);
    }
    @include rwd(medium) {
      width: calc(22% - 16px);
    }
    @include rwd(exlarge) {
      width: calc(16% - 16px);
    }
  }
}
