.train-autosuggest-wrapper {
    .input--error {
        background: white;
        border-color: $color-primary-01 !important;
    }

    .react-autosuggest__suggestions-container {
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        margin-top: 0;
        border-width: 1px;
        border-radius: 2px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: 6;
        &--open {
            box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
        }
    }
    .react-autosuggest__section-title {
        background: #f3f3f3;
        padding: 5px 10px;
        font-weight: 400;
        color: #333;
        font-size: 14px;
        text-transform: none;
        cursor: default;
        margin: 0;
    }

    .react-autosuggest__suggestions-list {
        border: 0;
    }

    .react-autosuggest__suggestion {
        margin: 0;
        background-color: transparent;
        display: block;
        font-size: inherit;
        padding: 8px 12px 8px 20px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        box-sizing: border-box;
        cursor: pointer;
        color: #505050;
        &:hover, &:focus {
            background: #f2f9fc;
        }
    }

    .autosuggest__suggestion {
        display: flex;
        flex-direction: column;
        &-name {
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
