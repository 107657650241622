.dropzone {
  border: 1px solid $color-grey-04;
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: border 0.5s, background-color 0.5s, box-shadow 0.5s;
  background-color: transparent;;
  &:hover {
    border: 1px solid $color-default-03;
    box-shadow: 0 0 15px $color-grey-03;
  }
}

.dropzone.focus {
  background-color: $color-grey-03;
  box-shadow: 0 0 15px $color-grey-03;
}

.dropzone__header {
  text-align: center;
  font-size: 16px;
  color: $text-color;
  display: block;
  margin-bottom: 20px;
}

.dropzone__icon {
  font-size: 40px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-grow: 1;
}

.dropzone__icon--diets {
  display: block;
  font-size: 88px !important;
  height: 106px;
  text-align: center;
  justify-content: unset;
  align-content: unset;
  align-items: initial;
  flex-grow: unset;
}

.tooltip-content .upload__dropzone .icon-document_add {
    font-size: 3em;
}

.dropzone__title {
  font-weight: $font-weight-semibold;
  display: flex;
  justify-content: center;
}

.dropzone__subtitle {
  display: block;
  @include rwdmax(small) {
    font-size: 13px;
  }
}

.dropzone__progress {
  width: 100%;
  height: 4px;
  background-color: $color-grey-03;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  margin-bottom: 10px;
  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 100%;
    background-color: $color-default-03;
    left: 0;
  }
}

.dropzone__progress.start {
  opacity: 1;
  &:before {
    width: 80%;
    transition: width 30s cubic-bezier(0.1, 0.1, 0.5, 1);
  }
}

.dropzone__progress.end {
  opacity: 0;
  transition: opacity 1s ease-in;
  &:before {
    width: 100%;
    transition: width 0.5s ease-in-out, opacity 1s ease-in;
  }
}
