// HOTELS DIALOG
.hotels-dialog {
  max-width: 1355px;
  width: 100%;
  position: relative;
  height: calc(100% - 96px);

  &--list {
    // modal structure ...
    & > div {
      height: 100%;
    }

    & > div > div {
      //height: 100%;
    }

    @media screen and (max-width: 1366px) and (max-height: 768px) {
      height: 100%;
      max-width: none;
    }

    @media screen and (min-height: 900px) {
      height: calc(100% - 100px);
    }
  }

  &__action {
    margin-top: 31px;
    display: flex;
    justify-content: flex-end;
  }

  &__main {
    display: flex;
    flex-grow: 1;
  }

  &__sidebar {
    width: 220px;
  }

  &__offers {
    flex: 1;
    padding-left: 20px;
    position: relative;
  }

  &__map-notification {
    position: absolute;
    left: 50%;
    top: 10px;
    padding: 8px 15px;
    border-radius: 8px;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    line-height: 1;
  }
}

.hotels-dialog__wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.hotels-dialog__header-line {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.hotels-dialog__heading {
  margin-top: 10px;
  margin-bottom: 18px;
  line-height: 19px;
  font-size: 15px;
}

.hotels-dialog__filters {
  margin-bottom: 10px;
}

.hotels-dialog__checkboxes {
  gap: 5px;
}

.hotels-dialog__form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &--stars {
    margin-bottom: 10px;
  }
}

.accommodation__input-container--stars {

}

.hotels-dialog__close-btn {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  font-size: 24px;
  position: absolute;
  top: -6px;
  right: -1px;
  cursor: pointer;
  outline: 0;

  & .icon {
    color: #848484;
  }
}

.hotels-dialog__navigation {
  display: flex;
  flex-direction: column;

  & .border-crossings__toggler {
    margin-bottom: 0;
    margin-right: 30px;
  }

  & .border-crossings__toggler-label {
    width: 70px;
  }

  &-item {

    label {
      margin: 6px 0;
    }

    .form-group {
      margin: 0;
      &__label{
        margin-right: 0;
      }
    }
  }
}

.hotels-dialog__navigation-filters {
  display: flex;
  flex-direction: column;
}

.hotels-dialog__checkboxes-wrapper {
  margin-right: 20px;
  & .checkbox-wrapper {
    margin-right: 20px;
  }
}

.hotels-dialog__input-filter-wrapper {
  min-width: 200px;
}

.hotels-dialog__input-filter-label {
  width: 100%;

  &--relative {
    position: relative
  }
}

.hotels-dialog__input-filter {
  height: 31px;
  font-size: 13px;
  width: 100%;
}

.hotels-dialog__input-filter-close {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 7px;

  &:hover {
    cursor: pointer;
  }
}

.hotels-dialog__hotels-list {
  margin: 0;
  padding: 5px 25px 5px 0;
  list-style-type: none;
  outline: 0;
}

.notice-loader--hotels-search {
  padding-right: 150px;
}

.hotels-dialog__hotels-item {
  display: flex;
  flex: 1 1 0;
  height: calc(100% - 20px);
  width: calc(100% - 10px);
  margin: -10px auto 0;
  transform: translateY(20px);
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .07);
  margin-left: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.hotels-dialog__hotel-image {
  max-width: 213px;
  min-width: 213px;
  height: 100%;
  object-fit: cover;

  background-image: url(/images/no-images.png);
  background-size: cover;
  background-position: center;
}

.hotels-dialog__hotel-content-wrapper {
  padding: 15px 25px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.hotels-dialog__hotel-content {
  .selected-hotel__price-amount-wrapper {
    margin-bottom: 5px;
  }
}

.hotels-dialog__hotel-details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.hotels-dialog__hotel-name-container {
  display: flex;
  align-items: center;
}

.hotels-dialog__hotel-stars-container {
  margin-left: 35px;
  display: block;

  & .icon {
    font-size: 20px;
  }
}

.hotels-dialog__hotel-name-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hotels-dialog__hotel-corporate-icon {
  display: flex;
  margin-left: 10px;

  .dollar-icon {
    width: 20px;
  }
}

.hotels-dialog__price-container {
  transform: translateY(15px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.hotels-dialog__price {
  margin-top: 15px;
}

.hotels-dialog__facilities-container {
  height: 40px;
  display: block;
}

.hotels-dialog__facilities-text {
  font-size: 14px;
  color: #505050;
  letter-spacing: 1px;
  line-height: 17px;
}

.hotels-dialog__additional-info-text {
  font-size: 14px;
  color: #848484;
  letter-spacing: 1px;
  line-height: 17px;
}

.hotels-dialog__hotel-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

// HOTEL DIALOG
.hotel-dialog {
  max-width: 1355px;
  width: 100%;
}

.hotel-dialog__nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -10px;
}

.hotel-dialog__return-btn {
  display: block;
}

.hotel-dialog__return-icon {
  display: inline-block;
  transform-origin: center center;
  line-height: 17px;
  transform: translate(-2px, 3px) rotate(90deg);
  font-size: 18px;
}

.hotel-dialog__return-text {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
}

.hotel-dialog__close {
  font-size: 24px;
  color: #848484;
  cursor: pointer;
}

.hotel-dialog__details {
  margin-bottom: 0 !important;
}

.hotel-dialog__offers-title {
  display: block;
  margin: 15px 0;
  letter-spacing: 1px;
  line-height: 19px;
}

.hotel-dialog__offers {
  margin: -10px 0 -20px;
}

.hotel-dialog__offers-slider {
  position: relative;
  width: calc(100% + 20px);
  padding: 10px 15px;
  margin-left: -16px;
  overflow: hidden;
  box-shadow: inset 0 0 15px 0 white;
  min-height: 200px;

  .slick-list {
    position: relative;
    overflow: visible;
    margin: 0 -5px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% + 30px);
      width: 15px;
    }

    &::before {
      left: -12px;
      background: transparent linear-gradient(to right, white 40%, transparent 100%);
    }

    &::after {
      right: -14px;
      background: transparent linear-gradient(to left, white 40%, transparent 100%);
    }
  }

  .slick-track {
    margin-left: 0;
    display: flex;
    flex-wrap: nowrap;
  }

  .slick-slide {
    margin: 0 5px;
  }
}

.hotel-dialog__offers-slider-btn {
  z-index: 1;
  position: absolute;
  top: 50%;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .25);
  border: 0;
  border-radius: 50%;
  display: block;
  width: 38px;
  height: 38px;
  cursor: pointer;
  outline: 0;

  .icon {
    color: #505050;
    font-size: 20px;
  }


  &--prev {
    left: -12px;
    transform: translateY(-50%) rotate(90deg);
  }

  &--next {
    right: -12px;
    transform: translateY(-50%) rotate(-90deg);
  }
}

.hotel-dialog__offer-wrapper {
  outline: 0;
}

.hotel-dialog__offer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  padding: 10px 10px 20px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .15);
  outline: 0;
}

.hotel-dialog__offer-detail {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 17px;

  &:last-child {
    margin-bottom: 0;
  }
}

.hotel-dialog__offer-detail-icon {
  .icon {
    color: #848484;
    font-size: 14px;
    display: inline-block;
    margin-right: 2px;
  }

  .svg {
    width: 17px;
    position: relative;
    top: -1px;
    margin-left: 5px;
  }

  &--cancellable .icon {
    color: #1bc668;
  }

  &--non-cancellable .icon {
    color: #ffc009;
  }

  .icon-restaurant {
    font-size: 17px;
  }
}

.hotel-dialog__offer-detail-text {
  letter-spacing: .22px;
  font-size: 14px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotel-dialog__offer-price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: .29px;
  font-weight: 500;
  margin-top: 10px;
}

.hotel-dialog__offer-price-container {
  text-align: center;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hotel-dialog__offer-price-per-night {
  display: block;
  letter-spacing: .19px;
  font-size: 12px;
  height: 14px;
  line-height: 14px;
}

.hotel-dialog__offer-select-btn {
  padding: 0 10px;
  min-width: 100px;
  height: 27px;
  line-height: 27px;
  margin: 0 auto;
}

.hotels-dialog__no-results {
  span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

.reservation-box {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 100%;
  text-align: center;
}

.reservation-box__content {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  padding: 15px;
  width: 195px;
  height: 253px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reservation-box__hotel-name {
  display: block;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #505050;
  margin-bottom: 12px;
}

.reservation-box__link {
  color: #2dcee3 !important;
  margin-left: 0 !important;
  margin-bottom: 32px;
}

.reservation-box__status {
  & .selected-offer__status {
    display: block;
    width: 100%;

    &::before {
      width: 35px;
      height: 35px;
      position: static;
      display: block;
      top: auto;
      left: auto;
      transform: none;
      margin: 0 auto 32px;
    }
  }

  & .selected-offer__status-message {
    display: block !important;
    line-height: 1;
  }
}

// BOOKED HOTEL

.booked-hotel__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  @media screen and (min-width: 1600px) {
    grid-template-columns: 520px 1fr;
  }
}

.booked-hotel__column {
  display: flex;
  flex-direction: column;
}

.booked-hotel__column-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.booked-hotel__selected-offer-heading {
  display: block;
  margin-bottom: 10px;
}

.booked-hotel__gallery {
  width: 100%;
  height: 100%;
}

.booked-hotel__image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.booked-hotel__gallery-btn {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 38px;
  height: 100%;
  border: 0;
  cursor: pointer;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(26, 26, 26, .7) 100%);
  outline: none;

  &::before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    content: "\e904";
    color: #fff;
    position: absolute;
    transform-origin: center;
    top: 50%;
    left: calc(50% - 3px);
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
    transform-origin: center;
    transform: rotate(180deg);
  }
}

.booked-hotel__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.booked-hotel__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
}

.booked-hotel__name {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.35px;
  line-height: 27px;

  & span {
    display: block;
    margin-right: 18px;
  }

  .svg {
    width: 20px;
    position: relative;
    top: -1px;
    margin-left: 10px;
  }

  .booked-hotel__name-stars {
    position: relative;

  }

  .booked-hotel__name-icons {
    display: flex;
    align-items: center;
  }
}

.booked-hotel__address {
  display: flex;
  align-items: center;
  color: #505050;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
  margin-bottom: 5px;
  margin-left: -2px;

  &::before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1em;
    content: "\e97c";
    color: $color-google;
    margin-right: 1px;
  }
}

.booked-hotel__tripadvisor {
  display: block;
  margin-left: -4px;
}

.booked-hotel__status {
  line-height: 17px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 4px;

  &::before {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &--success {
    color: #64e0ba;

    &::before {
      background-image: url('../../img/success.svg');
    }
  }

  &--error {
    color: #f32043;

    &::before {
      background-image: url('../../img/error.svg');
    }
  }

  &--warning {
    color: $color-warning;

    &::before {
      background-image: url('../../img/warning.svg');
    }
  }
}

.booked-hotel__offer-wrapper {
  margin-bottom: 10px;
}

.booked-hotel__offer-icons .icon {
  font-size: 20px;
  color: #848484;
}

.booked-hotel__room-icon-wrapper {
  display: flex;
  align-items: center;
}

.booked-hotel__room-icon {
  margin-right: 2px;

  &--cancellable .icon {
    color: #1bc668;
  }

  &--non-cancellable .icon {
    color: #ffc009;
  }
}

.booked-hotel__room-icon-text {
  display: block;
  font-size: 14px;
  letter-spacing: 0.23px;
  line-height: 17px;
}

.booked-hotel__cancel-info {
  display: block;
}

.booked-offer__details {
  margin-bottom: 24px;
}

.booked-offer__detail {
  padding-right: 20px;
}

.booked-offer__detail-title {
  margin-bottom: 0;
  letter-spacing: 1px;
}

.booked-offer__detail-copy {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
  text-align: justify;
  margin: 0 0 10px 0;
}

.booked-hotel__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;
}

.booked-hotel__price {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.26px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.booked-hotel__price-per-night {
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
}

.booked-hotel__messages{
  grid-column-start: 2;
}

.booked-hotel__agreement-message{
  margin: 10px 0;
}

.booked-hotel__commission-message {
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  color: #848484;
  letter-spacing: 1px;
  text-align: right;

  &--fixed-height {
    height: 25px;
  }

}

.booked-hotel__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-row: 2;
  grid-column: span 2;
}

.booked-hotel__button {
  padding: 0 10px;
  height: 27px;
  line-height: 27px;

  &:last-child {
    margin-right: 0;
  }

  &--another {
    min-width: 150px;
    line-height: 24px;
  }

  &--save {
    min-width: 112px;
    margin-left: 20px;
  }
}

.booking-hotel__reservation-notice{
  width: 100%;
  margin-top: 25px;
  @include rwd(exlarge) {
    padding: 0 10%;
  }
}

.booking-hotel__loader-wrapper {
  display: flex;
  align-items: center;
}

.booking-hotel__reservation-message {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.26px;
  text-align: right;

  &--long {
    width: 100%;
    order: -1;
    margin: -8px 0 10px;
  }

  .icon {
    font-size: 20px;
    margin-right: 3px;
    line-height: 1;
    top: 4px;
  }
}

.booking-hotel__loader {
  position: static;
  top: 0;
  left: 0;
  transform: rotate(0);
  margin-left: 5px;
  animation: hotel-loader-spin $loader-speed linear infinite;
}

// DIALOG
.hotels-dialog {
  .booked-hotel__row {
    grid-template-columns: 520px 1fr;
  }

  .booked-hotel__footer {
    height: auto;
  }
}

// KEYFRAMES
@keyframes hotel-loader-spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hotels-dialog__loader-wrapper {
  height: 27px;
}

.hotels-dialog__sort {
  margin-right: 30px;
  width: 200px;
}

.hotels-dialog__sorting{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.hotels-dialog__offers-counter {
  display: inline-flex;
  font-size: 12px;
  margin-left: 20px;
  span {
    font-weight: 500;
  }
}

.selected-hotel__breakfast-filter {
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
}

.selected-hotel__additional-options {
  display: flex;
}

.selected-hotel__additional-options-remarks {
  width: 100%;
}

.selected-hotel__breakfast-filter-counter {
  span {
    font-weight: 500;
  }
}

.hotel-dialog__no-offers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
