.my-cards {
  padding-bottom: 40px;

  &__statements-footer {
    margin-top: 20px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  .table-accordion__col.is-last {
    color: #FF2243;
  }

  &__account-payments-table {
    position: relative;
    min-height: 400px;
  }
}

.statement-status {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 5px;
  }

  .icon-warning {
    color: #FFC107;
  }

  .icon-checked_fill-square {
    color: #28a745;
    width: 24px;
  }
}
