.editable-label {
  .editable-label__label {
    border: 0px solid #cacaca;
    border-radius: 4px;
    padding: 5px 0;
    width: 100%;
    color: #505050;
    display: flex;
    position: relative;
    height: 31px;

    .editable-label__value {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }

    .editable-label__icon {
      font-size: 20px;
      cursor: pointer;
      margin-top: -7px;
    }
  }
}
