%react-select-option-wrap {
  white-space: normal;
  word-wrap: break-word;
}

// DIALOG
.train-trip__dialog {
  max-width: 1355px;
  width: 100%;


  & > div {
    height: 100%;
  }

  & > div > div {
    height: 100%;
  }

  @media only screen and (max-height: 768px) {
    height: 100%;
  }
}

.train-trip__dialog-nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  margin-bottom: 10px;
}

.train-trip__dialog-return-btn {
  display: block;
}

.train-trip__dialog-return-icon {
  display: inline-block;
  transform-origin: center center;
  line-height: 17px;
  transform: translate(-2px, 3px) rotate(90deg);
  font-size: 18px;
}

.train-trip__dialog-return-text {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
}

.train-trip__dialog-close {
  font-size: 24px;
  color: #848484;
  cursor: pointer;
}

.train-trip__dialog-main {
  padding-left: 5px;
  height: calc(100% - 50px);
  overflow: hidden;
}

.train-trip__dialog-trip-title {
  display: block;
  letter-spacing: .23px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-left: 5px;
}

.train-trip__dialog-trip-details {
  display: block;
  line-height: 17px;
  letter-spacing: 1px;
  margin: 0 0 7px 5px;
}

.train-trip__dialog-dashboard {
  display: flex;
  align-items: center;
  margin: 0 0 10px 5px;

  & .train-trip__input--range-slider {
    margin-right: 30px !important;
  }
}

// DIALOG - TICKET
.train-trip__dialog-list-wrapper {
  height: calc(100% - 100px);
}

.train-trip__dialog-ticket-list {
  list-style-type: none;
  margin: 0;
  padding: 5px 25px 5px 5px;
}

.train-trip__dialog-ticket {
  display: flex;
  align-items: center;
  width: 100%;
  height: 99px;
  background-color: #fff;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .07);
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.train-trip__dialog-ticket-logo {
  width: 40px;
  height: 28px;
  object-fit: contain;
  margin-right: 22px;
}

.train-trip__dialog-ticket-details {
  width: 273px;
}

.train-trip__dialog-ticket-departure-hours {
  display: block;
  font-weight: 500;
  color: #505050;
  font-size: 14px;
  letter-spacing: .26px;
  line-height: 23px;
}

.train-trip__dialog-ticket-provider {
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 17px;
}

.train-trip__dialog-ticket-travel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.train-trip__dialog-ticket-travel-detail {
  margin-right: 139px;

  &:last-child {
    margin-right: 0;
  }
}

.train-trip__dialog-ticket-travel-details-title {
  display: block;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 23px;
}

.train-trip__dialog-ticket-travel-details-value {
  display: block;
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 17px;
}

//.train-trip__dialog-ticket-availability {}

.train-trip__dialog-ticket-availability-heading {
  display: block;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 23px;
}

.train-trip__dialog-ticket-availability-badge {
  display: table;
  padding: 0 7px;
  margin: 0;
  height: 14px;
  border-radius: 7px;
  color: #505050;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-weight: 500;

  &--high {
    background-color: #73e5ac;
  }

  &--limited {
    background-color: #ffd800;
  }

  &--no-place {
    background-color: #eb2e38;
  }

  &--no-guarantee {
    background-color: #ff9600;
  }

  &--no-info {
    background-color: #d4cbbc;
  }

}

.train-trip__dialog-ticket-select {
  margin-left: auto;
  display: flex;
  align-items: center;

  .icon-warning{
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 19px;
  }
}

.train-trip__dialog-ticket-price {
  display: block;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: .26px;
  line-height: 19px;
  text-align: right;
  color: #505050;
  margin-right: 25px;
}

.train-trip__dialog-select-tooltip-text {
  font-size: 12px;
  color: #000;
}

.train-trip__dialog-select-button {
  height: 27px;
  line-height: 27px;
  min-width: 100px;
  padding: 0 10px;
}

.train-trip__dialog-select-button--disabled {
  background-color: #ececec;
  color: #fff;
}

// SELECTED TICKET
//.train-trip__selected-ticket {}

.train-trip__selected-ticket-title {
  color: #505050;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;
}

.train-trip__selected-ticket-title-cities {
  font-weight: 500;
}

.train-trip__selected-ticket-details-container {
  display: grid;
  grid-template-columns: 225px 1fr;
}

.train-trip__selected-ticket-intro {
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.train-trip__selected-ticket-logo {
  height: 28px;
  width: 40px;
  object-fit: contain;
  margin-right: 20px;
}

.train-trip__selected-ticket-details {
  width: 170px;

  @media screen and (min-width: 1560px) {
    width: 273px;
  }
}

.train-trip__selected-ticket-departure-hours {
  display: block;
  font-weight: 500;
  color: #505050;
  font-size: 16px;
  letter-spacing: .26px;
  line-height: 19px;
}

.train-trip__selected-ticket-provider {
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 17px;
}

.train-trip__selected-ticket-travel-details {
  display: grid;
  grid-template-columns: 85px 115px 165px 165px;
  grid-column-gap: 60px;
  margin-bottom: 10px;
}

.train-trip__selected-ticket-row + .train-trip__selected-ticket-row {
  margin-top: 10px;
}

.train-trip__selected-ticket-travel-detail-title {
  display: block;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 17px;
  margin-bottom: 4px;
}

.train-trip__selected-ticket-travel-detail-value {
  display: block;
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 17px;
}

.train-trip__selected-ticket-status {
  margin-left: 122px;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.train-trip__selected-ticket-status--error {
  color: #f32043;

  &::before {
    background-image: url('../../img/error.svg');
    background-size: contain;
  }
}

.train-trip__selected-ticket-status--warning {
  color: #ff672b;

  &::before {
    background-image: url('../../img/warning.svg');
    background-size: contain;
  }
}

.train-trip__selected-ticket-save-details {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.train-trip__selected-ticket-messages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.train-trip__selected-ticket-price {
  display: block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .26px;
  line-height: 19px;
  text-align: right;
  color: #505050;
}

.train-trip__selected-ticket-agreement-message,
.train-trip__selected-buttons {
  flex-basis: 40%;
  margin: 5px 0 24px;
}

.train-trip__selected-ticket-message {
  display: block;
  text-align: right;
  color: #848484;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 15px;
  margin-bottom: 10px;
}

.train-trip__selected-ticket-message + .train-trip__selected-ticket-message {
  margin-top: -10px;
}

.train-trip__selected-ticket-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

.train-trip__selected-change-button {
  min-width: 157px;
  height: 27px;
  line-height: 24px;
  padding: 0 10px;
  display: inline-block;
}

.train-trip__selected-save-button {
  min-width: 110px;
  height: 27px;
  line-height: 27px;
  padding: 0 10px;
  margin-left: 20px;
}

// SELECTED TICKET - RESERVATION
.train-trip__selected-ticket--reservation {
  .train-trip__selected-ticket-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .train-trip__selected-ticket-title {
    margin-bottom: 0;
  }

  .train-trip__selected-ticket-reservation-status {
    line-height: 17px;
    display: flex;
    align-items: center;
    font-weight: 500;

    &::before {
      content: "";
      display: block;
      width: 21px;
      height: 21px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  .train-trip__selected-ticket-reservation-status--success {
    color: #64e0ba;

    &::before {
      background-image: url('../../img/success.svg');
    }
  }

  .train-trip__selected-ticket-reservation-status--error {
    color: #f32043;

    &::before {
      background-image: url('../../img/error.svg');
    }
  }

  .train-trip__selected-ticket-reservation-status--warning {
    color: $color-warning;

    &::before {
      background-image: url('../../img/warning.svg');
    }
  }

  .train-trip__selected-ticket-details {
    @media screen and (min-width: 1560px) {
      width: 170px;
    }
  }

  // RWD
  .train-trip__selected-ticket-details-container {
    display: block;

    @media screen and (min-width: 1593px) {
      display: grid;
      grid-template-columns: 225px 1fr;
    }
  }

  .train-trip__selected-ticket-travel-details {
    display: grid;
    grid-template-columns: 85px 115px 165px 180px;
    margin-bottom: 10px;
    grid-column-gap: 30px;
    margin-left: 62px;

    @media screen and (min-width: 1593px) {
      grid-column-gap: 60px;
      margin-left: 0;
    }
  }

  .train-trip__selected-ticket-intro {
    margin-bottom: 20px;

    @media screen and (min-width: 1593px) {
      margin-bottom: 10px;
    }
  }

  // PLACE DETAILS
  .train-trip__selected-ticket-place-details {
    width: 167px;
    align-self: flex-start;
    flex: 1;
    flex-basis: auto;

    @media screen and (min-width: 1560px) {
      flex: 0;
      flex-basis: 210px;
    }
  }

  .train-trip__selected-ticket-place-prefered-select {
    width: 100%;

    &--short {
      max-width: 100px;
    }

    .react-select__single-value {
      max-width: calc(100% - 10px);
    }

    .react-select__option {
      @extend %react-select-option-wrap;
    }
  }

  .train-trip__selected-ticket-place-type-select {
    width: 100%;
    max-width: 200px;

    .react-select__option {
      @extend %react-select-option-wrap;
    }
  }

  // RESERVATION
  .train-trip__selected-ticket-place-reservation {
    @media screen and (max-width: 1366px) {
      display: none;
    }
  }

  .train-trip__selected-ticket-place-reservation-field {
    display: inline-block;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  .train-trip__selected-ticket-place-reservation-title {
    display: block;
    line-height: 14px;
    margin-top: -1px;
    margin-bottom: 4px;
    color: #505050;
    font-size: 12px;
    position: relative;
    padding-top: 4px;

    .icon {
      font-size: 20px;
      position: absolute;
      bottom: 1px;
      left: 12px;
    }
  }

  .train-trip__selected-ticket-place-reservation-wagon-field {
    width: 40px;

    & input {
      text-align: center;
    }
  }

  .train-trip__selected-ticket-place-reservation-place-field {
    width: 40px;

    & input {
      text-align: center;
    }
  }
}

.train-trip__selected-ticket-place-reservation-notice {
  width: 100%;
  @include rwd(exlarge) {
    padding: 0 10%;
  }
}


// OTHER
.train-trip__inputs {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  flex-wrap: wrap;
  @media screen and (min-width: 1465px) {
    flex-wrap: nowrap;
  }
}

.train-trip__inputs--requested-amount {
  justify-content: space-between;
  margin-bottom: 0;
}

.train-trip__resign-button {
  padding: 0 10px;
  min-width: 216px;
  height: 27px;
  line-height: 24px;
  margin-right: 20px;
}

.train-trip__search-button {
  padding: 0 10px;
  min-width: 112px;
  height: 27px;
  line-height: 27px;
}

.train-trip__departure-inputs {
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media screen and (min-width: 1465px) {
    margin-top: 0;
  }
}

.train-trip__input {
  max-width: 180px;
  min-width: 180px;
  width: 100%;
  margin-right: 30px;

  .form-group__error {
    text-align: left;
    position: absolute;
  }

  &:last-child {
    margin-right: 0;
  }
}

.train-trip__input--requested-amount {
  margin-right: 10px;
  width: 100px;
}

.train-trip__input-station {
  max-width: 200px;
  min-width: 200px;
}

.train-trip__checkbox {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.train-trip__input .border-crossings__toggler {
  margin-bottom: 0;
}

// range slider
.train-trip__input--range-slider {
  display: flex;
  align-items: center;
  max-width: 340px;
  min-width: 340px;
  width: 100%;
}

.train-trip__range-slider-data {
  display: block;
  margin-right: 15px;
  //max-width: 30px;
  min-width: 30px;
  text-align: center;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
    margin-left: 15px;
  }
}

.train-trip__range-input {
  height: 20px;
  position: relative;
  flex-grow: 1;
}

.train-trip__range-slider-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -9px;
}

.train-trip__range-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0 rgba(1, 1, 0, .11);
  outline: none;
}

.train-trip__range-track {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
}

.train-trip__range-track--active {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $gradient-default-01;
  border-radius: 4px;
}

.train-trip__range-label {
  display: none;
}

// DEPARTURES
.train-trip__departures-list {
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.departure {
  display: flex;
  margin-right: 20px;
  max-width: 253px;
  min-width: 253px;
  width: 100%;
  height: 310px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #cbcbcb, 1px 1px 15px 0 rgba(0, 0, 0, .07);
}

.departure--active {
  box-shadow: inset 0 0 0 2px #2ecee3, 1px 1px 15px 0 rgba(45, 206, 227, .3);
}

.departure--active .departure__aside-details {
  box-shadow: inset 0 0 0 2px #2ecee3;
}

.departure__aside-details {
  max-width: 28px;
  min-width: 28px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 0 0 1px #cbcbcb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.departure__aside-details-logo {
  max-height: 37px;
  min-width: 25px;
  width: 100%;
  height: 100%;
  margin-top: 17px;
  object-fit: contain;
}

.departure__aside-details-logo--rotated {
  transform-origin: center;
  transform: rotate(-90deg);
}

.departure__aside-details-icons {
  text-align: center;

  & > .icon {
    display: block;
    color: #505050;
    font-size: 20px;
  }
}

.departure__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100% - 28px);
  min-width: calc(100% - 28px);
  width: 100%;
  padding: 12px 15px 10px 10px;
}

.departure__destinations {
  position: relative;
  margin: 0;
  padding: 0 0 0 34px;
  list-style-type: none;
  font-size: 14px;
  letter-spacing: .23px;
}

.departure__destination {
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  // GREY LINE
  &:first-child {
    .departure__destination-date-details {
      & .icon {
        &::after {
          content: "";
          width: 1px;
          background-color: transparent;
          border-right: 1px solid #d0d0d0;
          height: 62px;
          position: absolute;
          top: calc(100% - 5px);
          left: calc(50% - 1px);
          transform: translateX(-50%);
        }
      }
    }
  }
}

.departure__destination-name {
  display: block;
  font-weight: 500;
  height: 19px;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.departure__destination-date-details {
  position: relative;

  & > .icon {
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: -32px;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.departure__destination-date {
  display: block;
}

.departure__destination-time {
  display: block;
}

.departure__toggler-container {
  display: flex;
  align-items: center;
  font-size: 14px;

  @media screen and (max-width: 1366px) {
    margin-top: 20px;
  }

}

.departure__toggler-input {
  display: block;
  margin: 0 10px;
}

.departure__price {
  display: block;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
}

.departure__duration-container {
  display: flex;
  align-items: center;
  transform: translateY(3px);

  & > .icon {
    color: #505050;
    font-size: 20px;
    margin-right: 3px;
  }
}

.departure__more-details {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

// REQUESTED AMOUNT
.train-trip__requested-amount-wrapper {
  display: flex;
  align-items: flex-end;
}

.train-trip__requested-amount-currency {
  transform: translateY(-11px);
}

.train-trip__empty-offers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.train-trip__departures-container {
  height: 295px;
  display: flex;
  justify-content: center;
  align-items: center;
}


// SELECTED OFFER
.selected-offer {
  padding: 10px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .07);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-offer--open {
  padding: 0;
  border: 0;
  height: 200px;

  .selected-offer__arrow-more {
    position: absolute;
    top: 10px;
    right: 11px;
    transform: rotate(180deg);
  }

  & .selected-offer__status {
    margin-top: 10px;
  }

  .selected-offer__amount {
    line-height: 1;
    margin-top: 8px;
  }
}

.selected-offer__title {
  padding: 0;
  margin: 0;
  letter-spacing: 1px;
  max-width: 800px;
  padding-right: 31px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected-offer__title-intro {
  display: none;
  @media screen and (min-width: 1465px) {
    display: inline-block;
  }
}

.selected-offer__title-details {
  display: inline-block;
  margin-left: 4px;
  font-weight: 500;
}

.selected-offer__status {
  font-weight: 500;
  position: relative;
  margin-right: 0;
  top: -1px;

  &::before {
    content: "";
    width: 21px;
    height: 21px;
    position: absolute;
    top: 50%;
    left: -31px;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
  }

  @media screen and (min-width: 1465px) {
    margin-right: 60px;
  }
}

.selected-offer__status--success {
  color: #64e0ba;

  &::before {
    background-image: url('../../img/success.svg');
    background-size: cover;
  }
}

.selected-offer__status--error {
  color: #f32043;

  &::before {
    background-image: url('../../img/error.svg');
    background-size: cover;
  }
}

.selected-offer__status--warning {
  color: $color-warning;

  &::before {
    background-image: url('../../img/warning.svg');
    background-size: contain;
  }
}

.selected-offer__status-message {
  display: none;
  @media screen and (min-width: 1465px) {
    display: inline-block;
  }
}

.selected-offer__amount {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: .29px;
  margin-right: 23px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.selected-offer__arrow-more {
  cursor: pointer;
  font-size: 16px;
}

.selected-offer__main--destination-container {
  width: 260px;
}

.selected-offer__main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 7px 20px;
  border: 1px solid #cbcbcb;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
}

.selected-offer__main-heading {
  display: block;
  letter-spacing: 1px;
  margin-bottom: 8px;
}

.selected-offer__destinations {
  padding: 0 0 0 31px;

  & .departure__destination-date-details {
    & .icon {
      &::after {
        top: calc(100% - 5px) !important;
        height: 60px !important;
      }
    }
  }
}

.selected-offer__details {
  margin: 32px 0 0 40px;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0px;
  grid-column-gap: 90px;
  @media screen and (min-width: 1465px) {
    margin: 32px 0 0 0;
    grid-row-gap: 30px;
  }
}

.selected-offer__detail-type {
  display: block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 4px;
}

.selected-offer__detail-value {
  display: block;
  line-height: 1;
}

.selected-offer__options {
  margin: 20px 30px 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .selected-offer__amount {
    margin-right: 0;
  }

  .selected-offer__status {
    margin-right: 0;
  }

  .selected-offer__status-message {
    display: block !important;
  }
}

.selected-offer__info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.selected-offer__icon-warning {
  font-size: 24px;
  margin-right: 6px;
}

.selected-offer__options-message {
  color: #848484;
  text-align: right;
  line-height: 17px;
  margin: 0;
  white-space: nowrap;

  &--long {
    white-space: initial;
    max-width: 500px;
  }

}

.selected-offer__options-message--margin {
  margin: 15px 0;
}

.selected-offer__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  & .btn {
    margin-right: 30px;
    min-width: 177px;
    padding: 0 10px;
    height: 36px;
    line-height: 34px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1465px) {
    display: block;
    & .btn {
      margin-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.train-trip__inputs--filters {
  align-items: center;
}


.train-trip__chose-button {
  margin: 0 auto;
  padding: 0 10px;
  min-width: 88px;
  height: 39px;
  line-height: 39px;
}

.train-trip__save-btn {
  max-width: 88px;
  min-width: 88px;
  width: 100%;
  height: 39px;
  line-height: 39px;
  padding: 0;
  margin-left: auto;
}

.train-trip__inputs--buttons {
  justify-content: flex-end;
}


.train-trip__dialog-no-results {
  span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

//.train-trip__dialog-fit {
//  height: 500px !important;
//  margin-bottom: 30px;
//  @media screen and (max-height: 768px) {
//    height: 70vh !important;
//  }
//}

.train-trip__selected-ticket-place-reservation-fields {
  display: flex;
  align-items: flex-start;
}

.train-trip__reservation-message {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.26px;
  text-align: right;
}

.train-trip__seat-validation {
  .form-group__error {
    display: block;
    text-align: left;
  }
}

.train-trip__reservation-message {
  font-size: 12px;
  letter-spacing: 0.26px;
  text-align: right;
  display: block;

  &--long {
    margin: -10px 0 10px 0;
    display: block;
    width: 100%;
  }

  .icon {
    font-size: 20px;
    margin-right: 3px;
    line-height: 1;
    top: 4px;
  }
}

.train-trip__loader-wrapper {
  display: flex;
  align-items: center;
}

.train-trip__loader {
  position: static;
  top: 0;
  left: 0;
  transform: rotate(0);
  margin-left: 5px;
  animation: hotel-loader-spin $loader-speed linear infinite;
}

.train-trip__travel-time-warning {
  text-align: right;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__message {
    font-weight: 200;
    font-size: 12px;
  }

  .icon {
    font-size: 23px;
    margin-right: 6px;
  }
}

.train-trip__range--disabled {

  .train-trip__range-track--active {
    background: $color-grey-10;
  }

}
