@import '../helpers';

.accordion-panel {
  margin-bottom: 30px;
  position: relative;  
  box-shadow: $default-shadow;
}

.accordion-panel__bar {
  padding: 10px 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.04);
  position: relative;
  background: #fff;
  z-index: 1;

  &.accordion-panel__bar--is-clickable {
    cursor: pointer;
  }

  h1 {
    text-transform: uppercase;
    color: $color-default-01;
  }
}

.accordion-panel.accordion-panel--theme-primary {
  .accordion-panel__bar {
    border-left: 3px solid #64E0BA;
  }
  h1 {
    color: #64E0BA;
    letter-spacing: 1px;
  }
}

.accordion-panel.accordion-panel--theme-info {
  .accordion-panel__bar {
    border-left: 3px solid #4FD9DE;
  }
  h1 {
    color: #4FD9DE;
    letter-spacing: 1px;
  }
}

.accordion-panel--theme-info--second {
  .accordion-panel__bar {
    border-left: 3px solid #00B5B8;
  }
  h1 {
    color: #00B5B8;
    letter-spacing: 1px;
  }
}

.accordion-panel__content {
  padding: 10px;
  position: relative;
  min-height: 250px;
  margin-top: -10px;
}

.accordion-panel__content--no-shadow {
  box-shadow: 0 0 0;
}

.accordion-panel__icon {
  position: absolute;
  font-size: 25px;
  right: 20px;
  top: 50%;
  margin-top: -15px;
  cursor: pointer;
  height: 31px;
  line-height: 31px;
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
  &.accordion-panel__icon--close {
    transform: rotate(180deg);
  }
}
