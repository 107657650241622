.counter-field {
  display: flex;
  align-items: center;

  &__icon {
    font-size: 18px;
    cursor: pointer;
  }

  &__value {
    min-width: 40px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}
