.request-mileage-allowance-page__add-button-wrapper {
  margin: 30px 0;
  padding: 15px;

  .request-mileage-allowance-page__add-button {
    margin: 0 auto;
  }
}

.request-mileage-allowance-summary__country {
  display: flex;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
}
.request-mileage-allowance-summary__country-name {
  margin-right: 3px;
}
.request-mileage-allowance-summary__country-currency {
  font-size: 11px;
}

.request-mileage-allowance-page__lump-sum {
  font-weight: 500;
}

.request-mileage-allowance-page__lump-sum-date {
  margin: 0 auto;

}
.request-mileage-allowance-page__tooltip {
  display: inline-block;
  display: -moz-inline-box;
  display: -webkit-inline-box;
}

.mileage-allowance-page {
  .delegation--buttons {
    margin-bottom: 30px;
  }

  &--top {
    display: flex;
    justify-content: space-between;
  }

  &--header {

  }

  &--buttons {

  }
}

