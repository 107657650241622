.request-summary-warnings {
  color: $color-grey-05;
  .request-summary-warnings__warning-header {
    line-height: 25px;
    margin-bottom: 0;
    .request-summary-warnings__warning-icon {
      font-size: 25px;
      margin-right: 15px;
    }
    * {
      vertical-align: middle;
    }
  }
  ul {
    list-style-type: none;
    margin-top: 0;
    li {

    }
  }
}
