.provider-select-option {
  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__details {
    font-size: 12px;
  }

  &__detail {
    display: inline-block;
  }

  &__detail + &__detail {
    margin-left: 10px;
  }
}