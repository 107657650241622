@keyframes main-page-load {
  0% {
    opacity: 1;
  }
  98% {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  100% {
    height: 0;
    width: 0;
  }
}

.main-page {
  position: relative;
  min-height: calc(100% - 111px);

  &:before {
    width: 100%;
    height: 100%;
    background: $color-grey-02;
    //content: " ";
    position: absolute;
    top: 0;
    left: 0;
    // z-index: 3;
    transition: 0.8s;
  }
}

.main-page.load:before {
  animation: main-page-load 1s forwards;
}
