@keyframes main-page-aside-swipe {
  from {
    position: static;
    height: auto;
  }
  to {position: fixed;
    height: 100%;
  }
}

.main-page-aside {
  padding: $default-padding;
  background-color: $color-grey-02;
  border-left: 1px solid $color-grey-04;
  margin-right: -240px;
  width: 240px;
  right: 0;
  transition: margin-right 1s;
  animation-name: main-page-aside-swipe;
  animation-duration: 2s;
  position: fixed;
  flex-shrink: 0;
  height: 100%;
  @include rwd(large) {
    animation: none;
    margin-right: 0;
    position: static;
    height: auto;
  }

  p {
    font-size: 13px;
  }
}