.offer-map-image {
  &__container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__image,
  &__placeholder {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in;
  }

  &__image--hidden {
    opacity: 0;
    visibility: hidden;
    height: 0;
    position: absolute;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
