.icon-select {
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 15px;
  margin-top: 10px;
  .icon {
    font-size: 28px;
    margin-right: 10px;
    cursor: pointer;
  }

  .icon-bus {
    font-size: 24px;
  }

  .timeline-icon {
    transition: transform 0.2s ease-in-out;
    transform-origin: center;
    &:hover {
      transform: translateX(2px) scale(1.5);
    }
  }
}

.icon-select--open {
  .timeline-icon:hover {
    transform: none;
  }
}
