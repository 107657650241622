.expense-type-box {
  width: 50%;
  padding: 0 4px;
  cursor: pointer;
  margin-bottom: 15px;
  opacity: 0.5;
  display: flex;
  flex-flow: column;
  @include rwd(small) {
    width: 50%;
  }
  @include rwd(medium) {
    width: 25%;
  }
  @include rwd(large) {
    width: 20%;
  }
}

.expense-type-box--is-active {
  opacity: 1;
}

.expense-type-box--has-content {
  opacity: 1;
}

.expense-type-box__icon {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 30px;
}

.expense-type-box__title {
  width: 100%;
  display: block;
  text-align: center;
  margin: 10px 0;
  line-height: 17px;
  flex-grow: 1;
}

.expense-type-box__content {
  text-align: center;
  display: block;
}

.expense-types-tab--read-only {

  .expense-type-box {
    @extend .expense-type-box--is-active;
    cursor: default;
  }



}
