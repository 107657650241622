.react-autosuggest__suggestions-container {
  position: absolute;
  z-index: 99;
  background: $color-grey-02;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid $color-grey-04;
}

.react-autosuggest__suggestion {
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background: $color-grey-03-semi-transparent;
}
