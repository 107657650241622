.avatar {
	border-radius: 50%;
	width: 50px;
	background-color: $color-grey-03;
	background-size: cover;
	background-position: center;
	height: 50px;
	flex-shrink: 0;
	box-shadow: 5px 5px 8px $color-grey-03;
}

img.user-avatar {
	border-radius: 50%;

	&:not(&--default) {
		object-fit: cover;
	}
}
div.user-avatar {
	border-radius: 50%;

	&:not(&--default) {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
}
