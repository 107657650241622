.notification-dropdown {
  position: relative;

  &__show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: $color-default-01;
    cursor: pointer;
    border-top:  1px solid $color-grey-03;

    &:hover {
      background-color: #eeeeee;
    }
  }
}

.notification-dropdown.active {
  z-index: 99;
}

.notification-dropdown.active .notification-dropdown__list {
  display: block;
}

.notification-dropdown__icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.notification-dropdown__icon-label {
  position: absolute;
  bottom: -5px;
  right: -5px;
  color: $color-grey-02;
  border-radius: 50%;
  display: flex;
  width: 18px;
  height: 18px;
  background: #ff748a $gradient-danger-01;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.notification-dropdown__list {
  position: absolute;
  background-color: $color-grey-02;
  margin: 0;
  list-style: none;
  width: 100%;
  padding: 0;
  margin-top: 10px;
  right: 5px;
  display: none;
  min-width: 280px;
  box-shadow: 0px 0px 15px $color-grey-03;
  z-index: -1;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: $color-grey-02;
    transform: rotate(45deg);
    top: -7px;
    right: 5px;
    box-shadow: 0 0 15px $color-grey-03;
    z-index: -1;
  }
  &:after {
    display: block;
    top: 0px;
    right: 0;
    content: '';
    width: 35px;
    height: 20px;
    position: absolute;
  }
}

.notification-dropdown__list-element {
  border-bottom: 1px solid $color-grey-03;
}

.notification-dropdown__list li:last-child .notification-dropdown__list-element {
  border-bottom: 0;
}
