.charge-cards__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.charge-cards__title {
  letter-spacing: 0.26px;
  font-size: 17px;
  text-transform: uppercase;
  line-height: 22px;
}

.charge-cards__horizontal {
  display: flex;
}

.charge-cards__add-button {
  position: relative;
  min-width: 129px;
  width: auto;
  box-sizing: border-box;
  text-align: center;
  height: 31px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  line-height: 1;
}

.charge-cards__header {
  .section__header-title {
    margin-top: -18px;
  }
}

.charge-cards__header_form {
  display: flex;
  align-items: flex-end;
}

.charge-cards__inline_form {
  min-width: 200px;
  margin-right: 30px;

  .filters__input-title {
    display: inline-block;
    margin-bottom: 5px;
  }

  .input--text {
    height: 34px;
  }
}

.charge-cards__form_wrapper {

}

.charge-cards__table {
  border-collapse: collapse;
  margin-bottom: 20px;
}

// CHARGE CARD
.charge-card {
  display: flex;
  align-items: center;
  background: #fff;
  color: #505050;
  height: 58px;

  &:nth-child(odd) {
    background: #f5f5f5;
  }
}

.charge-card__column {
  word-wrap: break-word;
  position: relative;
  padding: 10px 20px;

  &:nth-child(1) {
    min-width: 205px;
  }

  &:nth-child(2) {
   width: 100%;
  }

  &:nth-child(3) {
    min-width: 110px;
  }

  &:nth-child(4) {
    min-width: 112px;
  }

  &--no-results{
    min-width: 100% !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.charge-cards__table--withCompany{
  .charge-card__column{
    &:nth-child(1) {
      min-width: 195px;
    }

    &:nth-child(2) {
      min-width: 130px;
      width: auto;
    }

    &:nth-child(3) {
      width: 100%;
    }

    &:nth-child(4) {
      min-width: 110px;
    }

    &:nth-child(5) {
      min-width: 112px;
    }
  }
}

.charge-card__column--operations {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.charge-card__default-info {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: $color-default-01;
  font-weight: 500;
  width: 161px;
  margin-right: 20px;
}

.charge-card__operation-button {
  min-width: 161px;
  width: auto;
  padding: 0 10px;
  height: 27px;
  line-height: 27px;
  margin-right: 20px;
}
.charge-card__operation-button--delete {
  min-width: 71px;
  line-height: 24px;
  margin-right: 0;
}

// CHARGE CARDS FOOTER
.charge-cards__footer {
  display: flex;
  align-items: center;
}

.charge-cards__certificate-img {
  margin-right: 10px;

  @media screen and (min-width: 1367px) {
    margin-right: 20px;
  }
}

.charge-cards__clause {
  color: $color-grey-06;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
}

.charge-cards__charge-card--pending {
  opacity: .5;
  pointer-events: none;
}

.charge-cards__table-header {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-basis: 100%;
  color: #000000;
  font-weight: 500;
  border-bottom: 1px solid #cacaca;
}

.charge-cards__table-heading {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.26px;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}
