.dashboard-current-trips {

  .accordion-panel__content {
    padding: 10px 22px 22px;

    .dashboard-current-trips__amount {
      padding-right: 4px;
      font-weight: 500;
      white-space: nowrap;
      @include rwd(exlarge) {
        padding-right: 89px;
      }
    }
  }

  .dashboard-current-trips__row {
    margin-top: 13px;
    min-height: 340px;
  }


  .dashboard-current-trips__costs-types {
    font-size: 25px;

    * {
      display: inline-block;
      margin-right: 7px;
      color: $color-grey-05;
    }

    .empty {
      font-size: 15px;
    }
  }

  .dashboard-current-trips__hr {
    border-bottom: 1px solid $color-grey-04;
    margin: 0 15px;
    width: 100%;
    height: 1px;
    line-height: 0;
  }
  .dashboard-current-trips__show-button {
    position: absolute;
    right: 30px;
    top: 30px;
    @include rwdmax(exlarge) {
      & .btn {
        padding: 10px 15px;
      }
    }
  }

  .dashboard-current-trips__trip-info {
    text-align: right;
    line-height: 25px;

    .dashboard-current-trips__icon {
      color: $color-warning;
      font-size: 25px;
      vertical-align: middle;
      margin-right: 11px;
    }

    span {
      vertical-align: middle;
    }
  }

  .accordion-panel__content > div {
    margin-top: 30px;

    & > div:last-child .dashboard-current-trips__hr {
      border-bottom: none;
      margin: 0;
    }
  }

  .h2 {
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .4px;
  }
}
