.is-color-grey {
  color: $color-grey-04;
}

.is-icon-grey {
  color: $color-grey-07 !important;
}

.is-icon-grey-light {
  color: #ccc !important;
}

.is-google-red {
  color: $color-google !important;
}

.is-orange {
  color: $color-orange;
}

.is-color-warning {
  color: $color-warning;
}

.is-gradient-warning {
  background: $gradient-warning-01;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
}
.is-gradient-warning:hover {
  background-position: 0 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.is-color-info {
  color: $color-info;
}

.is-gradient-info {
  background: $gradient-info-01;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
}
.is-gradient-info:hover {
  background-position: 0 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.is-color-success {
  color: $color-success;
}
.is-gradient-success {
  background: $gradient-default-01;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
}

.is-gradient-success:hover {
  background-position: 0 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon.icon--filled {

}

.is-color-danger {
  color: $color-danger;
}

.is-gradient-danger {
  background: $gradient-danger-01;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
}
.is-gradient-danger:hover {
  background-position: 0 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*
Temp class
 */
.is-todo {
  color: #50ff3d !important;
}
