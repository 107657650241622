.lump-sum-section__summary-row {
  padding-right: 10px;

  .row {
    position: relative;
  }
}

.lump-sum-section__summary-row-labels {
  font-weight: 500;
  color: $color-grey-06;
}

.lump-sum-section__summary-row-values {

}

.lump-sum-section__access-lump-sum-warning {
  display: block;
  padding: 5px;
  min-height: 1rem;
}

.lump-sum-section__access-lump-sum-item {
  display: flow-root!important;
}
