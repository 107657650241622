@charset 'UTF-8';

@import './0_settings/variables';

@import './1_tools/_functions';
@import './1_tools/_mixins';
@import './1_tools/_rwd';

@import './2_generic/_normalize';
@import './2_generic/_fonts';

@import './3_elements/_body';
@import './3_elements/_headings';
@import './3_elements/_links';
@import './3_elements/_lists';
@import './3_elements/_paragraphs';
@import './3_elements/_tables';
@import './3_elements/_typography';


@import './4_objects/_grid';
@import './4_objects/_layout';

/*
Components
 */
@import './5_components/layouts/_main-header';
@import './5_components/layouts/_default-layout';
@import './5_components/layouts/_main-page';
@import './5_components/layouts/_main-page-aside';
@import './5_components/ui/_avatar';
@import './5_components/ui/_section';
@import './5_components/ui/_button';
@import './5_components/ui/_button-group';
@import './5_components/ui/_icon';
@import './5_components/ui/_simple-loader';
@import './5_components/ui/_input-group';
@import './5_components/ui/_input';
@import './5_components/ui/_form-group';
@import './5_components/ui/_react-select-container';
@import './5_components/ui/_react-select';
@import './5_components/ui/_dropzone';
@import './5_components/ui/_file';
@import './5_components/ui/_input-check';
@import './5_components/ui/_input-toggle';
@import './5_components/ui/_accordion';
@import './5_components/ui/_react-datepicker';
@import './5_components/ui/_table-accordion';
@import './5_components/ui/_person-add';
@import './5_components/ui/_input-wrapper';
@import './5_components/ui/_autocomplete';
@import './5_components/ui/_border-col';
@import './5_components/ui/_stars';
@import './5_components/ui/_input-square';
@import './5_components/ui/_advance-icon';
@import './5_components/ui/_timeline-icon';
@import './5_components/ui/_page-close';
@import './5_components/ui/_anchor';
@import './5_components/ui/_green-table';
@import './5_components/ui/_help-dialog';
@import './5_components/ui/_dropdown-field';
@import './5_components/ui/_traveler-select';
@import './5_components/ui/_room-field';
@import './5_components/ui/_counter';
@import './5_components/_table-actions';
@import './5_components/_form-error';
@import './5_components/_company-settings';
@import './5_components/_tabs';
@import './5_components/_sticky-container';
@import './5_components/timeline/timeline';
@import './5_components/timeline/icon-select';
@import './5_components/timeline/tooltip-content';
@import './5_components/expenses/_expense-type';
@import './5_components/expenses/_expense-type-box';
@import './5_components/request-unassigned-documents';
@import './5_components/_expense-request-actions';
@import './5_components/_provider-select-option';
@import './5_components/_form';
@import './5_components/_main-nav';
@import './5_components/_path';
@import './5_components/_profile-dropdown';
@import './5_components/_header-search';
@import './5_components/_breadcrumbs';
@import './5_components/_upload';
@import './5_components/_notification-dropdown';
@import './5_components/_comment';
@import './5_components/_filters';
@import './5_components/_main-page-loader';
@import './5_components/_notice-loader';
@import './5_components/_notification-element';
@import './5_components/_expenses';
@import './5_components/_alert';
@import './5_components/_document-preview';
@import './5_components/_summary';
@import './5_components/_state-text-button';
@import './5_components/_loading-overlay';
@import './5_components/_exchange-summary';
@import './5_components/_document-cell';
@import './5_components/_panel';
@import './5_components/_account-document-page';
@import './5_components/_pagination';
@import './5_components/ui/_smart-checkbox';
@import './5_components/_user-profile-card';
@import './5_components/_accordion_panel';
@import './5_components/_label';
@import './5_components/_dashboard-requests-to-accept';
@import './5_components/_dashboard-current-trips';
@import './5_components/_upload-cell';
@import './5_components/_dashboard-requests-documents';
@import './5_components/_dashboard-waiting-to-settlement';
@import './5_components/_login';
@import './5_components/_radio';
@import './5_components/_checkbox';
@import './5_components/_react-autosuggest';
@import './5_components/_accommodation-suggestions';
@import './5_components/_editable-label';
@import './5_components/_request-summary-warnings';
@import './5_components/_request-mileage-allowance-page';
@import './5_components/_financial-dashboard';
@import './5_components/_toast';
@import './5_components/_notification-bar';
@import './5_components/_travel-point';
@import './5_components/_tippy';
@import './5_components/_dashboard';
@import './5_components/_vat-summary';
@import './5_components/_lang';
@import './5_components/_lump-sum';
@import './5_components/_advance-payment';
@import './5_components/border-crossings/_border-crossings';
@import './5_components/border-crossings/_declarations';
@import './5_components/_document-assign';
@import './5_components/_component-overlay';
@import './5_components/_trip-plan-documents';
@import './5_components/_reports';
@import './5_components/_last-requests';
@import './5_components/_settings-page';
@import './5_components/_accommodation';
@import './5_components/_train-trip';
@import './5_components/_hotels';
@import './5_components/_selected-hotel';
@import './5_components/_user-profile';
@import './5_components/_sensitive-data';
@import './5_components/_plane-trip';
@import './5_components/_rent-car-trip';
@import './5_components/_charge-cards';
@import './5_components/_substitutions';
@import './5_components/ui/_autosuggest';
@import './5_components/_document-page';
@import './5_components/_trip-dialog';
@import './5_components/_reservation-status';
@import './5_components/_agreement-message';
@import './5_components/_company-hierarchy';
@import './5_components/_delegation-switcher';
@import './5_components/_user-groups';
@import './5_components/_transactions-table';
@import './5_components/_request-traveler';
@import './5_components/_accept-by-token';
@import './5_components/_trip-request-header-summary';
@import './5_components/_request-collapsable-section';
@import './5_components/_sticky';
@import './5_components/_company-settings-expense-type';
@import './5_components/_user-field';
@import './5_components/_my-cards';
@import './5_components/_account-page';
@import './5_components/_request-card-form';
@import './5_components/_payment-attach-table';
@import './5_components/_no-invoice-modal';
@import './5_components/_payment-table';
@import './5_components/_import-export';
@import './5_components/_slack-integration';
@import './5_components/_offer-map-marker';


/*
Helpers
 */
@import './6_helpers/_font-size';
@import './6_helpers/_link';
@import './6_helpers/_sections';
@import './6_helpers/_colors';
@import './6_helpers/_spacing';
@import './6_helpers/_read-only';
@import './6_helpers/_selection';
@import './6_helpers/_display';
@import './6_helpers/_clickable';
@import './6_helpers/_width';

/*
Widgets
 */

@import './7_widgets/request-expense-commute';

/*
Vendor
 */
@import './99_vendor/_flags.css';
@import './99_vendor/_slick-slider';
@import './99_vendor/_ag-grid';
@import './99_vendor/_tippy-tooltip';

.break {
  background: red;
  width: 100%;
}
