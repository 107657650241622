@use 'sass:math';

.filters {
  &__top {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    align-items: center;
    flex-wrap: wrap;

    & .input-group {
      width: 180px;
      margin-right: 30px;
    }

    & .input-group__input {
      min-width: 0;
      max-width: 110px;
    }
  }

  &__quick {
    display: flex;
    align-items: center;

    & .state-text-button {
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  &__quick-title {
    display: block;
    margin-right: 10px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;

    .filters__input-wrapper {
      width: math.div(100%, 3);
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;

      @include rwd(exlarge) {
        width: math.div(100%, 4);
      }
    }
  }

  &__main {
    position: relative;
  }

  &__toggler {
    display: block;
    margin-left: auto;
    cursor: pointer;
    border: 0;
    background: transparent;
    outline: 0;
    //position: relative;
    position: absolute;
    top: 0;
    right: 0;

    &--has-filters {
      &:after {
        content: attr(data-count);
        display: flex;
        position: absolute;
        top: -9px;
        right: -9px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #ff748a $gradient-danger-01;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #fff;
      }
    }
  }

  &__toggler-icon {
    font-size: 20px;
    color: $color-grey-07;
  }

  &__tooltip {
    position: relative;
    background-color: #fff;
    margin-top: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 5px;
    box-shadow: 0 0 20px 0 rgba(#000, .07);

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      right: 4px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 20px solid #fff;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;

    & .react-select__container--outer {
      width: 100%;
    }


    @media screen and (min-width: 1367px) {
      flex-wrap: nowrap;
    }
  }

  &__separator {
    display: block;
    margin: 0 5px;
  }

  &__input-title {
    margin: 5px 0;
    width: 100%;
    font-size: 12px;
    white-space: nowrap;

    &:after {
      content: ' ';
      display: table;
    }
  }

  &__period-container {
    display: flex;
    align-items: center;
    width: 100%;

    & .date-picker-field {
      width: 100%;
    }
  }


}

.filters-container{
  &__col{
    &.right-bottom {
      align-self: flex-end;
      text-align: right;
    }
  }
}

.agent-filters {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 30px;
  align-items: flex-end;

  > div {
    flex-grow: 1;
  }

  .input-group__input {
    margin-right: 5px;
  }
}
