.table-accordion__col__sortable {
  display: flex;
}

.basic-summary {
  .table-accordion__head {
    border-bottom: 0;
  }

  &.with-warning {
    .table-accordion__col {
      &:nth-child(3) {
        // icons column
        padding-right: 0;
      }
    }
  }

  .table-accordion__body {
    .table-accordion__accordion {
      .accordion__bar {
        .table-accordion__row {
          .table-accordion__col {
            .table-accordion__col__content {
              font-size: 14px;
              line-height: 20px;
              .icon {
                font-size: 22px;
                vertical-align: middle;
              }
            }
          }
        }
        .table-accordion__row-icon {
          bottom: 0;
          top: 0;
          display: flex;
          align-items: center;
          .icon {
            vertical-align: middle;
            font-size: 17px;

            &:before {
              vertical-align: bottom;
            }
          }
        }
      }
      .accordion__children {
        padding-right: 0;

        .accordion__children {
          padding-left: 0;
          .table-accordion__accordion {
            .accordion__bar {
              .table-accordion__row {
                .table-accordion__col:first-child {
                  padding-left: 30px;
                }
              }
            }
          }
          .basic-summary__expense {
            & > .accordion__bar {
              & > .table-accordion__row {
                & > .table-accordion__col:first-child {
                  padding-left: 30px;
                }
              }
            }
          }
          .basic-summary__documents {
            & > .accordion__bar {
              & > .table-accordion__row {
                & > .table-accordion__col:first-child {
                  padding-left: 90px;
                }
              }
            }
          }
        }
      }
      border-bottom: 0 none;
    }
  }


  .table-accordion__body {
    .settled-cell.has-warning {
      .summary-warnings {
        right: -25px;
      }
    }
  }
  .table-accordion__head,
  .table-accordion__body,
  .table-accordion__footer {
    .settled-cell {
      margin-right: -7px;
      white-space: nowrap;
    }

    .requested-cell {
      white-space: nowrap;
    }
  }

  .basic-summary--depth-0 {

    & > .accordion__bar {
      border-top: 1px solid #cacaca;
    }

    & > .accordion__children {
      padding-left: 5px;
    }
  }

  .flag {
    vertical-align: middle;
    margin-right: 8px;
  }
}

.basic-summary__text-indent {
  display: flex;
  align-items: flex-start;

  & .icon {
    display: block;
    margin-top: -1px;
  }
}

.summary-balance {
  .summary-balance__amount-cell {
    text-align: right;
    padding-right: 30px;
  }
}

.section--expense {
  .basic-summary .basic-summary__element-row {
    margin-right: 10px;
  }
}

.summary-settlement {
  & div:not(.accordion__children) .table-accordion__col__content span span {
    margin-right: 3px;
  }
  & div:not(.accordion__children) .table-accordion__col__content .basic-summary__currency {
    margin-right: 10px;
  }
}


.summary-warnings {
  position: absolute;
  display: inline-block;
  right: -16px;
  top: 8px;
  z-index: 97;
  transition: opacity .3s;

}

.summary-warnings__icon {
  font-size: 18px !important;
}

.basic-summary--depth-0.accordion--is-open > .accordion__bar {
    .summary-warnings {
      opacity: 0;
    }
}


.cover-id{
  margin-right: 10px;

  + .flag {
    margin-top: -4px;
  }
}

.dashboard-requests-to-accept__summary {
  .basic-summary {
    .table-accordion__col {
      display: flex;
      align-items: center;

      &.is-allign-end {
        justify-content: flex-end;
      }
    }

    .table-accordion__body {
      .settled-cell {
        margin-right: 0;
      }
    }

    .table-accordion__col:nth-child(3) {
      padding-right: 30px;
      margin-right: 0;

      .summary-warnings {
        right: 3px;
        top: 50%;
        transform: translate(0, -50%);
        line-height: 1;
        margin-top: -1px;
      }
    }
  }
}
