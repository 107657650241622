.main-header {
  //padding: $default-padding;
  background-color: $color-grey-02;
  border-bottom: 1px solid $color-grey-04;
  display: flex;
  justify-content: space-between;
}

.main-header__search {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 10px;
  @include rwd(small) {
    padding: $default-padding;
  }
}

.main-header__search.active + .main-header__profile-dropdown + .main-header__icons, .main-header__search.active + .main-header__profile-dropdown {
  @include rwdmax(small) {
    opacity: 0;
  }
}

.main-header__profile-dropdown {
  margin-right: 10px;
  padding: 10px 0;
  transition: opacity 0.5s;
  @include rwd(small) {
    padding: $default-padding 0;
    margin-right: $default-padding;
  }
}

.main-header__icons {
  border-left: 1px solid $color-grey-04;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 20px;
  transition: opacity 0.5s;
  @include rwd(small) {
    padding: $default-padding;
  }
  .icon {
    margin: 0 4px;
    cursor: pointer;
    font-size: 20px;
    color: $color-grey-04;
  }
}