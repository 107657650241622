@use '../../../../sass/0_settings/variables' as vars;


.offer-map-marker {
  position: relative;
  cursor: pointer;

  --marker-gallery-width: 200px;
  --marker-total-width: 700px;
  --marker-height: 340px;

  &__icon {
    position: relative;
    width: 32px;
    height: 34px;

    path {
      fill: vars.$color-default-02;
    }

    &--warning {
      path {
        fill: vars.$color-warning;
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: var(--marker-total-width);
    height: var(--marker-height);
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    //transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    --arrow-left: calc(50%);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: var(--arrow-left);
      transform: translateX(-50%);
      top: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      pointer-events: none;
    }

    &::before {
      border-top-color: rgba(0, 0, 0, 0.15); // Shadow color
      top: calc(100% + 1px);
      z-index: -1;
    }

    &::after {
      border-top-color: #fff;
      top: 100%;
    }

    &.arrow-top {
      &::before {
        bottom: calc(100% + 1px);
        top: auto;
        border-top-color: transparent;
        border-bottom-color: rgba(0, 0, 0, 0.15); // Shadow color
      }

      &::after {
        bottom: 100%;
        top: auto;
        border-top-color: transparent;
        border-bottom-color: #fff;
      }
    }
  }

  &__gallery {
    width: var(--marker-gallery-width);
    height: 100%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
  }

  &__offer-container {
    flex: 1;
    padding: 16px;
  }

  &.hovered {
    z-index: 999;

    .offer-map-marker__content {
      visibility: visible;
      opacity: 1;
    }

    // a dummy rectangle that expand element width & height to let user
    // move cursor into the popover and not trigger mouseleave event
    .offer-map-marker__icon {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 50px;
        width: 100px;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 20px);
      }

    }
  }
}
