.user-profile__fields-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-profile__fields {
  display: flex;
  flex-direction: column;
}

.user-profile__fields-grouped {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-profile__hover-tooltip {
  letter-spacing: .22px;
  font-size: 14px;
  white-space: nowrap;
  max-width: initial;
}

.user-profile__hover-line {
  white-space: nowrap;
  cursor: pointer;
}

.user-profile__fields {

  .date-picker-field {
    width: 100%;
  }

  .form-group__input-wrapper {
    width: 100%;
  }

  .form-group {
    position: relative;
    flex-flow: column;
    width: 300px;
  }

  .form-group__error > p {
    margin-top: 10px;
  }

  .react-select__container--outer {
    width: 100%;
  }
}
