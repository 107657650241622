.switch-to-map {
  position: relative;
  aspect-ratio: 16/9;
  margin-bottom: 20px;
  cursor: pointer;

  &__map {
    display: block;
  }

  &__map-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.15s ease-in;
  }

  &:hover & {
    &__map-backdrop {
      opacity: 0.1;
    }
  }


  & &__button {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 10px 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    z-index: 2;
    line-height: 18px;


    .icon {
      position: relative;
      left: initial;
      font-size: 18px !important;
      line-height: 18px;
      top: 3px;
      margin-right: 2px;
    }
  }
}
