.pagination {
  display: inline-block;
  margin-top: 20px;
  width: 100%;

  &.pagination--align-center {
    text-align: center;
  }

  .pagination__wrapper {
    display: inline-block;
  }

  .pagination__link {
    display: inline-block;
    min-width: 27px;
    height: 27px;
    line-height: 27px;
    color: $color-grey-07;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    padding-top: 1px;

    &:hover{
      color: #000;
    }

    &.pagination__link--is-active {
      color: #fff !important;
      border-radius: 50%;
      background-color: #64e0ba;
      background: linear-gradient(90deg, #64e0ba, #64e0ba 55%, #2dcee3 90%);
    }
  }

  &.has-notification-bar {
    margin-bottom: 50px;
  }
}
