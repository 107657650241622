.no-invoice-modal {
  text-align: center;

  &__icon {
    text-align: center;
  }

  &__description {
    width: 400px;
    margin-bottom: 30px;

    h4 {
      text-transform: none;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .icon-warning {
    font-size: 80px;
    color: $color-warning;
  }
}
