.input-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 31px;
}

.input-toggle__input {
  display: none;

  &[disabled] {
    + .input-toggle__toggle-container-outer{
      background: linear-gradient(90deg, #ccc 0%, #ccc 55%, #ccc 90%) !important;
    }
  }
}

.input-toggle__toggle-container-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 47px;
  height: 24px;
  background: $gradient-default-01;
  border-radius: 11px;
  position: relative;
}

.input-toggle__toggle-container-inner {
  position: absolute;
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  background: #fff;
  border-radius: 9px;
  line-height: 20px;
}

.input-toggle__toggle-close-icon, .input-toggle__toggle-checked-icon {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  bottom: 0;
}

.input-toggle__toggle-close-icon {
  color: #ccc;
  left: 5px;
  font-size: 14px;
}

.input-toggle__toggle-checked-icon {
  color: $color-default-01;
  font-size: 19px;
  right: 1px;
  margin-right: 0 !important;
}

.input-toggle__checked-background {
    position: absolute;
    left: 3px;
    top: 1px;
    bottom: 0;
    width: 18px;
    height: 18px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: block;

    opacity: 1;
}


.input-toggle__input:not(:checked) + .input-toggle__toggle-container-outer {
  background: $gradient-default-01;
  .input-toggle__checked-background {
    background: #ccc;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  .input-toggle__toggle-close-icon {
    color: #fff;
  }
  .input-toggle__toggle-checked-icon {
    color: #ccc;
  }
}

.input-toggle__input:checked + .input-toggle__toggle-container-outer {
  background: $gradient-default-01;
  .input-toggle__checked-background {
    background: $gradient-default-01;
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;

  }
  .input-toggle__toggle-close-icon {
    color: #ccc;
  }
  .input-toggle__toggle-checked-icon {
    color: #fff;
  }
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(20px); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(20px); }
}

// input toggle value
.input-toggle-value {
  position: relative;
  display: block;
  width: 47px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $color-default-03 $gradient-default-01;
  box-shadow: inset 0 -999px 0 0 #fff;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    background: $color-default-01 $gradient-default-01;
    border-radius: 50%;
    transition: left .25s ease;
  }
}

.input-toggle-value--disabled {
  cursor: default;
  background: $color-grey-04;
  &::before {
    background: $color-grey-04;
  }
}

.input-toggle-value--on {
  &::before {
    left: calc(100% - 18px);
  }
}

.input-toggle-value__input {
  display: none;
  visibility: hidden;
  opacity: 0;
}

