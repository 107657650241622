.hotels-dialog--single > div {
  display: flex;
  flex-direction: column;
}

.hotels-dialog--single > div > div {
  @media screen and (min-height: 900px) {
    display: flex;
    flex-flow: row;
  }
}

.selected-hotel {
  @media screen and (min-height: 900px) {
    flex-grow: 1;
  }
}

.selected-hotel__legend {
  margin-left: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  line-height: 1;

  &-text {
    display: block;
    margin-left: 5px;
  }

  .icon {
    font-size: 18px;
  }

}

.selected-hotel__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media screen and (min-height: 900px) {
    height: 100%;
  }
}

.selected-hotel__column {
  margin-right: 15px;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-height: 900px) {
    display: flex;
    flex-flow: column;

    &:last-child {
      margin-top: 70px;
    }
  }
}

.selected-hotel__name {
  letter-spacing: .35px;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  span {
    margin-right: 20px;
  }

  .svg {
    width: 20px;
    position: relative;
    top: -1px;
    margin-left: 10px;
  }

  .selected-hotel__name-stars {
    position: relative;

  }

  .selected-hotel__name-icons {
    display: flex;
    align-items: center;
  }
}

.selected-hotel__distance {
  padding-left: 14px;
  margin-top: 1px;
}

.selected-hotel__address {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  color: #505050;
  margin-left: -2px;

  &::before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1em;
    content: "\e97c";
    color: $color-google;
    margin-right: 1px;
  }
}

.selected-hotel__tripadvisor-img {
  margin-left: -4px;
}

.selected-hotel__offer-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.selected-hotel__offer-wrapper--single-hotel {
  height: 25px;
  visibility: hidden;
  opacity: 0;
}

.selected-hotel__offer-icons {
  box-sizing: border-box;
  line-height: 1;
  margin: 2px 0 0;

  & .icon {
    color: #848484;
    font-size: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.selected-hotel__offer-icon {
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.selected-hotel__offer-facilities {
  margin-bottom: 5px;
  margin-left: -2px;
}

.selected-hotel__offer-details {
  font-size: 14px;
  color: #848484;
  letter-spacing: 1px;
  line-height: 17px;
  text-align: right;
  width: 200px;

  @media screen and (min-width: 1540px) {
    width: auto;
  }
}

.selected-hotel__detail {
  padding-right: 12px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.selected-hotel__details {
   @media screen and (min-height: 900px) {
    display: flex;
    flex-flow: column;
    height: 100%;
    margin-bottom: 10px !important;

    > :nth-child(2) {
      height: 100% !important;
    }
  }
}

.selected-hotel__details-title {
  display: block;
}

.selected-hotel__details-copy {
  margin: 0;
  letter-spacing: 1px;
  line-height: 17px;
}

.selected-hotel__footer {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selected-hotel__footer--small {
  display: none;

  @media (max-width: 1800px) {
    display: flex;

    .selected-hotel__price {
      margin-right: 30px;
    }
  }
}

.selected-hotel__small-footer-details {
  display: flex;
  justify-content: space-between;
}

.selected-hotel__small-footer-details--fullwidth {
  width: 100%;
}

.selected-hotel__another-offer-btn {
  min-width: 145px;
  padding: 0 10px;
  height: 36px;
  line-height: 34px;
}

.selected-hotel__price-amount-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 3px;
}

.selected-hotel__price-corporate-image {
  position: relative;
  max-height: 25px;
  margin-top: 2px;
}

.selected-hotel__price-icon {
  font-size: 22px;
  margin-right: 5px;
}

.selected-hotel__price-amount {
  letter-spacing: .29px;
  font-size: 18px;
  font-weight: 500;
}

.selected-hotel__price-per-night {
  font-size: 12px;
  letter-spacing: .23px;
  line-height: 12px;
  display: block;
  text-align: right;
}

.selected-hotel__show-offers-btn {
  min-width: 118px;
  border-radius: 14px;
  height: 27px;
  line-height: 27px;
  padding: 0 10px;
  margin-top: 10px;
}

.selected-hotel__gallery {
  display: flex;
  height: 348px;
  overflow: hidden;
}

.selected-hotel__aside-gallery {
  height: 100%;
  max-width: 119px;
  width: 100%;
  margin-right: 8px;
  display: block;
}

.selected-hotel__aside-gallery-image-wrapper {
  height: 78px;
  width: 100%;
  position: relative;
  transition: box-shadow .25s ease;
  cursor: pointer;
  outline: 0;

  &::before {
    content: "";
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #2dcee3;
    box-sizing: border-box;
  }
}

.selected-hotel__aside-gallery-image-wrapper--active {
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .25);

  &::before {
    display: block;
  }
}

.selected-hotel__aside-gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: url(/images/no-images.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}

.selected-hotel__main-gallery {
  position: relative;
  width: 100%;
  height: calc(100% - 14px);
  margin-bottom: 0;
  overflow: hidden;
}

.selected-hotel__gallery-btn {
  position: absolute;
  z-index: 1;
  width: 38px;
  height: 100%;
  border: 0;
  cursor: pointer;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(26, 26, 26, .7) 100%);
  outline: none;

  &::before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    content: "\e904";
    color: #fff;
    position: absolute;
    transform-origin: center;
    top: 50%;
    left: calc(50% - 3px);
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.selected-hotel__gallery-btn--prev {
  left: 0;
}

.selected-hotel__gallery-btn--next {
  right: 0;
  transform-origin: center;
  transform: rotate(180deg);
}

.selected-hotel__main-gallery-image {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/no-images.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
}
