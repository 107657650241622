.radio-wrapper {
  display: flex;
  align-items: center;
}

.radio-wrapper__input-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.radio-wrapper__input {
  display: none;

  &[disabled] {
    + .radio-wrapper__icon {
      background: #ccc;
    }
  }
}

.radio-wrapper__icon {
  flex-shrink: 0;
  display: flex;
  width: 17px;
  height: 17px;
  border: 2px solid transparent;
  border-radius: 50%;
  position: relative;
  justify-content: center;
  align-items: center;
  background: $color-default-01 $gradient-default-01 no-repeat 100% 0;
  -moz-background-origin: border;
  background-origin: border-box;
  box-shadow: inset -999px 0 0 #fff;
  background-size: 200% 200%;
}

.radio-wrapper__input:checked + .radio-wrapper__icon {
  animation: radio-wrapper__icon 0.2s forwards;
  &:before {
    display: block;
    width: 7px;
    height: 7px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: " ";
    background: $gradient-default-01 no-repeat;
  }
}

.radio-wrapper__label {
  cursor: pointer;
  padding: 0 10px;
  font-weight: 300;
}

@keyframes radio-wrapper__icon {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
