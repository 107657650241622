//.main-page-loader {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  background-color: #fff;
//  left: 0;
//  top: 0;
//  z-index: 98;
//  opacity: 1;
//  transition: opacity 0.5s;
//}
//
//@keyframes loader {
//  0% {
//    width: 0;
//    height: 0;
//  }
//  100% {
//    height: 0;
//    width: 0;
//  }
//}
//
//.main-page-loader.hidden {
//  opacity: 0;
//  animation: loader 0.1s 5s forwards;
//}


//@todo fake loader
@keyframes loader {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  99% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  100% {
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

.main-page-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 1;
  animation: loader 0.2s 1s forwards;
  overflow: hidden;
}

.main-page-loader--infinite {
  animation: none;
}

@keyframes loader-spin {
  0% {
    transform: translateX(-50%) rotate(0);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

.main-page-loader__loader {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border-top: 2px solid $color-grey-04;
  border-right: 2px solid $color-grey-04;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-radius: 50%;
  animation: loader-spin $loader-speed linear infinite;
}

.has-loader {
  position: relative;
}

.mb-4 {
  margin-bottom: 4rem;
}

.pt-12 {
  padding-top: 12rem;
}

.component-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: 98;
  opacity: 1;
  animation: loader 0.2s 1s forwards;
  overflow: hidden;
}

.component-loader__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-top: 2px solid $color-grey-04;
  border-right: 2px solid $color-grey-04;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-radius: 50%;
  animation: loader-spin $loader-speed linear infinite;
  transform: translate(-50%, -50%);

  &--top-150{
    top:150px;
  }
  &--top-200{
    top:200px;
  }
}

.component-loader__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.5);
  z-index: 97;
}

.component-loader__loader--small {
  width: 25px;
  height:25px;
  top: calc(50% - 25px);
  left: calc(50% + 12px);
}

.component-loader__loader--center {
  top: 50%;
  left: 50%;
}

.component-loader__loader--document {
  background-color: transparent !important;
}

.has-loader--mark-as-paid {
  .component-loader__overlay {
    background-color: initial;
  }
}

.component-loader__label {
  position: absolute;
  left: 50%;
  top: calc(50% + 40px);
  z-index: 999999999;
  transform: translate(-50%, -50%);
}
