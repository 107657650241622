@use 'sass:math';

/* global */
$grid_columns: 12;
$grid_columns-padding: 15px;
$container-padding: 15px;

$default-padding: 30px;
$default-padding-small: 15px;

$default-margin: 30px;
$default-margin-small: math.div($default-margin, 2);

$default-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.07);

$loader-speed: 0.9s;


/* breakpoints */
$rwd-small: 768px;
$rwd-medium: 992px;
$rwd-large: 1200px;
$rwd-exlarge: 1370px;

$rwd-max-small: $rwd-small - 1px;
$rwd-max-medium: $rwd-medium - 1px;
$rwd-max-large: $rwd-large - 1px;
$rwd-max-exlarge: $rwd-exlarge - 1px;

/* colors */
$text-color: #000000;

$color-default-01: #64e0ba;
$color-default-02: #4fd9de;
$color-default-03: #2dcee3;

$color-primary-01: #ff2243;
$color-primary-02: #ff2243;

$color-warning: #ffc107;
$color-success: #28a745;
$color-danger: #ff2243;
$color-info: #2dcee3;

$color-google: #e04b42;
$color-orange: #ff9e1e;

$color-yellow: #F8CB1C;
/*
PDF style-guide  numbers
 */
$color-grey-02: #ffffff;
$color-grey-03: rgba(0, 0, 0, 0.07);
$color-grey-03-semi-transparent: rgba(225, 225, 225, .5);
$color-grey-04-semi-transparent: rgba(225, 225, 225, .25);
$color-grey-04: #cacaca;
$color-grey-05: #505050;
$color-grey-06: #000000;
$color-grey-07: #848484;
$color-grey-08: #f8f8f8;
$color-grey-09: #eaeaea;
$color-grey-10: #a4a4a4;

$gradient-default-01: linear-gradient(90deg, $color-default-01 0%, $color-default-01 55%, $color-default-03 90%);
$gradient-default-02: linear-gradient(0deg, $color-default-01 0%, $color-default-01 25%, $color-default-03 90%);
$gradient-default-03: linear-gradient(90deg, $color-default-03 0%, $color-default-03 55%, $color-default-01 90%);
$gradient-info-01: linear-gradient(90deg, $color-info 0%, $color-info 55%, $color-default-02 90%);
$gradient-primary-01: linear-gradient(90deg, $color-primary-01 0%, $color-primary-01 55%, $color-primary-02 90%);
$gradient-danger-01: linear-gradient(90deg, $color-primary-01 0%, $color-primary-01 55%, #ff748a 90%);
$gradient-warning-01: linear-gradient(90deg, $color-warning 0%, $color-warning 55%, #ff672b 90%);


/* typography */
$font-size-default: 14px;
$font-size-small: 13px;
$font-size-xsmall: 12px;

$font-weight-book: 200;
//$font-weight-regular: 300;
$font-weight-semibold: 500;
$font-weight-heavy: 600;

$font-lineheight-default: 1.36;
$font-lineheight-small: 1.2;