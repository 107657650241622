.input-square {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.input-square--center {
  justify-content: center;
}

.input-square__icon {
  width: 30px;
  border-radius: 4px;
  height: 30px;
  border: 1px solid $color-grey-05;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-square__input:checked + .input-square__icon:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90e";
  font-size: 24px;
  position: absolute;
  display: block;
}

.input-square__input:disabled + .input-square__icon {
  border: 1px solid $color-grey-04;
}

.input-square__label {
  margin: 0 10px;
}

.input-square__input {
  display: none;
}
