.loading-overlay {
  position: absolute;
  z-index: 98;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $color-grey-02;
  cursor: wait;
}

@keyframes loader-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loading-overlay__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  transform-origin: center center;
  border-top: 2px solid $color-grey-04;
  border-right: 2px solid $color-grey-04;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-radius: 50%;
  animation: loader-spin $loader-speed linear infinite;
}

.loading-overlay__loader--center {
  top: calc(50% - 25px);
}

.loading-overlay__loader--xs {
  width: 30px;
  height: 30px;
  border-width: 2px;

  &.loading-overlay__loader--center {
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }
}
