.comment {
  border: 1px solid $color-grey-04;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  margin: 15px 0 0 0;
  //@include rwd(small) {
  //  margin: 15px 0 15px 110px;
  //}
}

.comment__photo {
  margin-right: 20px;
  align-self: flex-start;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: $color-grey-03;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
}

.comment__content {
  white-space: pre-line;
}

.comment__content-desc {
  margin: 0;
  margin-bottom: 10px;
}

.comment__content-name {
  color: $color-grey-04;
}

.comment__content-date {
  color: $color-grey-04;
}
