.has-error {
  display: flex;
  align-items: center;

  .field-error {
    margin-left: 10px;

    .icon {
      color: $color-primary-02;
    }
  }

  .field-input {
    flex-grow: 1;
  }
}
