.label {
  display: inline-block;
  text-align: center;
  padding: 5px;
  vertical-align: middle;
  border-radius: 50%;
  min-width: 2em;
  margin-right: 20px;
}

.label--is-warning {
  background-color: $color-warning;
  color: #fff;
}
