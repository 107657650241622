.path {
  margin-top: $default-padding;
  margin-left: 5px;
  display: block;
  position: relative;
}

.path:before {
  content: '';
  width: 2px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  margin-left: -2px;
  border-left: 2px solid $color-default-01;
  transform: translateY(5px);
}

.path:after {
  content: '';
  width: 2px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  margin-left: -2px;
}

.path__element {
  padding-bottom: 15px;
  padding-left: 25px;
  position: relative;
  font-size: $font-size-small;
  &:last-child {
    height: 0;
    padding-bottom: 0;
  }
}

.path__element:before {
  content: " ";
  width: 8px;
  height: 8px;
  position: absolute;
  left: -5px;
  top: 5px;
  background-color: $color-default-02;
  border-radius: 50%;
  z-index: 2;
}


.path__element.active ~ .path__element:before {
    background: #fff;
    border: 2px solid #2dccc3;
    width: 5px;
    height: 5px;
    top: 4px;
    left: -5px;
}

.path__element.active:before {
    @extend .icon;
    width: auto;
    height: auto;
    font-size: 38px;
    background: transparent;
    top: -18px;
    left: -20px;
    position: absolute;
    color: $color-default-02;
    border: 0 !important;
    z-index: 2;
    content: "\e95e";
}

.path__element:after {
  content: " ";
  width: 32px;
  height: 32px;
  position: absolute;
  left: -19px;
  top: -9px;
  background-color: $color-grey-02;
  border-radius: 50%;
  z-index: 1;
  border: 2px solid $color-default-01;
  opacity: 0;
  transform: scale(0);
  transition: 0.5s;
}

.path__element.active:after {
  opacity: 1;
  transform: scale(1);
}

.path__element-content {
  margin: 0;
  //transform: translateY(-5px);
  margin-top: -5px;
  
  span {
      display: block;
  }
}

.path__date {
  color: #505050;
}

.path__element-content-list {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  margin-bottom: 5px;

  .form-group__input-wrapper {
    min-height: 10px;  
  }
}

.path__element-content-list li {
  display: flex;
  align-items: center;
}