.alert {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid $color-grey-04;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
  position: relative;
}

.alert-warning {
  border-color: $color-warning;
}

.alert__content {
  padding-right: 50px;
  span {
    font-weight: 500;
  }
}

.alert__controls {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.alert__controls-icon {
  cursor: pointer;
  font-size: 20px;
}

.alert__controls-icon + .alert__controls-icon {
  margin-left: 5px;
}

.no-ocr-hints {
  color: $color-google;
}

.panel-alert {
  display: block;
  font-size: .8em;
}
.panel-alert--success {
  color: $color-success;
}
.panel-alert--danger {
  color: $color-danger;
}
