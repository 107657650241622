.room-field {
  .room-row {
    display: flex;
    justify-content: space-between;
  }

  .room-item {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .room-name {
      margin-right: 20px;
      white-space: nowrap;
      flex-grow: 1;
    }

    .room-field {

    }
  }

  .room-col + .room-col {
    margin-left: 50px;
  }
}
