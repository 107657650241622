@font-face {
  font-family: 'unisans';
  src: url('/fonts/unisans-book-webfont.eot');
  src: url('/fonts/unisans-book-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/unisans-book-webfont.woff2') format('woff2'),
  url('/fonts/unisans-book-webfont.woff') format('woff'),
  url('/fonts/unisans-book-webfont.ttf') format('truetype'),
  url('/fonts/unisans-book-webfont.svg#unisansbookuploaded_file') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'unisans';
  src: url('/fonts/unisans-semibold-webfont.eot');
  src: url('/fonts/unisans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/unisans-semibold-webfont.woff2') format('woff2'),
  url('/fonts/unisans-semibold-webfont.woff') format('woff'),
  url('/fonts/unisans-semibold-webfont.ttf') format('truetype'),
  url('/fonts/unisans-semibold-webfont.svg#unisanssemibolduploaded_file') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'unisans';
  src: url('/fonts/unisans-heavy-webfont.eot');
  src: url('/fonts/unisans-heavy-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/unisans-heavy-webfont.woff2') format('woff2'),
  url('/fonts/unisans-heavy-webfont.woff') format('woff'),
  url('/fonts/unisans-heavy-webfont.ttf') format('truetype'),
  url('/fonts/unisans-heavy-webfont.svg#unisansheavyuploaded_file') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot');
  src:  url('/fonts/icomoon.eot#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf') format('truetype'),
  url('/fonts/icomoon.woff') format('woff'),
  url('/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
