.notice-loader {
  position: relative;
  margin: 15px 0 5px;
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  color: #848484;
  letter-spacing: 1px;
  text-align: center;

  &__loader-text {
    position: relative;
    padding-right: 75px;
    display: inline;

    &--hotels-search {
      //display: block;
      //text-align: right;
      margin: 0;
      //padding-top: 5px;
      //&:after {
      //  top: 12px !important;
      //}
    }

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      right: 0;
      display: block;
      width: 25px;
      height: 25px;
      border-top: 2px solid $color-grey-04;
      border-right: 2px solid $color-grey-04;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      border-radius: 50%;
      animation: loader-spin $loader-speed linear infinite;
      transform: translateX(-65px);
    }
  }

  &__description{
    margin:35px 0 0;
    &--break {
      margin: 0;
    }
  }

  &--hotels-search-pending {
    margin: 5px 0 5px;
    &:before {
      width: 15px;
      height: 15px;
      margin-right: 30px;
    }
  }

  &--hidden {
    visibility: hidden;
  }
}
