.header-search {
  @include rwdmax(small) {
    padding-left: 70px;
  }
}

.header-search.active .header-search__input-group {
  max-width: 100%;
  transition: max-width 0.5s, min-width 0.5s;
  min-width: 150px;
  position: absolute;
  z-index: 10;
  overflow: visible;
}

.header-search__input-group {
  max-width: 40px;
  overflow: hidden;
  min-width: 40px;
  transition: max-width 0.5s, min-width 0.5s;
  @include rwd(small) {
    max-width: 100%;
    min-width: 350px;
  }
}

.header-search__input-group-input {

}