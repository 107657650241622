.substitutions__header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.substitutions__title {
  margin-right: 11px;
}

.substitutions__icon-info {
  margin: 0;
}

.substitutions__range-panel {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.substitutions__range-field-wrapper {
  display: block;
  width: 151px;
  margin-right: 21px;

  &:last-child {
    margin-right: 0;
  }
}

.substitutions__copy {
  margin: 0 0 26px 0;
}

.substitutions__user-panel {
  display: flex;
  align-items: center;
  margin-bottom: 27px;
}

.substitutions__username-field-wrapper {
  width: 151px;
  margin-right: 21px;

  .react-select__container--outer {
    width: 100%;
  }
}

.substitutions__user {
  display: flex;
  align-items: center;
}

.substitutions__user-avatar {
  display: block;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 25px;
  border-radius: 50%;
}

.substitutions__user-detail {
  display: block;
  font-size: 14px;
  letter-spacing: 0.26px;
  line-height: 17px;
  color: #505050;
  text-decoration: none;
}
.substitutions__user-detail--bold {
  font-weight: 500;
}

.substitutions__set-button {
  min-width: 131px;
  margin-bottom: 40px;
}

.substitutions-table {

  &__header {
    font-weight: $font-weight-semibold;
  }

  &__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d0d0d0;

    &:last-child {
      border-bottom: none;
    }
  }

  &__cell {
    padding: 5px;
    min-width: 200px;

    @media screen and (max-width: 1600px) {
      min-width: 110px;
    }
  }

  &__cell:nth-child(1) {
    width: 100%;
  }
}
