.lang {}

.lang__selector {
    display: block;
    margin: 10px;
    position: absolute;
    right: 5px;
    border-right: 1px solid #737373;
    border-left: 1px solid #737373;
    padding: 0 5px;
    cursor: pointer;
    color: #737373;
    font-size: 10px;
	letter-spacing: 3px;
}

.lang__selector-item {
    display: none;
    color: initial;

    &--active {
        display: block;
    }
}