.table-accordion {
  overflow-X: auto;
  padding: 0 15px;
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px !important;

  .section__header-title {
    font-weight: 100 !important;
  }
}

.table-accordion__row-icons {
  opacity: 1;
  transition: opacity .3s;
  padding-left: 45px;
  @include rwdmax(exlarge) {
    padding-left: 35px;
  }
}
.table-accordion__row-icons .icon {
  font-size: 20px !important;
  margin-right: 5px;
}
.table-accordion__row-icons .icon-arrow_more {
  font-size: 16px !important;
  color: $color-grey-04;
}
.accordion--is-open .table-accordion__row-icons {
  opacity: 0;
}

.table-accordion-container {
  overflow: hidden;
  margin: $default-padding-small 0;
  position: relative;
  padding-bottom: 180px;

  &.table-accordion-container--unset-overflow {
    overflow: unset;
    padding-bottom:175px;
    .table-accordion {
      overflow-x: unset;
    }
  }
  &.table-accordion-container--no-padding-bottom {
    padding-bottom: 0px;
  }
}

.table-accordion__head {
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-basis: 100%;
  color: $text-color;
  font-weight: 500;
  border-bottom: 1px solid $color-grey-04;
  padding-right: 10px;

  &.table-accordion__head--noborder {
    border: 0;
  }

  .accounting__header {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    color: #505050;
  }
}

.section--expense {
  .table-accordion__head {
    .table-accordion__row > .table-accordion__col:nth-child(2) {
      /* padding-right: 15px; */
    }
  }
}

.table-accordion__body {
  flex-grow: 1;
  flex-basis: 100%;
  position: relative;

  .loading-overlay {
    z-index: 1;
  }

  .table-accordion__accordion {

    .accordion__children {
      position: relative;
      padding-right: 10px;
    }

    .accordion__bar {
      position: relative;
      color: $text-color;
      padding-right: 10px;


      .table-accordion__row-icon {
        padding-top: 10px;
        padding-bottom: 10px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        line-height: 19px;
        display: block;
        max-height: 40.38px;

        .icon {
          margin: 0;
          color: $color-default-01;
        }

        &.table-accordion__row-icon--close {
          transform: rotate(180deg);
          line-height: 14px;
        }
      }
    }
  }

  .table-accordion__accordion:not(:last-child) {
    border-bottom: 1px solid $color-grey-04;
  }

  &.table-accordion__body--is-loading {
    min-height: 200px;
  }

  & > * {
    -webkit-animation: fadeInFromNone .25s ease-out;
    -moz-animation: fadeInFromNone .25s ease-out;
    -o-animation: fadeInFromNone .25s ease-out;
    animation: fadeInFromNone .25s ease-out;
  }

  & > a {
    display: block;
  }
}

.table-accordion__footer {
  flex-basis: 100%;
  flex-wrap: nowrap;
  flex-grow: 1;
  border-top: 1px solid $color-grey-06;
  padding-right: 10px;
  padding-top: 5px;
  color: $text-color;
  font-size: 17px;

  .table-accordion__col {
    padding-left: 0 !important;
  }
}

.table-accordion__footer-bold {
  font-size: 17px;
  color: $color-grey-06;
  display: block;
}

.table-accordion__row {
  margin: 0;

  &.table-accordion__row--is-link {
    cursor: pointer;
    color: $color-grey-05;
    align-items: center;
    &:not(.react-datepicker-wrapper):hover {
      color: $color-default-02;
    }

    .sublink {
      color: $color-grey-05;
    }

    .sublink:hover {
        color: $color-default-02;
    }
  }

  &.table-accordion__row--is-thin {
    .table-accordion__col.xs-5 {
        padding-left: 38px;
        @include rwd(exlarge) {
          padding-left: 45px;
        }
    }
    .table-accordion__col.xs-6 {
        padding-left: 38px;
        @include rwd(exlarge) {
          padding-left: 45px;
        }
    }
    .table-accordion__col {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }

  &.table-accordion__row--travel-expenses {
    .table-accordion__col__content {
      padding-left: 38px;
      @include rwd(exlarge) {
        padding-left: 45px;
      }
    }
  }

  .icon {
    margin-right: 5px;
  }

  .table-accordion__col {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    word-wrap: break-word;
    position: relative;

    &.table-accordion__col--is-link {
      cursor: pointer;
      color: inherit;
      align-items: center;
      &:not(.react-datepicker-wrapper):hover {
        color: inherit;

        .sublink {
          color: inherit;
        }
      }

      .sublink {
        color: inherit;
      }
    }
  }
}



@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}




.green-table:not(.table-accordion__row--is-data-row) {
  .table-accordion__col:nth-child(2n) .table-accordion__col__content,
  .table-accordion__col:nth-child(3n) .table-accordion__col__content {
    font-weight: 400;
  }
}
.green-table {
  &.table-accordion__row--is-data-row
  .table-accordion__col__content {
    font-weight: 300 !important;
  }
  .table-accordion__col--is-summary {
    font-weight: bold;
    @include rwdmax(exlarge) {
      padding: 10px 5px !important;
    }
    .table-accordion__col__content {
      font-weight: bold !important;
    }
  }

  &.table-accordion__row--is-background-gradient-row, &.table-accordion__row--is-background-primary-row {
    &.table-accordion__row--is-background-gradient-row, &.table-accordion__row--is-background-primary-row {
      background: -webkit-gradient(linear, left top, right top, from(#64e0ba), to(#2dcee3));
      background: linear-gradient(90deg, #64e0ba, #2dcee3);
    }
    &.table-accordion__row--is-background-primary-row {
      .table-accordion__col {
        background: $color-default-01;
      }
    }

    .table-accordion__col {
      color: #fff;
      text-transform: uppercase;
      word-wrap: break-word;
      /* word-break: break-all; */
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid white;
      border-right: 1px solid white;
      text-align: center;

      .table-accordion__col__content {
        display: inline-block;
        vertical-align: text-bottom;
      }
    }
  }
}

.no-uppercase {
  .table-accordion__col__content {
    text-transform: none;
  }
}

/* Special elements for table-accordion in different views */

.request-list__table
.table-accordion__col__content {
    font-weight: 300 !important;
}

.table-accordion_row-greyed-out {
  opacity: 0.5;
}

.request-list__column-name-ellipsis {
  margin: 0 3px;
}
.request-list__column-name-icon {
  font-size: 18px;
  color: $color-grey-04 !important;
}
.request-list__column-name span {
  letter-spacing: 0 !important;
}

.installment {
  margin-top: -8px;
  color: #505050;

  .table-accordion__col__content {
    font-size: 13px;
    font-weight: 300 !important;
  }
}

.summary-balance {

}

.decretation {
  &.table-accordion__row--is-thin {
    .table-accordion__col.xs-6 {
      padding-left: 0px;
    }
  }

  &__text-line {
    position: relative;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    max-height: 20px;
    cursor: default;
    transition: max-height 1s;

    &:hover {
      max-height: 100px;
      white-space: normal;
    }
  }
}

.accounting__table {
  .table-accordion__row--is-data-row > .table-accordion__col--is-summary:nth-child(3),
  .table-accordion__row--is-data-row > .table-accordion__col--is-summary-2:nth-child(3) {
    background: #f5f5f5;
  }
}

.table-accordion__total {
  display: block;
}
.table-accordion__total--installment {
  font-size: 13px;
  font-weight: 300 !important;
  color: $color-grey-05;
}

.table-accordion__installment-date {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &--flex-start{
    justify-content: flex-start;
  }
}

.is-allign-end {
  .table-accordion__installment-currency {
    justify-content: flex-end;
  }
}
.table-accordion__installment-currency {
  display: flex;
  align-items: center;
}

.table-accordion__installment-loader {
  min-height: 39px;
  .component-loader__overlay {
    background-color: transparent;
  }
}


.table-accordion__row-edit {
  display: flex;
  align-items: center;

  > :nth-child(2) {
    max-width: 80px;
  }
}

.table-stylesheet-theme.table-accordion-container {
  overflow: initial;

  .table-accordion {
    overflow: initial;
  }

  .table-accordion__head {
    padding-right: 0;

    .table-accordion__row {
      align-items: flex-end;
      flex-wrap: nowrap;
    }
  }

  .table-accordion__row .icon-edit {
    margin-right: -2px;
  }

  .table-accordion__body {
    .table-accordion__row {
      height: 58px;
      flex-wrap: nowrap;
    }
  }

  &.table-with-form {
    .table-accordion__body {
      .table-accordion__row {
        min-height: 58px;
        height: auto;
      }
    }
  }

  .accounted-sum-amount {
    padding-left: 15px;
    padding-right: 15px;
    width: 155px;
    justify-content: flex-end;

    @include rwd(exlarge) {
      width: 155px;
    }
  }

  .sum-amount {
    padding-left: 15px;
    padding-right: 15px;
    width: 175px;
    justify-content: flex-end;

    @include rwd(exlarge) {
      width: 175px;
    }
  }

  .checkbox-wrapper__input-wrapper {
    margin: 0;
  }
}

.table-accordion-container.align-center {
  .table-accordion__col {
    display: flex;
    align-items: center;

    .table-accordion__col__content {
      width: 100%;
    }
  }
}

.table-accordion__cell-group {
  display: flex;
  align-items: center;
  color: inherit;
  flex-grow: 1;

  &__link:hover {
    color: $color-default-02
  }
}

.table-accordion {
  .has-checkbox {
    padding: 0 !important;

    .checkbox-wrapper {
      padding: 15px;
      cursor: pointer;
    }
  }
}
