@use 'sass:math';

$arrowSize: 15px;

.profile-dropdown {
  position: relative;
}

.profile-dropdown.active {
  z-index: 99;

  .profile-dropdown__header {
    z-index: -1;
  }
}

.profile-dropdown.active .profile-dropdown__list {
  display: block;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.profile-dropdown__header {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;

  .icon {
    font-size: 12px;
  }

  &:before {
    content: " ";
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0);
    left: 0;
    top: 0;
  }
}

.profile-dropdown.active .profile-dropdown__header:before {
  display: block;
}

.profile-dropdown__header-img {
  border-radius: 50%;
  width: 50px;
  background-color: $color-grey-03;
  background-size: cover;
  background-position: center;
  height: 50px;
  flex-shrink: 0;
  margin-right: 15px;
  box-shadow: 5px 5px 8px $color-grey-03;
}

.profile-dropdown__list {
  position: absolute;
  background-color: $color-grey-02;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 10px 0 0;
  right: math.div($arrowSize, 2);
  display: none;
  min-width: 280px;
  box-shadow: 0 0 $arrowSize $color-grey-03;
  z-index: -1;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: $color-grey-02;
    transform: rotate(45deg);
    top: math.div($arrowSize, 2) * -1;
    right: 5px;
    box-shadow: 0 0 $arrowSize $color-grey-03;
    z-index: -1;
  }

  &:after {
    display: block;
    top: 0;
    right: 0;
    content: '';
    width: 35px;
    height: 20px;
    position: absolute;
  }

  li {
    position: relative;
    &.is-sub-open{
      &:after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: calc(50% + #{$arrowSize});
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: lighten(#e1e1e1, 5%);
        transform: rotate(45deg) translateY(-50%);
        top: 50%;
        left: -$arrowSize;
        box-shadow: 0 0 $arrowSize rgba(0, 0, 0, 0.07);
        z-index: -1;
      }
    }

    &.active {
      span {
        background-color: lighten(#e1e1e1, 5%);
      }
    }
  }
}

.profile-dropdown__list-header {
  padding: 10px 15px;
  display: block;
  background: #fff;
}

.profile-dropdown__list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.profile-dropdown__list-element {
  padding: 10px 15px;
  display: flex;
  cursor: pointer;
  color: $color-grey-05;
  text-decoration: none;
  background: #fff;

  &:hover {
    background-color: lighten(#e1e1e1, 5%);
  }
}

.profile-dropdown__list-element--divider {
  border-top: 1px solid #ededed;
}
.profile-dropdown__list-element--divider-bottom {
  border-bottom: 1px solid #ededed;
}



.profile-dropdown__list {
  ul {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(calc(-100% - #{$arrowSize}));
    display: none;
    background-color: $color-grey-02;
    min-width: 140px;
    width: 100%;
    box-shadow: 0 0 $arrowSize $color-grey-03;
    list-style: none;
    padding: 0;

    &.active {
      display: block;
    }

    li {
      padding: 10px 15px;
      display: flex;
      cursor: pointer;
      color: $color-grey-05;
      text-decoration: none;
      background: #fff;

      &:hover {
        background-color: lighten(#e1e1e1, 5%);
      }
    }

  }
}

.profile-dropdown__list-search {
  background-color: white;
  padding: 10px;
}

.profile-dropdown {
  &__list {
    &-element {
      border-top: 1px solid #ededed;
    }
  }

  &__header-content {
    padding-top: 14px;
    margin-right: 15px;
    flex-grow: 1;
    display: none;

    @include rwd(small) {
      display: flex;
      flex-direction: column;
    }
  }

  &__header-company {
    font-size: 10px;
  }

  &__company-name {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.open {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &__company {
    background-color: #fff;
    position: relative;

    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 15px rgb(0 0 0 / 7%);
    }
  }
}