.person-add {
  padding: 5px 0 0 0;
}

.person-add__add {
  display: flex;
  align-items: center;
}

.person-add__select-container {
  /* flex-wrap: nowrap; */
  align-self: center;
}

.person-add--select {
  display: none !important;
}

.person-add--select.active {
  display: flex !important;
}

.person-add__label {
  padding: 0 5px;
  /*display: flex;
  align-items: center;
  flex-grow: 1;*/
}

.person-add__label--path {
  padding: 0 5px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: calc(100% - 42px);
}

.person-add__edit .icon {
  margin-left: 5px;
  margin-right: 0;
}

.person-add__data {
    width: 100%;
    display: flex;
    padding: 0 5px 0 24px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.person-add .icon--lg {
    font-size: 18px !important;
}