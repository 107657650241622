.tooltip-content {
  padding: $default-padding;
  box-shadow: 0 0 15px $color-grey-03;
  position: relative;
  margin-top: 15px;
  background-color: $color-grey-02;
  z-index: 5;
  transform: translateY(8px);
}

.tooltip-content--documents {
  display: flex;
}

.timeline-container--other-costs {
  .tooltip-content {
    z-index: 3;
  }
}

.tooltip-content--no-arrow {
  &:before, &:after {
    content: none;
  }
}

.tooltip-content__arrow {
  position: absolute;
  bottom: calc(100% - 1px);
  left: calc(50% - 3px);
  background: #ffffff;
  border: 1px solid $color-grey-03;
  transform: translateX(-4px);
}

.tooltip-content__arrow:after, .tooltip-content__arrow:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip-content__arrow:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}
.tooltip-content__arrow:before {
  border-color: rgba(225, 225, 225, 0);
  border-bottom-color: $color-grey-03;
  border-width: 9px;
  margin-left: -9px;
}

.icon-select + .tooltip-content:before {
  content: '';
  background: transparent;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 15px solid #fff;
  position: absolute;
  left: 10px;
  top: -15px;
  filter: drop-shadow(0px 0px 5px $color-grey-03);
  z-index: -1;
}

.icon-select + .tooltip-content:after {
  content: '';
  width: 40px;
  height: 10px;
  background: #fff;
  position: absolute;
  top: 0px;
  left: 10px;
}

