.is-margin-top {
  margin-top: $default-margin;
}

.is-margin-top-small {
  margin-top: $default-margin-small;
}

.is-margin-bottom {
  margin-bottom: $default-margin;
}

.is-margin-bottom-small {
  margin-bottom: $default-margin-small;
}

.is-margin-right-small {
  margin-right: $default-margin-small;
}
