.document-preview {
  position: relative;
}

.document-preview__thumbnail {
  width: 100%;
  margin-bottom: 30px;
  img {
    width: 100%;
  }
}

.document-preview--container {
  aspect-ratio: 1 / 1.4142;
}

.document-preview--sticky .document-preview__thumbnail {
  width: 100%;
  z-index: 2;
  @include rwd(small) {
    position: fixed;
    width: 250px;
  }
  @include rwd(medium) {
    width: 300px;
  }
  @include rwd(large) {
    width: 400px;
  }
}

.document-preview__blank {
  display: none;
  img {
    width: 100%;
  }
}

.document-preview--sticky .document-preview__blank {
  opacity: 0;
  width: 100%;
  display: none;
  @include rwd(small) {
    width: 250px;
    display: block;
  }
  @include rwd(medium) {
    width: 300px;
  }
  @include rwd(large) {
    width: 400px;
  }
}