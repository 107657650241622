.page-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  cursor: pointer;
}

.page-close .icon {

}