/*
.btn
 */

.btn {
  padding: 10px 35px;
  min-width: 150px;
  max-width: 100%;
  display: block;
  appearance: none;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 2px 2px 5px $color-grey-03;
  border: 0 none;
  font-weight: $font-weight-semibold;
  position: relative;
  text-decoration: none;

  &:disabled {
    pointer-events: none;
  }

  &--loading {
    pointer-events: none;
  }

  &--waiting {
    background: $color-grey-03 !important;
    box-shadow: none !important;
  }

  &--waiting:hover {
    cursor: wait;
  }

  &--locked {
    background: $color-grey-03 !important;
    box-shadow: none !important;
  }

  &--locked:hover {
    cursor: not-allowed;
  }
}

.btn--tiny {
  display: flex;
  align-items: center;
  padding: 0 10px;
  line-height: 1;
}

.btn--xs {
  padding: 10px 25px;
  min-width: 0;
}

.btn--xxs {
  padding: 3px 20px;
  min-width: unset;
}

.btn .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn--pull-end {
  margin-left: auto;
}

.btn--hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &:before {
    content: '.';
  }
}

.btn:hover {
  outline: none;
}

.btn:visited, .btn:focus {
  outline: none;
  box-shadow: none;
}

.btn--primary {
  position: relative;
  color: $color-grey-02 !important;
  background-color: $color-default-01;
  background: $gradient-default-01;
  background-position: 100% 2px;
  background-size: 200% 200%;
  transition: all .5s ease;
  .icon {
    color: $color-grey-02;
  }
}

.btn--primary:hover {
  background-position: 0 0;
}

.btn--primary__disabled {
  background: $color-grey-03 !important;
  box-shadow: none !important;
}

.btn--primary:disabled {
  @extend .btn--primary__disabled;
}

.btn--secondary {
  position: relative;
  background: $color-grey-02;
  color: $color-default-01;
  background-clip: padding-box;
  background: $gradient-default-01;
  .icon {
    color: $color-default-01;
  }
}

.btn--secondary:hover {
  border: 2px solid $color-default-01;
}

.btn--secondary:disabled {
  background: $color-grey-02 !important;
  border: 2px solid $color-grey-03 !important;
  color: $color-grey-03 !important;
  box-shadow: none !important;
  .icon {
    color: $color-grey-02 !important;
  }
}

.btn.btn--outline,
.btn.btn--outline-light {
  border: 2px solid transparent;
  color: $color-default-03;
  background-color: $color-default-03;
  background: $gradient-default-01;
  box-shadow: inset -999px 0 0 #fff; /* The background color */
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
  filter: drop-shadow(2px 2px 5px $color-grey-03);

  .btn__progress {
    //animation: backward-filling 10s forwards;
    //left:0;
    //right: initial;
    background: black;
    opacity: .1;
  }
}

.btn.btn--outline-light {
  border-width: 1px;
}

.btn--outline:hover {
  background-position: 0 0;
  color: $color-default-01;
}

.btn--outline:disabled {
  border: 2px solid $color-grey-03 !important;
  color: $color-grey-03 !important;
  background: $color-grey-03 !important;
  cursor: default;
}

.btn.btn--danger {
  color: $color-grey-02 !important;
  background-color: $color-primary-01;
  background: $gradient-danger-01;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;

  &--nogradient {
    background: $color-primary-01;
  }
}

.btn--danger:hover {
  background-position: 0 0;
}

.btn.btn--warning {
  color: $color-grey-02 !important;
  background-color: $color-warning;
  background: $gradient-warning-01;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
}

.btn--warning:hover {
  background-position: 0 0;
}

.btn.btn--link {
  left: 25px;
  position: absolute;
  box-shadow: 0 0 0 transparent;
  border: 0;
  text-decoration: underline;
  padding: 0;
  font-size: 14px;
  min-width: auto;
  //color: #64e0ba;
  color: #4fd9de;
  cursor: pointer;
  display: table;
  margin-right: 10px;
  background: linear-gradient(90deg, #64e0ba 0%, #64e0ba 55%, #2dcee3 90%);
  background-clip: border-box;
  -webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  align-self: center;
}

.btn.btn--icon {
  display: flex;
  align-items: center;
  padding: 10px 35px 10px 50px;
  min-width: 135px;
}

.btn .icon {
  position: absolute;
  font-size: 2.5rem !important;
  line-height: 20px;
}

.btn__content {
  position: relative;
  z-index: 5;
}

.btn__progress {
  opacity: .4;
  background-color: white;
  position: absolute;
  top:0;
  right:0;
  width: 10%;
  height: 100%;
  animation: forward-filling 10s forwards;
  z-index: 4;
  border-radius: 13px;
}

.btn-add {
  font-size: 20px;
  height: 31px;
  margin-bottom: 3px;
  margin-left: 10px;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 35px;
}

.btn.with-arrow {
  padding-left: 16px;
  padding-right: 13px;

  .btn__content {
    display: inline-flex;
    align-items: center;
  }

  .icon-arrow {
    display: inline-flex;
    align-items: center;
    position: static;
    font-size: 12px !important;
    transform: none !important;
    margin-left: 8px;

    &.icon--rotate {
      transform: rotate(180deg) !important;
      transform-origin: 50% 50%;
    }
  }
}

.btn--inline-block{
  display: inline-block;
}

@keyframes forward-filling {
  0% {
    width: 100%;
  }
  5% {
    width: 100%;
  }
  10% {
    width: 90%;
    border-radius: 0 13px 13px 0;
  }
  30% {
    width: 80%;
    border-radius: 0 13px 13px 0;
  }
  50% {
    width: 40%;
    border-radius: 0 13px 13px 0;
  }
  75% {
    width: 30%;
    border-radius: 0 13px 13px 0;
  }
  80% {
    width: 28%;
    border-radius: 0 13px 13px 0;
  }
  90% {
    width: 25%;
    border-radius: 0 13px 13px 0;
  }
  100% {
    width: 23%;
    border-radius: 0 13px 13px 0;
  }
}


@keyframes backward-filling {
  0% {
    width: 10%;
    border-radius: 13px 0 0 13px;
  }
  5% {
    width: 10%;
    border-radius: 13px 0 0 13px;
  }
  10% {
    width: 20%;
    border-radius: 13px 0 0 13px;

  }
  30% {
    width: 30%;
    border-radius: 13px 0 0 13px;
  }
  50% {
    width: 50%;
    border-radius: 13px 0 0 13px;
  }
  75% {
    width: 75%;
    border-radius: 13px 0 0 13px;
  }
  80% {
    width: 78%;
    border-radius: 13px 0 0 13px;
  }
  90% {
    width: 80%;
    border-radius: 13px 0 0 13px;
  }
  100% {
    width: 80%;
    border-radius: 13px 0 0 13px;
  }
}

