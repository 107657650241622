.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-wrapper__input-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 2px 0;
  width: 18px;
  height: 18px;
  border: 1px solid $color-default-02;
  border-radius: 3px;
  &.checkbox-wrapper__input-wrapper--is-disabled {
    border-color: #cccccc;
    cursor: not-allowed;
    * {
      cursor: not-allowed !important;
    }
  }
}

.checkbox-wrapper__input {
  display: none;
}

.checkbox-wrapper__icon {
  display: none;
}

.checkbox-wrapper__label {
  padding: 0 10px;
  line-height: 1;
}

.checkbox-wrapper__input:checked + .checkbox-wrapper__icon {
  display: block;
  animation: checkbox-wrapper__icon 0.2s forwards;
  color: $color-default-02;
  font-weight: bold;
  margin-left: -1px;
  font-size: 18px;
  margin-top: 0px;
  color: #64e0ba;
  cursor: pointer;
  background: $gradient-default-01;
      background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-padding-before: 0px;
}

.checkbox-wrapper__input-wrapper--is-disabled .checkbox-wrapper__icon {
  background-image: linear-gradient(90deg, #ccc, #ccc 55%, #ccc 90%) !important;
}

@keyframes checkbox-wrapper__icon {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
