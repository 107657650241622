.reservation-status {
  position: relative;
  line-height: 17px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 3px 0 3px 30px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top:0;
    left: 0;
    width: 21px;
    height: 21px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &--success {
    color: #64e0ba;

    &::before {
      background-image: url('../../img/success.svg');
    }
  }

  &--error {
    color: #f32043;

    &::before {
      background-image: url('../../img/error.svg');
    }
  }

  &--warning {
    color: $color-warning;

    &::before {
      background-image: url('../../img/warning.svg');
    }
  }
}