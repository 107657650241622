.table__header {
  display: flex;
}

.table__header-actions {
  display: flex;
  margin-left: auto;
  align-items: flex-end;

  .table__header-select {
    width: 300px;
    margin-right: 15px;
  }
}
