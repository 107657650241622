.dashboard-requests-documents__container-outer {

  > div {
    display: flex;
    max-width: 85%;
  }

  .dashboard-requests-documents__container {
    height: 320px;
    margin-right: 20px;
    max-width: 75%;

    & > div {
      overflow-y: hidden;
      padding-right: 10px;
    }

    & div .row {
      margin-left: 0;
      margin-right: 0;
    }

    > div > div:first-child{
      position: static !important;
      overflow: hidden !important;
    }

    .document-cell-outer {
      width: 100%;
    }

    .col {
      flex-shrink: 0;
      @include rwdmax(exlarge) {
        padding: 0;
        margin-right: 18px;
        width: 190px !important;
        &:first-child {
          margin-left: 10px;
        }
      }
    }

  }
  .upload-cell {
    display: inline-block;
    .upload__dropzone.dropzone {
      width: 220px;
      display: inline-block;
      & > div {
        width: 100% !important;
      }
    }
  }
  .dashboard-requests-documents__upload-container {
    padding-top: 19px;
    display: inline-block;
    position: relative;
    top: -4px;
    vertical-align: top;
  }
}
