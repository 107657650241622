.notification-bar {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  background: #fff;
  height: 80px;
  padding: 10px 25px;
  box-shadow: -1px -1px 10px $color-grey-03;
  margin-left: -17px;
  width: calc(100% - 190px);
  z-index: 100;
  @include rwd(exlarge) {
    width: calc(100% - 222px);
  }

  &__row {
    display: flex;
    align-items: center;
  }

  span {
    font-weight: 300;
    margin-left: 0px;
  }

  &__title {
    padding: 10px 15px;
    cursor: default;
  }
}
