.foreign-currencies__icon--small {
  .icon--type-fx {
    margin-right: 5px;
  }

}

.add-unrequested-reservation {

  &__wrapper {
    display: flex;
    min-height: 283px;

    &--form {
      width: 100%;
    }
  }

  &__container {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #2dcee3;
    font-weight: 500;
    height: 100%;
    width: 100%;
  }

  &__select {

  }

  &__init-section {
    cursor: pointer;
  }

  &__select-type-section {
    text-decoration: none;
  }

  &__button-text {
    width: 100%;
    border-bottom: 2px solid #2dcee3;
    padding-bottom: 0.05rem;
  }
}
