@import '../helpers';

/*
html
body
 */

* {
  box-sizing: border-box;
}

body {
  font-family: 'unisans';
  font-weight: $font-weight-book;
  line-height: $font-lineheight-default;
  font-size: 16px;
  color: $color-grey-05;
  letter-spacing: .4px;
  @include rwd(medium) {
    font-size: $font-size-default;
  }
}



* {
  //box-shadow: inset 0 0 0 1px rgba(200,0,0,1);
}

.styleguide {
  hr {
    margin: 30px 0;
  }
}

code {
  border: 1px solid $color-grey-03;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: inline-block;
}
