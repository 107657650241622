.notification-element {
  padding: 10px 15px;
  display: flex;
  cursor: pointer;
  color: $color-grey-05;
  text-decoration: none;
  background: #fff;
  &:hover {
    background-color: lighten(#e1e1e1, 5%);
  }
}

.notification-element--read {
  background-color: lighten(#e1e1e1, 5%);
}

.notification-element--warning {
  .icon {
    color: $color-warning;
  }
}

.notification-element--info {
  .icon {
    color: $color-default-02;
  }
}

.notification-element__col-icon {
  padding-right: 10px;
  .icon {
    font-size: 18px;
  }
}

.notification-element__title {}

.notification-element__desc {

}
