.file {
  border: 1px solid $color-grey-04;
  padding: 15px;
  text-align: center;
  margin: 20px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.file__preview {
  width: 100%;
  flex-shrink: 0;
  border: 1px solid $color-grey-04;
  background-size: cover;
  background-position: top center;
  height: 200px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .icon {
    margin-left: 5px;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.file__trash {
  display: flex;
  position: absolute;
  opacity: 0;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border: 1px solid $color-grey-04;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
}

.file:hover .file__trash {
  opacity: 1;
  transition: opacity 0.3s;
}

.file__preview-header {
  text-align: right;
  padding: 5px;
  font-size: 20px;
}

.file__preview-footer {
  text-align: right;
  padding: 5px;
  font-size: 20px;
}

.file__name {
  text-align: left;
  display: block;
  color: $color-grey-04;
  word-break: break-all;
}

.file__title {
  text-align: left;
  display: block;
  color: $color-grey-06;
  word-break: break-all;
  font-weight: $font-weight-semibold;
}

.file__sub-title {
  text-align: left;
  display: block;
  color: $color-grey-06;
  word-break: break-all;
  font-weight: $font-weight-book;
}

.file__btn {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}