.expense-type {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  &__header {
    &--uppercase {
      .panel__header {
        text-transform: uppercase;
      }
    }
  }
}

.expense-type__group {
  flex-grow: 1;
  position: relative;
}

.expense-type__tooltip {
  padding: 20px;
  margin-top: 0;
  margin-bottom: 15px;
  //position: absolute;
  left: 0;
  right: 0;
  min-width: 100%;

  &.last-toggle{
    order: 999;
  }
}

.expense-type__tooltip.is-active {
  display: flex;
}

