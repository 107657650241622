.request-actions-select {
  margin-right: 15px;
  position: relative;

  .request-actions-select-label {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 15px;
    }
  }

  .react-select__select-container {
    position: absolute;
    width: auto;
    bottom: 100%;
    top: auto;
  }

  .react-select__menu {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .icon-other {
    font-size: 24px;
    cursor: pointer;
  }

  .date-picker-field {
    width: 200px;
  }

  .react-select__option {
    display: flex;
    align-items: center;
    overflow: initial;
  }
}
