.react-datepicker {
  font-family: 'unisans';
  border: 0;
  box-shadow: 1px 1px 10px rgb(242, 242, 242);
  overflow: hidden;
  display: flex;
}

.react-datepicker-popper {
  margin-top: 10px;
  z-index: 1100;
  //width: 315px;
}

.react-datepicker-popper .react-datepicker__triangle {
    top: 0;
    border-top: none !important;
    border-bottom: 8px solid #f0f0f0 !important;
    margin-top: -8px;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__header {
  background: #f1f1f1;
  text-transform: uppercase;
  boder-bottom: 0;
  border: 1px solid #eee;
}

.react-datepicker__current-month {
  font-weight: 400;
  color: #727272;
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
  font-weight: 400;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 10px;
}

.react-datepicker__navigation--previous {
  border-right-color: #515151;
}

.react-datepicker__navigation--next {
  border-left-color: #515151;
}

.react-datepicker__input-container {
  @extend .input-wrapper;
  &:before {
    /* background-color: transparent !important */;
    right: 5px !important;
    font-size: 20px;
  }
}

.react-datepicker__input-container input {
  @extend .input;
  &[disabled] + .react-datepicker__close-icon {
    display: none;
  }
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #7c7c7c;
  outline: none;
}

.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__day-name {
  font-weight: 400;
}

.react-datepicker__day--selected {
  color: #fff !important;
  background: $gradient-default-01;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
  &:hover {
      background-position: 0 0;
      border-radius: 10px;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $color-default-02;
  color: #fff;
  &:hover {
    background-color: $color-default-03;
  }
}

.react-datepicker__close-icon {
  //content: "\e93b";
  @extend .icon;
  @extend .icon-close_fill;
  color: $color-danger;
}

.react-datepicker__close-icon:before {
  background-color: transparent;
}

.react-datepicker__close-icon {
  position: absolute;
  right: 25px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  text-align: center;
}

.react-datepicker__close-icon:after {
  content: none;

}

.react-datepicker__header--time {
  padding-bottom: 32px;
}
.react-datepicker__time-list {
  padding: 0;
  overflow-y: visible !important;
  height: 175px !important;
}

.react-datepicker__time-list-item {
  color: #7c7c7c;
}
.react-datepicker__time-list-item--selected {
  //width: fit-content;
  color: #fff !important;
  background: $gradient-default-01;
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all .5s ease;
  font-weight: 500 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: transparent;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: transparent;
}

.react-datepicker-time__header {
  font-weight: normal;
  color: #727272;
}

.date-picker-field {
  position: relative;

  .date-picker-field__icon {
    position: absolute;
    font-size: 18px;
    top: 50%;
    margin-top: -13px;
    right: 4px;
    height: 18px;
    cursor: pointer;
  }
}

.border-crossing{
  .date-picker-field {
    width: 100%;
  }
}
