.button-group {
  margin-top: $default-padding !important;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .btn {
    width: 100%;
    margin-bottom: 10px;
    @include rwd(small) {
      margin-left: 10px;
      margin-bottom: 0;
      width: auto;
    }
  }

  &--fake {
    margin-top: -20px !important;
  }
}


.request-button-group {
  margin-top: $default-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn.btn--link {
    position: static;
    margin-right: 20px;
  }
}
