.react-select__select-container {
  cursor: pointer;
  width: 100%;
  position: relative;

  &.react-select__select-container--is-disabled {
    background-color: #ebebe4;
    cursor: not-allowed;
  }

  .react-select__dropdown-indicator {
    cursor: pointer;

    svg {
      display: none;
    }
    &:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px;
      line-height: 16px;
      content: "\e904";
      color: #4fd9de;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__control {
    min-height: 31px;
    background: #fff;
    border-color: #cacaca;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      border-color: #4fd9de;
    }
  }

  .react-select__placeholder {
    color: $color-grey-05;
    font-weight: 200;
    font-size: 13px;
  }

  .react-select__group {
    padding: 0;

    & > div:first-child:not(.react-select__option) {
      background: #f3f3f3;
      padding: 5px 10px;
      font-weight: 400;
      color: #333;
      font-size: 14px;
      text-transform: none;
      cursor: default;
      margin: 0;
    }

    .react-select__option {
      padding-left: 20px;
    }
  }

  .react-select__input {
    line-height: initial;
    height: auto;
  }

  .react-select__clear-indicator {
    position: absolute;
    right: 23px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-align: center;
    cursor: pointer;

    &:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      color: $color-danger;
      background-color: transparent;
      content: "\e917";
      font-size: 18px;
    }

    svg {
      display: none;
    }
  }

  .react-select__single-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
    min-width: 35px;
  }
}

// fix firefox select issues (VTL-1841)
.react-select__value-container {
  //max-height: 31px;
}

.react-select__multi-value-label {
 padding: 5px;
  line-height: 20px;
}

.react-select__option-row {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;

  .checkbox-wrapper {
    justify-self: flex-end;
  }

  .react-select__multi-row-wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    > div {
      max-width: calc(100% - 30px);
    }
  }

  .react-select__row-wrapper {
    width:100%;
  }

  .react-select__option-row-label {
    white-space: normal !important;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
}

.react-select__option-row-amount {
  font-size: 12px;
  font-weight: 500;

  > span {
    font-weight: 200;
  }
}

.react-select__menu-portal {
  z-index: 1400 !important;
}

.react-select__select-container,
.react-select__menu-portal {
  .react-select__menu {
    width: auto;
    min-width: 100%;
    max-width: 300px;
    margin-top: 0;
    border-width: 1px;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1101;

    .react-select__menu-list {
      padding: 0;
    }

    .react-select__option {
      cursor: pointer;
      color: $color-grey-05;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.react-select__option--is-focused {
        background: #f2f9fc;
      }

      &.react-select__option--is-selected {
        background: #f5faff;
      }

      &:hover:not(.react-select__option--is-focused) {
        background: #f2f9fc;
      }
    }
  }
}

.react-select__container--flight-services {
  .react-select__menu {
    .label.checkbox-wrapper {
      padding-left: 10px;
    }
  }
}

.react-select__currency {
  .react-select__control {
    height: 31px;
  }
}
