.hello-message {
     margin-bottom: 1em;
     margin-top: .5em;
     font-weight: 500;
}

.click-to-action {

}

.click-to-action__header {
     text-transform: uppercase;
}

.click-to-action__boxes {
     display: flex;
     flex-flow: wrap row;
     justify-content: space-between;
}

.click-to-action__box-wrapper {
     width: 50%;

     &:nth-child(even) {
          padding-left: 15px
     }

     &:nth-child(odd) {
          padding-right: 15px
     }
}

.click-to-action__box {
     display: flex;
     flex-flow: wrap column;
     margin-bottom: 30px;
     box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
     align-items: center;
     justify-content: center;
     height: 350px;
}

.click-to-action__noshadow {
     box-shadow: none;
}

.click-to-action_half__box {
     width: 100%;
     height: 167px;
     display: flex;
     flex-flow: wrap column;
     padding-bottom: 0px;
     align-items: center;
     justify-content: center;
     box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
     &:nth-child(odd) {
          margin-bottom: 15px;
          padding-bottom: 0px;
     }
}

.click-to-action__box-label {
     font-size: 18px;
     margin-top: 40px;
     color: $color-grey-06;
     font-weight: 500;
}

.click-to-action__box-button {
     font-size: 18px;
     padding: 25px 26px !important;
     border-radius: 20px;

     .icon-plus2 {
          font-size: 50px !important;
          left: 1px;
     }
}

.breakable {
     -ms-word-break: break-all;
     word-break: break-all;

     /* Non standard for webkit */
     word-break: break-word;

     -webkit-hyphens: auto;
     -moz-hyphens: auto;
     -ms-hyphens: auto;
     hyphens: auto;
}