.input-wrapper {
  width: 100%;
  position: relative;
  &:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-default-02;
    cursor: pointer;
    background-color: #fff;
    padding-left: 5px;
  }
}

.input-wrapper--search {
  .input-group {
    border: 2px solid $color-grey-04;
    background: transparent;
    box-shadow: none;
  }

  .input-group__input {
    max-width: calc(100% - 50px);
    min-width: 1px;
  }
}

.input-wrapper--date {
  flex-grow: unset !important;
  width: auto;
  .react-datepicker__input-container input {
    width: 175px;
    border: 0;
    color: $color-grey-07;
  }
  .react-datepicker__input-container input::-moz-placeholder {
    color: #000;
    font-size: 14px;
  }
}

.filter__input-wrapper {
  .Select-control {
    /* border: 0; */
    box-shadow: none;
  }
  .Select-placeholder {
    color: $color-grey-07;
  }
  .Select-input {
    min-width: 66px;
  }
  .is-focused:not(.is-open) > .Select-control {
      border: 0;
      border-color: transparent !important;
      border-bottom: 1px solid $color-default-02 !important;
      box-shadow: 0 0 0 0 !important;
      border-radius: 0 !important;
  }
}
