.login {
  width: 100%;
  height: 100vh;
  display: flex;

  .login__background {
    background: url('/images/login_background.jpg');
    background-repeat: no-repeat;
    flex-grow: 1;
    display: flex;
    background-size: auto 100%;
    background-position-x: 20%;
    @include rwd(exlarge) {
      background-position-y: 25%;
      background-size: auto 140%;
    }

    a {
      align-self: flex-end;
      padding-left: 50px;
      padding-bottom: 40px;
      color: #737373;
      letter-spacing: 3px;
    }
  }

  .login-wrapper {
    width: 470px;
    display: flex;
    flex-direction: column;

    .login__logo {
      margin: 150px auto 0;
      height: 100px;
      width: 280px;
      background: url('/images/mindento_logo_gradient.png') no-repeat center;
      background-size: calc(100% - 60px);
      transform: translateY(10px);

      @media screen and (max-height: 550px) {
        margin-top: 20px;
      }
    }

    .login__panel {
      width: 100%;
      flex-grow: 1;
      padding: 0 95px;
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 12px;
      }

      > div {
        width: 100%;
      }

      .form-group {
        display: flex;
        flex-flow: column;
        margin-top: -5px;

        .form-group__wrapper {
          width: 100%;
          margin: 14px auto;

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        .input {
          border: 1px solid transparent;
          border-bottom: 1px solid #737373;
          border-radius: 0;
          padding-left: 0;
          color: #000;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            text-transform: lowercase;
            color: #737373;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            text-transform: lowercase;
            color: #737373;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            text-transform: lowercase;
            color: #737373;
          }
          &:-moz-placeholder { /* Firefox 18- */
            text-transform: lowercase;
            color: #737373;
          }
        }

        .input--submit {
          margin: 38px 0 10px;
          width: 100%;
          padding: 8.5px 35px;
          box-shadow: 7px 7px 5px 0px #f3f3f3;
        }
      }

      .remember {
        display: flex;
        width: 100%;
        font-size: 12px;
        color: #7c7c7c;
        text-transform: lowercase;

        a {
          flex-grow: 1;
          text-align: right;
          align-self: center;
          text-decoration: none;
        }
      }
    }

    .login-with {
      text-align: center;
      margin: 0 95px 43px;
      color: #7c7c7c;

      &__title {
        font-size: 13px;
      }

      &__buttons {
        margin-top: 15px;
        justify-content: center;
        display: flex;

        .login-button {
          background: transparent;
          padding: 7px 10px;
          display: block;
          appearance: none;
          cursor: not-allowed;
          border-radius: 15px;
          box-shadow: 7px 7px 5px 0px #f3f3f3;
          font-weight: $font-weight-book;
          border: 1px solid $color-grey-03;
          margin-right: 15px;
          width: 100%;
          font-size: 12px;
          outline: none;
          display: flex;
          align-items: center;

          &:last-child {
            margin-right: 0;
          }

          .icon {
            display: block;
          }

          .text {
            width: 100%;
          }

          .google-icon {
            height: 20px;
            width: 24px;
            background: url('../../img/google.svg') no-repeat;
          }

          .azure-icon {
            height: 16px;
            width: 18px;
            background: url('../../img/azure.svg') no-repeat;
          }

          &.azure {
            color: #2385c4;
          }
        }
      }
    }
  }
}

.lost-password-form {
  position: relative;
  .form-group__wrapper {
    .form-group__label {
      display: none;
    }
    .form-group__input-wrapper {
      align-self: normal;
    }
  }
  .lost-password-form__success-message-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    p {

    }
  }
}