/*------------------------------------------
Breakpoint
------------------------------------------*/

@mixin rwd($width) {
  @if $width==small {
    @media only screen and (min-width: $rwd-small) {
      @content;
    }
  }
  @else if $width==medium {
    @media only screen and (min-width: $rwd-medium) {
      @content;
    }
  }
  @else if $width==large {
    @media only screen and (min-width: $rwd-large) {
      @content;
    }
  }
  @else if $width==exlarge {
    @media only screen and (min-width: $rwd-exlarge) {
      @content;
    }
  }
  @else {
    @media only screen and (min-width: $width) {
      @content;
    }
  }
}

@mixin rwdmax($width) {
  @if $width==small {
    @media only screen and (max-width: $rwd-max-small) {
      @content;
    }
  }
  @else if $width==medium {
    @media only screen and (max-width: $rwd-max-medium) {
      @content;
    }
  }
  @else if $width==large {
    @media only screen and (max-width: $rwd-max-large) {
      @content;
    }
  }
  @else if $width==exlarge {
    @media only screen and (max-width: $rwd-max-exlarge) {
      @content;
    }
  }
}

/*------------------------------------------
Retina
------------------------------------------*/

@mixin retina($file, $type, $width, $height) {
  background-image: url('../img/' + $file + '.' + $type);
  background-size: $width $height;
  @media (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    & {
      background-image: url('../img/' + $file + '-2x.' + $type);
      background-size: $width $height;
    }
  }
}

/*------------------------------------------
Responsive background
------------------------------------------*/
@mixin section-bg($file,
$type) {
  background-image: url('../img/' + $file + '.' + $type);
  @include rwd(small) {
    background-image: url('../img/' + $file + '-t.' + $type);
  }
  @include rwd(large) {
    background-image: url('../img/' + $file + '-d.' + $type);
  }
}
