.travel-point {
  margin-right: 20px;
  white-space: nowrap;
}

.timeline-container--trip-plan {
  .travel-point__link {
    position: relative;
  }

  .timeline__header--title.xs-9 {
    .travel-point__link {
      transform: translateY(-50%);
    }
  }

  .timeline__header-row {
    .travel-point__link {
      .icon-compas {
        position: absolute;
        top: -5px;
      }
    }
  }
}

.travel-point-start-stop--label {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  color: #505050;
  height: 16px;
}

.travel-point-date {
  white-space: nowrap;
}

.timeline {
  .travel-point-date {
    &:before {
      content: ' ';
      display: table;
    }
  }

  &.trip-start,
  &.trip-end {
    .travel-point-date {
      &:before {
        content: '';
        display: inline;
      }
    }
  }
}
