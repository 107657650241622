.document-assign {}

.document-assign--overlay {
  .document-cell-inner {
    //background: rgba($color-grey-05, 0.1);
  }

  .document-assign__select-label {
    margin-bottom: 5px;
    text-align: center;
    font-size: 13px;
  }

  .document-assign__select {
    display: block;

    .react-select__select-container .react-select__menu{
      min-width: 169px;
      max-width: 169px;
    }

    .react-select__select-container .react-select__option{
      white-space: pre-wrap;
      overflow: visible;
      text-overflow: unset;
      font-size: 13px;
    }
  }

  .document-cell__img-wrapper {
    opacity: .1;
    filter: blur(1px);
  }
}

.document-assign__select {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 150px;
  display: none;
  @include rwdmax(exlarge) {
    left: calc(50% - 10px);
  }
}
