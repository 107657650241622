.react-select-container {
  align-items: center;
  position: relative;
  display: flex;
}

.react-select-container .icon {
  cursor: pointer;
}

.react-select-container .react-select {
  display: none;
}

.react-select-container__placeholder {
  padding: 6px 10px;

  &.react-select-container__placeholder--no-padding-left {
    padding-left: 0;
  }
}

.react-select-container.active .react-select-container__placeholder {
  display: none;
}

.react-select-container.active .react-select {
  display: block;
}

.react-select-container.active .icon {
  display: none;
}

.react-select-custom {
  .react-select__menu {
    top: 100%;
    background-color: hsl(0,0%,100%);
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
    margin-bottom: 8px;
    position: absolute;
    box-sizing: border-box;
  }

  .react-select__with-search {
    padding-top: 35px;
  }

  .react-select__search {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px;
    background: white;
  }

  .react-select__menu-list {
    position: relative;
    max-height: (35px * 9);
    overflow-y: auto;
  }

  .react-select__option {
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
  }
}

.react-select__container--outer {
  position: relative;
}
