.mileage-allowance-page,
.document-page,
.account-document-page {
  .read-only {
    input,
    .input,
    .Select-control,
    .checkbox-wrapper,
    .checkbox-wrapper__input-wrapper,
    .checkbox-wrapper__input,
    .checkbox-wrapper__icon,
    .input-toggle__toggle-container-outer,
    .input-toggle,
    .radio-wrapper {
      border: none !important;
      pointer-events: none !important;
      cursor: initial;
    }

    .icon-calendar,
    .icon-trash,
    .Select-arrow-zone,
    .Select-arrow,
    .Select-clear-zone {
      display: none !important;
    }

    .input-toggle__toggle-container-outer {
      background: #ccc !important;
    }
    .input-toggle__input:not(:checked) + .input-toggle__toggle-container-outer .input-toggle__checked-background {
      background: linear-gradient(90deg, #64e0ba 0%, #64e0ba 55%, #2dcee3 90%);
    }

    .form-group__label,
    .radio-wrapper__label {
      color: #505050;
    }
  }
}

.document-page,
.account-document-page{
  .read-only {

    input {
      &::-webkit-input-placeholder {
        text-indent: -99999px;
      }
    }

    .editable-label__label {
      border: none !important;
      pointer-events: none !important;
      padding: 0;
      display: block;
      //margin-bottom: 5px;
    }

    .editable-label__value {
      padding-top: 7px;
    }

    .radio-wrapper {
      pointer-events: none !important;
    }

    .editable-label__icon  {
      display: none;
    }
  }
}

.read-only {
  .react-select__control {
    border-width: 0;
    cursor: default;
  }
  .react-select__dropdown-indicator {
    display: none;
  }
  .react-select__menu {
    display: none;
  }
  .react-select__single-value {
    pointer-events: none !important;
    color: $color-grey-05;
  }
}


.read-only .force-edit {
  .react-select__control {
    border-width: 1px;
    cursor: pointer;
  }
  .react-select__dropdown-indicator {
    display: flex;
  }
  .react-select__menu {
    display: block;
  }
  .react-select__single-value {
    pointer-events: initial !important;
    color: hsl(0,0%,20%);
  }


  .input {
    border: 1px solid #cacaca !important;
    pointer-events: initial !important;
  }
}
